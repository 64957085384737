import React, { useRef, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import bidActGroup24 from "../../../assets/images/Group-24.png";
import cancelIcon from "../../../assets/images/cancel-icn.png";
// import pendingBtn from "../../../assets/images/pending-btn-main.png";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import ResponsiveNavBarDasbord from "../../../components/ResponsiveNavBarDasbord";
import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import { gql, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { baseUrl } from "../../../utils/Function";
const customStyles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "70%",
  },
};

const customStyles2 = {
  content: {
    position: "absolute",
    inset: "58% auto auto 58%",
    border: "none",
    background: "#FFFBF0",
    overflow: "auto",
    outline: "none",
    padding: "30px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    textAlign: "center",
    color: "grey",
  },
};
const customStyles3 = {
  content: {
    position: "absolute",
    inset: "58% auto auto 58%",
    border: "none",
    background: "#fff",
    borderRadius: "20px",
    overflow: "auto",
    outline: "none",
    padding: "30px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    textAlign: "center",
    color: "grey",
  },
};

const GET_AUCTION_BIDS_ACTIVITY_LOG = gql`
  query {
    plates(where: { status: SOLD, purpose: AUCTION }) {
      askingPrice
      id
      combination
      user {
        email
      }
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            winner {
              username
            }
          }
        }
      }
    }
  }
`;

const GET_LISTING_BIDS_ACTIVITY_LOG = gql`
  query {
    plates(where: { status: SOLD, purpose: LISTING }) {
      id
      askingPrice
      combination
      status
      user {
        email
      }
      detail {
        ... on PlateListing {
          buyer {
            username
          }
        }
      }
    }
  }
`;
const GET_MODAL_BIDS_ACTIVITY_LOG = gql`
  query GetModalBids($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      id
      askingPrice
      combination
      payments {
        amount
      }
      user {
        username
        email
        streetAddress
        id
      }
      detail {
        ... on PlateListing {
          settlePrice
          currentOffer
          buyer {
            username
            email
            streetAddress
          }
        }
      }
    }
  }
`;

const GET_MODAL2_BIDS_ACTIVITY_LOG = gql`
  query GetAuctionProduct($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      id
      askingPrice
      combination
      status
      payments {
        amount
      }
      user {
        id
        username
        email
        streetAddress
      }
      detail {
        ... on PlateAuction {
          auction {
            winner {
              username
              email
              streetAddress
            }
          }
          currentBid
        }
      }
    }
  }
`;

// https://buy.stripe.com/test_bIYeYQckCc477QI28d

function BidsAct() {
  const handleRequest = (plateID, userID, Paylink) => {
    // Use axios directly here, no need to require it
    console.log(Paylink, "Paylink-------=====");
    console.log(plateID, "plateID-------=====");
    console.log(userID, "userID-------=====");
    // const imgInputRef = useRef(null);
    const data = JSON.stringify({
      link: Paylink,
      plateId: plateID,
      userId: userID,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://signatureapi.institu-tech.com/payments/paymentlink",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePopUp = () => {
    Swal.fire(
      "Good job!",
      "Payment Proof has been to the seller on Email",
      "success"
    );
  };
  const handlePopup2 = () => {
    Swal.fire(
      "Good job!",
      "Request has been send to the Buyer on Email",
      "success"
    );
  };
  const userData = useSelector((state) => state.user.userData);
  const token = userData.accessToken;
  const [showText, setShowText] = useState();
  // const [id , setId] = useState();
  const [isbidactmodal, setIsBidActModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setTimeout(() => {
  //     setIsModalOpen(false);
  //   }, 1500);

  //   // Handle file upload logic here

  //   // For this example, we'll just set the fileUploaded state to true
  //   setFileUploaded(true);
  // };

  // const handleFileChange2 = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setTimeout(() => {
  //     setIsModalOpen(false);
  //   }, 1500);

  //   // Handle file upload logic here

  //   // For this example, we'll just set the fileUploaded state to true
  //   setFileUploaded(true);
  // };

  const bidactmodal = (i) => {
    ModalTwo({
      variables: { id: i },
    });
    console.log(desiredPlateId, "kia hay id");
    setIsBidActModal((prev) => !prev);
  };

  const [isbidauctionactmodal, setIsBidAuctionActModal] = useState(false);
  const bidauctionactmodal = (i) => {
    console.log(i);
    ModalOne({
      variables: { id: i },
    });
    setIsBidAuctionActModal((prev) => !prev);
  };
  const [isBuyerPaidAmount, setIsBuyerPaidAmount] = useState(true);
  const [isSellerGetAmountEmail, setIsSellerGetAmountEmail] = useState(true);
  const [isrequestSend, setIsrequestSend2] = useState(false);

  const requestSend = () => {
    setIsrequestSend2(true);
  };
  const requestSendclose = () => {
    setIsrequestSend2(false);
  };

  const handleBothFunctions = () => {
    setIsSellerGetAmountEmail((prev) => !prev);
    if (isSellerGetAmountEmail) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const [isFirstParagraphVisible, setIsFirstParagraphVisible] = useState(true);

  const handleClick = () => {
    setIsFirstParagraphVisible(!isFirstParagraphVisible);
  };

  const handleCancel = () => {
    setIsBidActModal(false);
  };

  const handleCancel2 = () => {
    setIsBidAuctionActModal(false);
  };

  const {
    loading: loading2,
    error: error2,
    data: data2,
  } = useQuery(
    GET_LISTING_BIDS_ACTIVITY_LOG,
    { fetchPolicy: "network-only" },
    { refetchQueries: ["LISTING_BIDS_ACTIVITY_LOG"] }
  );

  const { loading, error, data } = useQuery(GET_AUCTION_BIDS_ACTIVITY_LOG);

  const auctionData2 = data2?.plates;
  const auctionData = data?.plates;

  const desiredPlateId = data?.plates[0]?.id;
  const desiredPlateId2 = data2?.plates[0]?.id;

  const [
    ModalOne,
    { loading: loadingModal, error: errorModal, data: dataModal },
  ] = useLazyQuery(GET_MODAL_BIDS_ACTIVITY_LOG);
  const [
    ModalTwo,
    { loading: loadingModal2, error: errorModal2, data: dataModal2 },
  ] = useLazyQuery(GET_MODAL2_BIDS_ACTIVITY_LOG);

  const auctionDataModal = dataModal?.plate || [];
  const auctionDataModal2 = dataModal2?.plate || [];

  if (loading || loading2 || loadingModal || loadingModal2) {
    return (
      <div className="bg-dashboard">
        <main id="main">
          <ResponsiveNavBarDasbord />
          <Sidebar />
          <div className="dashboard-right-side">
            <div className="form-req-tble-parent">
              <div className="payment-inner-page-set">
                <div
                  style={{ backgroundColor: "#FFFBF0" }}
                  className="form-req-tble-inner d-flex"
                >
                  <h1 style={{ color: "#6D6D6D" }}>Loading....</h1>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  if (error) {
    return <div>Error1: {error.message}</div>;
  }
  if (error2) {
    return <div>Error2: {error2.message}</div>;
  }
  if (errorModal) {
    return <div>Error: {errorModal.message}</div>;
  }
  if (errorModal2) {
    return <div>Error3: {errorModal2.message}</div>;
  }
  // Access the data from the query

  // roshaan
  const ModalPay = () => {
    const [uploadedProofImg, setUploadedProofImg] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const handleChange = (event) => {
      // const stripeLinkPattern = /^https:\/\/buy\.stripe\.com\/[a-zA-Z0-9\-_]+$/i;
      // const newValue = event.target.value;
      // if (stripeLinkPattern.test(newValue) || newValue === "") {
      //   setInputValue(newValue);
      // }
    };

    const uploadRef = useRef();
    const uploadImage = (event) => {
      setUploadedProofImg(event.target.files[0]);
    };

    const handleBothFunctions2 = (price, PlateID, userId) => {
      if (isBuyerPaidAmount) {
        const formData = new FormData();
        formData.append("proof", uploadedProofImg);
        formData.append("amount", price);
        formData.append("plateId", PlateID);
        formData.append("userId", userId);
        axios
          .post(`${baseUrl}payments`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log("Image uploaded successfully:", response.data);
            setIsBuyerPaidAmount((prev) => !prev);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    };

    // const handleBothFunctionsListingSeller = (
    //   price,
    //   PlateID,
    //   userId
    // ) => {
    //   if (isBuyerPaidAmount) {
    //     const formData = new FormData();
    //     formData.append("proof", uploadedProofImg);
    //     formData.append("amount", price);
    //     formData.append("plateId", PlateID);
    //     formData.append("userId", userId);
    //     axios
    //       .post(`${baseUrl}payments`, formData, {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //           Authorization: `Bearer ${token}`,
    //         },
    //       })
    //       .then((response) => {
    //         console.log("Image uploaded successfully:", response.data);
    //         setIsSellerGetAmountEmail((prev) => !prev);
    //         Swal.fire(
    //           "Good job!",
    //           "Payment Proof has been to the seller on Email",
    //           "success"
    //         );
    //       })
    //       .catch((error) => {
    //         console.error("Error uploading image:", error);
    //         Swal.fire("Opps!", "Please try again", "Failure");
    //       });
    //   }
    // };

    return (
      <Modal
        isOpen={isbidactmodal}
        // onRequestClose={bidactmodal}
        style={customStyles}
        onAfterOpen={() => {}}
      >
        <div class={`modal-form-request-parent`}>
          <div style={{ boxShadow: "none" }} class="modal-form-request-inner">
            <div class="cancel-icn" onClick={handleCancel}>
              <Link>
                <img src={cancelIcon} alt="" />
              </Link>
            </div>
            <div class="abc-heading text-center abc-chng">
              <h1>{auctionDataModal2?.combination}</h1>
            </div>
            <div class="row">
              <div class="col-lg-6 bd-left">
                <div class="main-modal-width">
                  <div class="abc-heading text-center">
                    <h1>Seller Details</h1>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Name
                          <input
                            type="text"
                            placeholder={auctionDataModal2?.user?.username}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Email
                          <input
                            type="email"
                            placeholder={auctionDataModal2?.user?.email}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label class="d-flex flex-column" for="modalinp1">
                          Address
                          <textarea
                            placeholder={auctionDataModal2?.user?.streetAddress}
                            readOnly
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Price
                          <input
                            type="text"
                            placeholder={auctionDataModal2?.askingPrice}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="modal-input-form-req py-2">
                    <label for="modalinp1">StatusX</label>
                    <div>
                      {/* buuton aye ga */}

                      <div class="col-lg-12">
                        <input
                          id="file-upload"
                          ref={uploadRef}
                          type="file"
                          accept=".jpg, .jpeg, .png, .gif"
                          onChange={uploadImage}
                          style={{ display: "none" }}
                        />
                        <button
                          className="uploadBtnBtn"
                          onClick={() => uploadRef.current.click()}
                        >
                          Upload Proof seller
                        </button>
                      </div>

                      <div class="send-req-btn">
                        <Link
                          onClick={() => {
                            handleBothFunctions2(
                              auctionDataModal?.detail?.currentOffer,
                              auctionDataModal?.id,
                              auctionDataModal?.user?.id
                            );
                          }}
                          // onClick={handlePopUp}
                        >
                          Amount Transfer
                        </Link>
                      </div>

                      {/* <Switch
                        className="react-switch bid-act-before"
                        onChange={() => handleBothFunctions()}
                        checked={isSellerGetAmountEmail}
                        disabled={isBuyerPaidAmount}
                        aria-labelledby="neat-label"
                        uncheckedIcon={
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#fff",
                              fontWeight: "400",
                              padding: "4px 0 0",
                            }}
                          >
                            Paid
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#fff",
                              fontWeight: "400",
                              padding: "4px 0 0 6px",
                            }}
                          >
                            Pending
                          </div>
                        }
                        onColor="#707070"
                        offColor="#7A724E"
                        height={25}
                        width={85}
                      /> */}
                      <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        style={customStyles2}
                      >
                        <h2>
                          <b>Payment Proof</b>
                        </h2>
                        <p>Please provide the screenshot of payment.</p>

                        <label for="file-upload" class="custom-file-upload">
                          <b>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-upload"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                            </svg>
                          </b>
                          <span
                            className={`tick-icon ${
                              fileUploaded ? "visible" : ""
                            }`}
                          >
                            File Uploaded
                          </span>
                          <span
                            className={`upload-label ${
                              fileUploaded ? "hidden" : ""
                            }`}
                          >
                            Upload
                          </span>
                        </label>
                        {/* <input
                          id="file-upload"
                          type="file"
                          accept=".jpg, .jpeg, .png, .gif"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        /> */}
                        {/* <button onClick={closeModal}>Close Modal</button> */}
                      </Modal>
                    </div>
                  </div>

                  {/* <div class="send-req-btn">
                    <Link href="">Amount Transfer</Link>
                  </div> */}
                </div>
              </div>

              <div class="col-lg-6">
                <div class="main-modal-width">
                  <div class="abc-heading text-center">
                    <h1>Buyer Details</h1>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Name
                          <input
                            type="text"
                            placeholder={
                              auctionDataModal2?.detail?.auction?.winner
                                ?.username
                            }
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Email
                          <input
                            type="email"
                            placeholder={
                              dataModal2?.plate?.detail?.auction?.winner?.email
                            }
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label class="d-flex flex-column" for="modalinp1">
                          Address
                          <textarea
                            placeholder={
                              auctionDataModal2.detail?.auction?.winner
                                ?.streetAddress
                            }
                            readOnly
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Winning Bid
                          <input
                            type="text"
                            placeholder={auctionDataModal2?.detail?.currentBid}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <input
                        id="file-upload"
                        ref={uploadRef}
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif"
                        onChange={uploadImage}
                        style={{ display: "none" }}
                      />
                      <button
                        className="uploadBtnBtn"
                        onClick={() => uploadRef.current.click()}
                      >
                        Upload Proof buyer
                      </button>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">Status buyer</label>
                        <div>
                          <Switch
                            className="react-switch bid-act-before"
                            onChange={() => {
                              console.log("switch buyer");
                            }}
                            checked={
                              auctionDataModal2?.payments?.length > 0
                                ? false
                                : true
                            }
                            aria-labelledby="neat-label"
                            uncheckedIcon={
                              <div
                                style={{
                                  fontSize: "10px",
                                  color: "#fff",
                                  fontWeight: "400",
                                  padding: "4px 0 0",
                                }}
                              >
                                Paid
                              </div>
                            }
                            checkedIcon={
                              <div
                                style={{
                                  fontSize: "10px",
                                  color: "#fff",
                                  fontWeight: "400",
                                  padding: "4px 0 0 6px",
                                }}
                              >
                                Pending
                              </div>
                            }
                            onColor="#707070"
                            offColor="#7A724E"
                            height={25}
                            width={85}
                          />
                          <Modal
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                            style={customStyles2}
                          >
                            <h2>
                              <b>Payment Proof</b>
                            </h2>
                            <p>Please provide the screenshot of payment.</p>

                            <label for="file-upload" class="custom-file-upload">
                              <b>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-upload"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>
                              </b>
                              <span
                                className={`tick-icon ${
                                  fileUploaded ? "visible" : ""
                                }`}
                              >
                                File Uploaded
                              </span>
                              <span
                                className={`upload-label ${
                                  fileUploaded ? "hidden" : ""
                                }`}
                              >
                                Upload
                              </span>
                            </label>
                            {/* <input
                              id="file-upload"
                              type="file"
                              accept=".jpg, .jpeg, .png, .gif"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            /> */}
                            {/* <button onClick={closeModal}>Close Modal</button> */}
                          </Modal>
                          {!(auctionDataModal2?.payments?.length > 0) ? (
                            <div className="send-req-btn">
                              <div className="flex">
                                <input
                                  type="text"
                                  placeholder="Place payment link here"
                                  style={{
                                    height: "35px",
                                    marginBottom: "10px",
                                  }}
                                  required
                                  value={inputValue}
                                  onChange={(event) =>
                                    setInputValue(event.target.value)
                                  }
                                />
                                <a
                                  className="paymentLink"
                                  href="https://dashboard.stripe.com/test/payment-links/create"
                                >
                                  Create Link
                                </a>
                              </div>

                              <Link
                                to="#"
                                onClick={(e) => {
                                  const stripeLinkPattern =
                                    /^https:\/\/buy\.stripe\.com\/[a-zA-Z0-9\-_]+$/i;
                                  if (stripeLinkPattern.test(inputValue)) {
                                    e.preventDefault();
                                    handleRequest(
                                      auctionDataModal2?.id,
                                      auctionDataModal2?.user?.id,
                                      // generatedLink
                                      inputValue
                                    );
                                    requestSend();
                                  }
                                }}
                              >
                                Send Request buyer
                              </Link>
                              <h6 style={{ marginTop: "12px" }}>
                                Request for Initial Deposit
                              </h6>

                              <button
                                onClick={() => {
                                  handleBothFunctions2(
                                    auctionDataModal2?.detail?.currentBid,
                                    auctionDataModal2?.id,
                                    auctionDataModal2?.user?.id
                                  );
                                }}
                              >
                                Pay Buyer
                              </button>
                              <Modal
                                isOpen={isrequestSend}
                                onRequestClose={requestSendclose}
                                style={customStyles3}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={70}
                                  height={70}
                                  fill="currentColor"
                                  className="bi bi-check-lg"
                                  viewBox="0 0 16 16"
                                  color="green"
                                >
                                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                </svg>

                                <h2>
                                  <b>Request Send</b>
                                </h2>
                              </Modal>
                            </div>
                          ) : (
                            <div className="send-req-btn-paid">
                              <Link to="#">Paid</Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const ModalListing = () => {
    const [uploadedProofImg, setUploadedProofImg] = useState(null);
    const [uploadedProofImg2, setUploadedProofImg2] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const handleChange = (event) => {
      const stripeLinkPattern =
        /^https:\/\/buy\.stripe\.com\/[a-zA-Z0-9\-_]+$/i;
      const newValue = event.target.value;
      if (stripeLinkPattern.test(newValue) || newValue === "") {
        setInputValue(newValue);
      }
    };

    const uploadRef = useRef();
    const uploadImage = (event) => {
      setUploadedProofImg(event.target.files[0]);
      setUploadedProofImg2(event.target.files[0]);
    };

    const handleBothFunctionsListing = (price, PlateID, userId) => {
      if (isBuyerPaidAmount) {
        const formData = new FormData();
        formData.append("proof", uploadedProofImg);
        formData.append("amount", price);
        formData.append("plateId", PlateID);
        formData.append("userId", userId);
        axios
          .post(`${baseUrl}payments`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log("Image uploaded successfully:", response.data);
            setIsSellerGetAmountEmail((prev) => !prev);
            Swal.fire(
              "Good job!",
              "Payment Proof has been to the seller on Email",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    };
    const handleBothFunctionsListingSeller = (price, PlateID, userId) => {
      if (isBuyerPaidAmount) {
        const formData = new FormData();
        formData.append("proof", uploadedProofImg);
        formData.append("amount", price);
        formData.append("plateId", PlateID);
        formData.append("userId", userId);
        axios
          .post(`${baseUrl}payments`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log("Image uploaded successfully:", response.data);
            setIsSellerGetAmountEmail((prev) => !prev);
            Swal.fire(
              "Good job!",
              "Payment Proof has been to the seller on Email",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            Swal.fire("Opps!", "Please try again", "Failure");
          });
      }
    };
    return (
      <Modal
        isOpen={isbidauctionactmodal}
        // onRequestClose={bidauctionactmodal}
        style={customStyles}
        onAfterOpen={() => {}}
      >
        <div class="modal-form-request-parent">
          <div style={{ boxShadow: "none" }} class="modal-form-request-inner">
            <div class="cancel-icn" onClick={handleCancel2}>
              <Link>
                <img src={cancelIcon} alt="" />
              </Link>
            </div>
            <div class="abc-heading text-center abc-chng">
              <h1>{auctionDataModal?.combination}</h1>
            </div>
            <div class="row">
              <div class="col-lg-6 bd-left">
                <div class="main-modal-width">
                  <div class="abc-heading text-center">
                    <h1>Seller Details</h1>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Name
                          <input
                            type="text"
                            placeholder={auctionDataModal?.user?.username}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Email
                          <input
                            type="email"
                            placeholder={auctionDataModal?.user?.email}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label class="d-flex flex-column" for="modalinp1">
                          Address
                          <textarea
                            placeholder={auctionDataModal?.user?.streetAddress}
                            readOnly
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Asking Price
                          <input
                            type="text"
                            placeholder={auctionDataModal?.askingPrice}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Settle Price
                          <input
                            type="text"
                            placeholder={auctionDataModal?.detail?.settlePrice}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <input
                      id="file-upload"
                      ref={uploadRef}
                      type="file"
                      accept=".jpg, .jpeg, .png, .gif"
                      onChange={uploadImage}
                      style={{ display: "none" }}
                    />
                    <button
                      className="uploadBtnBtn"
                      onClick={() => uploadRef.current.click()}
                    >
                      Upload Proof seller
                    </button>
                  </div>

                  <div class="send-req-btn">
                    <Link
                      onClick={() => {
                        handleBothFunctionsListingSeller(
                          auctionDataModal?.detail?.currentOffer,
                          auctionDataModal?.id,
                          auctionDataModal?.user?.id
                        );
                      }}
                      // onClick={handlePopUp}
                    >
                      Amount Transfer
                    </Link>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="main-modal-width">
                  <div class="abc-heading text-center">
                    <h1>Buyer Details</h1>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Name
                          <input
                            type="text"
                            placeholder={
                              auctionDataModal?.detail?.buyer?.username
                            }
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Email
                          <input
                            type="email"
                            placeholder={auctionDataModal?.detail?.buyer?.email}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label class="d-flex flex-column" for="modalinp1">
                          Address
                          <textarea
                            placeholder={
                              auctionDataModal?.detail?.buyer?.streetAddress
                            }
                            readOnly
                          ></textarea>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <div class="modal-input-form-req py-2">
                        <label for="modalinp1">
                          Total Amount
                          <input
                            type="text"
                            placeholder={auctionDataModal?.detail?.currentOffer}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <div class="col-lg-12">
                      <input
                        id="file-upload"
                        ref={uploadRef}
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif"
                        onChange={uploadImage}
                        style={{ display: "none" }}
                      />
                      <button
                        className="uploadBtnBtn"
                        onClick={() => uploadRef.current.click()}
                      >
                        Upload Proof
                      </button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="status-text">
                      <h6>Status</h6>
                    </div>
                    {/* <div class="three-btns">
                      <Link class="btn-bg-red-col-white" to="">
                        Pending
                      </Link>
                      <Link class="" to="" onClick={handleClick}>
                        {isFirstParagraphVisible ? "Deposit" : "Deposit"}
                      </Link>
                      <Link to="">PaidX</Link>
                    </div> */}
                    <Switch
                      className="react-switch bid-act-before"
                      onChange={() => console.log("first")}
                      checked={
                        auctionDataModal?.payments?.length > 0 ? false : true
                      }
                      aria-labelledby="neat-label"
                      uncheckedIcon={
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#fff",
                            fontWeight: "400",
                            padding: "4px 0 0",
                          }}
                        >
                          Paid
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#fff",
                            fontWeight: "400",
                            padding: "4px 0 0 6px",
                          }}
                        >
                          Pending
                        </div>
                      }
                      onColor="#707070"
                      offColor="#7A724E"
                      height={25}
                      width={85}
                    />
                  </div>

                  <div class="send-req-btn">
                    {isFirstParagraphVisible ? (
                      <h6>Request for Initial Deposit</h6>
                    ) : (
                      <h6>Request for remaining amount</h6>
                    )}
                    {!(auctionDataModal?.payments?.length > 0) ? (
                      <>
                        <div className="send-req-btn">
                          <div className="flex">
                            <input
                              type="text"
                              placeholder="Place payment link here"
                              style={{
                                height: "35px",
                                marginBottom: "10px",
                              }}
                              required
                              value={inputValue}
                              onChange={handleChange}
                            />
                            <a
                              className="paymentLink"
                              href="https://dashboard.stripe.com/test/payment-links/create"
                            >
                              Create Link
                            </a>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            handleBothFunctionsListing(
                              auctionDataModal?.detail?.currentOffer,
                              auctionDataModal?.id,
                              auctionDataModal?.user?.id
                            );
                          }}
                          className="payBtn"
                        >
                          Pay
                        </button>
                        <Link onClick={handlePopup2}>Send Request</Link>
                      </>
                    ) : (
                      <h1>paid</h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div class="bg-dashboard">
      <div id="main">
        <Sidebar />
        <ResponsiveNavBarDasbord />
        <div class="dashboard-right-side plates-rec-parent">
          <div class="page-title">
            <h1>BIDS Activity</h1>
          </div>
          <div class="payment-inner-page-set">
            <div class="tabs-plate-rec-parent bids-act-tabs-parent">
              <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    style={{ marginLeft: "0" }}
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Auction
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Listing
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div class="winner-table-parent">
                    <div class="winner-table d-flex w-100">
                      <div
                        style={{ width: "22%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Seller Email</h3>
                      </div>
                      <div
                        style={{ width: "11%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Plate</h3>
                      </div>
                      <div
                        style={{ width: "17%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Starting Price</h3>
                      </div>
                      <div
                        style={{ width: "14%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Winner</h3>
                      </div>
                      <div
                        style={{ width: "15%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Winning Bid</h3>
                      </div>
                      <div
                        style={{ width: "15%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Payment Status</h3>
                      </div>
                      <div
                        style={{ width: "6%" }}
                        class="winner-table-inner text-center"
                      ></div>
                    </div>
                    {auctionData
                      .slice()
                      .reverse()
                      .map((item, index) => (
                        <div
                          key={index}
                          className="winner-table winner-table-2 d-flex w-100"
                        >
                          <div
                            style={{ width: "22%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>{item.user?.email}</h3>
                          </div>
                          <div
                            style={{ width: "11%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>{item.combination}</h3>
                          </div>
                          <div
                            style={{ width: "17%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>${item.askingPrice}</h3>
                          </div>
                          <div
                            style={{ width: "14%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>
                              {item.detail.auction.winner?.username ||
                                "No winner"}
                            </h3>
                          </div>
                          <div
                            style={{ width: "15%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>${item.detail.currentBid || 0}</h3>
                          </div>
                          <div
                            style={{ width: "15%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>Null</h3>
                          </div>
                          <div
                            style={{ width: "6%" }}
                            className="winner-table-inner text-center"
                          >
                            <Link>
                              <h3>
                                <img
                                  onClick={() => {
                                    console.log(item.id, "bidactmodal");
                                    bidactmodal(item.id);
                                  }}
                                  src={bidActGroup24}
                                  alt="Bid Action"
                                />
                              </h3>
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div class="winner-table-parent">
                    <div class="winner-table d-flex w-100">
                      <div
                        style={{ width: "39%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Seller Email</h3>
                      </div>
                      <div
                        style={{ width: "20%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Plate</h3>
                      </div>
                      <div
                        style={{ width: "20%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Price</h3>
                      </div>
                      <div
                        style={{ width: "20%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Buyer </h3>
                      </div>

                      <div
                        style={{ width: "15%" }}
                        class="winner-table-inner text-center"
                      >
                        <h3>Payment Status</h3>
                      </div>
                      <div
                        style={{ width: "6%" }}
                        class="winner-table-inner text-center"
                      ></div>
                    </div>
                    {auctionData2
                      .slice()
                      .reverse()
                      .map((plate, index) => (
                        <div
                          key={index}
                          className="winner-table winner-table-2 d-flex w-100"
                        >
                          <div
                            style={{ width: "39%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>{plate?.user?.email}</h3>
                          </div>
                          <div
                            style={{ width: "20%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>{plate.combination}</h3>
                          </div>
                          <div
                            style={{ width: "20%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>${plate?.askingPrice?.toFixed(2)}</h3>
                          </div>
                          <div
                            style={{ width: "20%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3>
                              {plate?.detail?.buyer?.username ? (
                                <>{plate?.detail?.buyer?.username}</>
                              ) : (
                                <>No Buyer</>
                              )}
                            </h3>
                          </div>
                          <div
                            style={{ width: "15%" }}
                            className="winner-table-inner text-center"
                          >
                            <h3 style={{ color: "#C20C0C" }}>
                              {plate?.status ? <>{plate?.status}</> : "null"}
                            </h3>
                          </div>
                          <div
                            style={{ width: "6%" }}
                            className="winner-table-inner text-center"
                          >
                            <Link to="">
                              <h3>
                                <img
                                  onClick={() => bidauctionactmodal(plate.id)}
                                  src={bidActGroup24}
                                  alt="Bid Action"
                                />
                              </h3>
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <ModalPay />
            <ModalListing />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidsAct;
