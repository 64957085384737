import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
import '../../../../../node_modules/bootstrap/js/dist/modal'
import ResponsiveNavBarDasbord from '../../../../components/ResponsiveNavBarDasbord'

function Seller_dashboard() {
  return (
<section className="dasgboard-miansection">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="user-seller-approved-unapproved-container">
    <div className="row pt-2">
    <UserSidebar/>
    <ResponsiveNavBarDasbord/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <div className="sllermain-title">
            <h1>DASHBOARD</h1>
            <div className="sidebar-brand">
              <h2
                id="formButton"
                onclick="location.href='saller-dashboard.html';"
              >
                Switch to buyer{" "}
                <i className="fa fa-refresh" aria-hidden="true" />
                {/*  <label class="switch">*/}
                {/*    <input type="checkbox" id="formButton" onclick="location.href='index.html';">*/}
                {/*    <span class="slider round"></span>*/}
                {/*</label>  */}
              </h2>
            </div>
          </div>
        </div>
        <div className="dashboard-destailbox">
          <div className="wellcome-message-one">
            <img
              src="./assets/images/logoas.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="wellcome-message-two">
            <p>
              Hello Nick! <br />
              Welcome to Signature+
              <br />
              From your account dashboard you can view your recent orders,
              manage your account's setting and billing addresses, and edit your
              password and account details.
            </p>
            <a
              data-bs-toggle="modal"
              href="#exampleModalToggle"
              role="button"
              className="reqvalidation"
            >
              Request Verification
            </a>
          </div>
        </div>
        {/* modal  */}
        <div
          className="modal fade"
          id="exampleModalToggle"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                {/* form  */}
                <div className="col-sm-12 col-md-12 col-lg-12 latest_order">
                  <div className="order-heading">
                    <h1>
                      {" "}
                      <span className="icon-shop">
                        {" "}
                        <i className="fa-solid fa-user" />
                      </span>{" "}
                      REQUEST VERIFICATION{" "}
                    </h1>
                  </div>
                  <div className="formset pr-5">
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>First Name</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Last Name</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Phone</legend>
                        <input
                          className="form-input phoneNumber"
                          type="tell"
                          id="fname"
                          name="fname"
                          minLength={11}
                          maxLength={11}
                          size={11}
                          required=""
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Email:</legend>
                        <input
                          className="form-input"
                          type="email"
                          id="fname"
                          name="fname"
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Street Address</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="fname"
                          name="fname"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Suburb</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="fname"
                          name="fname"
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Post Code</legend>
                        <input
                          className="form-input phoneNumber"
                          type="text"
                          id="fname"
                          name="fname"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>State</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="fname"
                          name="fname"
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Driver Licence Front</legend>
                        <input type="file" id="myfile" name="myfile" />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Driver Licence Back</legend>
                        <input type="file" id="myfile" name="myfile" />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Maximum file size: 32 MB</legend>
                        <legend>
                          Please provide a copy of your Driver Licence so that
                          we can verify your identity for our live bidding
                          platform.
                        </legend>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Maximum file size: 32 MB</legend>
                        <legend>
                          If you have an updated address label, please provide a
                          photo of the back of the licence.
                        </legend>
                      </div>
                    </fieldset>
                    <a href="" className="logout">
                      Request Verification
                    </a>
                  </div>
                </div>
                {/* form */}
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        
      </div>
    </div>
  </div>
</section>

  )
}

export default Seller_dashboard