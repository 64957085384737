import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import AnimatedCursor from "../../../components/AnimatedCursor";
import RangeSliderComponent from "../../../components/RangeSliderComponent";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import Plate from "./Plate";
import LeftMainGridBuy from "./LeftMainGridBuy";

function Buy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data1, setData1] = useState("");
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState();

  const handleDataChange1 = (term) => {
    setData1(term);
  };
  const handleDataChange2 = (characterCount) => {
    setData2(characterCount);
    console.log(characterCount, "------------ char check  ");
  };
  const handleDataChange3 = (pattern) => {
    setData3(pattern);
    // console.log(characterCount, "------------ char check  ")
  };
  const handleDataChange4 = (value) => {
    setData4(value);
    console.log(value, "------------ Vaaaaal check  ");
  };

  return (
    <>
      <>
        <AnimatedCursor />
        <div className="main-scroll-bar" />
        <header className="header-bg">
          <Header />
          <section className="top_banner">
            <div className="container">
              <div className="grid-row auction-grid">
                <div className="grid-col-2">
                  <div className="contentBox content leFadeIn">
                    <h1>Buy</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam convallis rhoncus eros, nec scelerisque nisi.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>
        <div className="main">
          <section className="main-buy">
            <div className="container">
              <div className="parent-twogrid">
                <div className="grid-buy-main">
                  <div className="right-main-grid-buy">
                    <LeftMainGridBuy
                      onDataUpdate2={handleDataChange2}
                      onDataUpdate={handleDataChange1}
                      onDataUpdate3={handleDataChange3}
                      onDataUpdate4={handleDataChange4}
                    />{" "}
                  </div>

                  <div className="right-main-grid-buy">
                    <Plate
                      data3={data3}
                      data2={data2}
                      data1={data1}
                      data4={data4}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    </>
  );
}

export default Buy;
