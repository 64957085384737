import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'

function Payment_strip() {
  return (
<section className="dasgboard-miansection hundred-payment pb-5">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="user-seller-approved-unapproved-container pb-5">
    <div className="row pt-2">
    <UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <h1>Payment Method</h1>
        </div>
        <div className="row pb-5 my-4 Latest-Order gx-0">
          <div className="col-sm-12 col-md-10 col-lg-10 latest_order">
            <div className="order-heading">
              <div className="row payment-heading">
                <div className="col-md-8 col-lg-8 col-sm-12">
                  <h1>
                    {" "}
                    <span className="icon-shop">
                      {" "}
                      <i className="fa-solid fa-credit-card" />{" "}
                    </span>{" "}
                    Payment
                  </h1>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <a href="" className="logout Shipping  update">
                    Add Payment Method
                  </a>
                </div>
              </div>
              <div className="formset pb-5 pr-5">
                <fieldset className="row pt-5">
                  <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                    <legend>
                      Credit Card{" "}
                      <span style={{ color: "red" }}>
                        *<span />
                      </span>
                    </legend>
                    <div className="cradit-card-input">
                      <input
                        className="form-input"
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="First Name"
                      />
                      <span className="credit-icon">
                        {" "}
                        <i className="fa-solid fa-credit-card" />
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3 col-lg-3 pt-3">
                    <legend>
                      Expiry{" "}
                      <span style={{ color: "red" }}>
                        *<span />
                      </span>
                    </legend>
                    <input
                      className="form-input"
                      placeholder="MM/YY"
                      type="text"
                      id="fname"
                      name="fname"
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 col-lg-3 pt-3">
                    <legend style={{ opacity: 0 }}>s</legend>
                    <input
                      className="form-input"
                      placeholder="CVC"
                      type="text"
                      id="fname"
                      name="fname"
                    />
                  </div>
                </fieldset>
                <fieldset className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 pt-3">
                    <input
                      className="form-input"
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Card Holder Name"
                    />
                  </div>
                </fieldset>
                <a href="" className="logout">
                  Save Changes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Payment_strip