import { useState } from "react";
import searchLogo from "../../../assets/images/search-logo.png";
import Logo from "../../../assets/images/logo.png";
import card1 from "../../../assets/images/btm-card-one.png";
import card2 from "../../../assets/images/btm-card-two.png";
import CountdownTimer from "../../../components/Counter";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, gql, useQuery } from "@apollo/client";
import Allbids from "../../../components/Allbids";
import Header from "../../../components/Header";
import AskingPriceTwo from "../Asking-Price-Two/AskingPriceTwo";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../utils/Function";
import AskingPriceOne from "../Asking-Price-One/AskingPriceOne";
import AnimatedCursor from "../../../components/AnimatedCursor";
import ScrollIntoView from "react-scroll-into-view";
import React, { useRef } from "react";

const GET_AUCTION_PRODUCT = gql`
  query GetAuctionProduct($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      numberPlate
      id
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            id
            endAt
            bids {
              id
              price
              createdAt
              user {
                firstName
                lastName
                id
                username
              }
            }
          }
        }
        ... on PlateListing {
          id
        }
      }
      status
      comments
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;

const Me = gql`
  query {
    me {
      status
      firstName
      lastName
      email
      phoneNumber
      drivingLicense
    }
  }
`;

function formatDateTime(dateTime) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };

  return new Date(dateTime).toLocaleString("en-US", options);
}

function AuctionProduct() {
  const scrollRef = React.useRef();

  const [price, setPrice] = useState("");
  const userData = useSelector((state) => state.user.userData);

  const CREATE_BID_MUTATION = gql`
    mutation CreateBid($auctionId: Int!, $price: Int!) {
      createBid(data: { auctionId: $auctionId, price: $price }) {
        id
        price
      }
    }
  `;
  const { loading: loadingb, error: errorb, data: datab } = useQuery(Me);
  console.log(datab?.me?.status, "-----srrsrsrs");

  const [createBid] = useMutation(CREATE_BID_MUTATION, {
    onCompleted: (data) => {
      console.log("Bid created successfully:", data.createBid);
      toast.success("Bid submited sucessfull");
      // window.location.reload();
      refetch();
    },
    onError: (error) => {
      console.error("Error creating bid:", error);
      toast.error("Price not set OR Price must be greater than current Bid");
    },
  });

  const handleSubmit = (event) => {
    if (plate?.askingPrice >= price) {
      return alert("price should be greater than currentbid");
    } else {
      event.preventDefault();
      createBid({
        variables: {
          auctionId: plate.detail.auction.id,
          price: parseFloat(price),
        },
      });
      setPrice("");
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  const MyId = userData.userID;


  const { id } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_AUCTION_PRODUCT, {
    variables: { id: parseInt(id) },
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const plate = data && data.plate;
  const { combination, comments, detail, numberPlate } = plate;
  const { auction } = detail;

  if (auction) {
    const { id: auctionId, startingPrice, endAt, bids } = auction;
    // const highestBid = bids.length > 0 ? bids[0] : null;
    //end

    const token = userData.accessToken;
    if (loadingb) {
      return <div>Loadin...</div>;
    }
    if (errorb) {
      return <div>Error: {error.message}</div>;
    }

    if (datab?.me?.status === "PENDING") {
      return <AskingPriceOne />;
    }

    if (!token) {
      return <AskingPriceTwo />;
    } else {
      return (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <AnimatedCursor />
          <div class="main-scroll-bar">
            <header class="header-bg">
              <Header />
              <section class="top_banner">
                <div class="Web-Container">
                  <div class="grid-row contactpage">
                    <div class="grid-col-2">
                      <div class="contentBox">
                        <h1 data-aos="fade-right" data-aos-duration="1700">
                          {combination}
                        </h1>
                        <p data-aos="fade-right" data-aos-duration="1800">
                          {comments}
                        </p>
                      </div>
                    </div>
                    <div class="grid-col-2"></div>
                  </div>
                </div>
              </section>
            </header>
            <div class="main_contact-page">
              <section class="bid-main-sec">
                <div class="Web-Container">
                  <div class="bid-parent">
                    <div class="bid-grid-main">
                      <div class="bid-inner-grid-content">
                        <div class="prent-left-grid">
                          <div class="main-left-bid-grid" id="big-image">
                            <div class="serch-icn-cont">
                              <img src={searchLogo} alt="" />
                            </div>
                            <div class="logo-inner-card">
                              <img src={Logo} alt="" />
                            </div>
                            <div class="plate-bid-card">
                              {/* <img src={`${baseUrl}${plate?.numberPlate}`} alt="" /> */}
                              <img
                                class="und-img"
                                src={`${baseUrl}${plate?.numberPlate}`}
                                alt=""
                              />
                            </div>
                            <div class="text-bid-card">
                              <h2>{comments}</h2>
                            </div>
                          </div>
                          <div class="btm-card-img ">
                            <div class="btm-card-inner">
                              <div class="btm-img small-images">
                                <img id="img1" src={card1} alt="" />{" "}
                                <img id="img2" src={card2} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bid-inner-grid-content">
                        <div class="top-txt-rightbid">
                          <h2>{combination}</h2>
                          <p>{comments}</p>
                          <h1>
                            Current Bid : $
                            {data?.plate?.detail?.currentBid ??
                              plate?.askingPrice}
                          </h1>
                        </div>
                        <div id="countdown">
                          <CountdownTimer
                            redirectTo="/AuctionSig"
                            targetDate={new Date(
                              plate.detail.auction.endAt
                            ).getTime()}
                          />
                          <ul>
                            <li
                              class="timer"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              <span id="daysbidcard"></span>Day(S)
                            </li>
                            <li
                              class="timer"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              <span id="hoursbidcard"></span>hour(S)
                            </li>
                            <li
                              class="timer"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              <span id="minutesbidcard"></span>MIN(S)
                            </li>
                            <li
                              class="timer"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              <span id="secondsbidcard"></span>Sec(s)
                            </li>
                          </ul>
                        </div>
                        <div class="ending-on">
                          {console.log(endAt)}
                          <h1>
                            Ending on:<span>{formatDateTime(endAt)}</span>
                          </h1>
                        </div>
                       { console.log(MyId, "compare ----- id1")}
{console.log(plate?.user?.id , "compare ----- id") }




                        {plate?.user?.id === MyId ? <p style={{
                              color:"Green",
                            }} >you are not able to bid, this is your own plate</p> 
                      :   
                        <form onSubmit={handleSubmit}>
                          <div className="flex-sub-bid">
                            <div className="input-value">
                              <span>$</span>
                              <input
                                type="text"
                                inputMode="numeric" // This will display a numeric keyboard on mobile devices.
                                pattern="[0-9]*"
                                onKeyPress={(e) => {
                                  const isValidInput = /^\d*$/.test(e.key);
                                  if (!isValidInput) {
                                    e.preventDefault();
                                  }
                                }}
                                value={price}
                                onChange={(event) =>
                                  setPrice(event.target.value)
                                }
                              />
                            </div>

                            <div className="input-sub-bid">
                              <ScrollIntoView selector="#footer">
                                <input type="submit" value="Submit Bid" />
                              </ScrollIntoView>
                            </div>
                          </div>
                        </form>
                       }
                        <div class="btm-txt-right-col">
                          <h2>
                            Enter More than $
                            {plate?.detail?.currentBid ?? plate?.askingPrice}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <Allbids ref={scrollRef} />
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return;
}

export default AuctionProduct;
