import React from 'react'
import Logo from '../../../assets/images/logo.png'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import AnimatedCursor from '../../../components/AnimatedCursor'

function About() {
  return (
    <div>
        {/* <?php $page = "about"; ?> */}
    <AnimatedCursor/>
    <div class="main-scroll-bar">
        <header class="header-bg inner-page">
        {/* <?php include('includes/header.php'); ?> */}
        <Header/>
            <section class="top_banner">
                <div class="container">
                    <div class="grid-row auction-grid">
                        <div class="grid-col-2">
                            <div class="contentBox content leFadeIn">
                                <h1 class="leFadeIn" data-aos="fade-right" data-aos-duration="1700">About</h1>
                                <p data-aos="fade-right" data-aos-duration="1800">Lorem ipsum dolor sit amet,
                                    consectetur
                                    adipiscing elit. Nam convallis rhoncus eros,
                                    nec scelerisque nisi.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </header>
        <div class="main">
            <section class="second-sect">
                <div class="container">
                    <div class="second-grid">
                        <div class="sec-grid-img-parent">
                            <div class="sec-grid-img" data-aos="fade-right" data-aos-duration="1500">
                                <img src={Logo} alt=""/>
                            </div>
                            <div class="txt-sec-grid-img">
                                <h1 data-aos="fade-left" data-aos-duration="1500">Signatures +</h1>
                                <p data-aos="fade-left" data-aos-duration="1500">Victoria's Boutique Signature Plate
                                    Auction House</p>
                            </div>
                        </div>
                        <div class="sec-grid-parent">
                            <div class="sec-grid-txt">
                                <h1>About Us</h1>
                                <p>Established 2023</p>
                            </div>
                            <div class="sec-grid-para">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ipsum erat, dapibus
                                    ac
                                    placerat at, elementum vel mi.</p>

                                <p>Maecenas at euismod orci. Morbi turpis nunc, sodales vitae odio quis, vehicula
                                    feugiat
                                    est. Morbi odio libero, ullamcorper quis ante ut, ullamcorper feugiat lorem.
                                    Vestibulum
                                    in tincidunt risus. Sed sagittis odio eu velit vulputate ornare.</p>

                                <p> Nulla sed risus sit amet lectus vestibulum euismod ac et dolor. Donec congue, sem
                                    vel
                                    tempor imperdiet, nisl massa interdum ante.</p>

                                <p> sed elementum nisl nisl ac ligula. In ac ipsum at lacus consequat vulputate eu id
                                    orci.
                                    Nulla et tristique augue.</p>

                                <p> non maximus tellus. Suspendisse dignissim cursus tellus a efficitur. Mauris sit amet
                                    libero id dolor gravida tempus. Nulla facilisi.</p>

                                <p> Mauris varius tempus fringilla. Aliquam tristique placerat mi at dapibus.
                                    Suspendisse
                                    sit amet scelerisque sapien.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        {/* <?php include('includes/footer.php'); ?> */}
        <Footer/>
    </div>




    </div>
  )
}

export default About









