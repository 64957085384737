import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'

function Notification() {
  return (
<section className="dasgboard-miansection hundred-payment pb-5">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="user-seller-approved-unapproved-container pb-5">
    <div className="row pt-2">
    <UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <h1> Notification</h1>
        </div>
        <div className="row pb-5 my-4 Latest-Order gx-0">
          <div className="col-sm-12 col-md-10 col-lg-10 latest_order set-userdash-notification">
            <div className="order-heading">
              <div className="row payment-heading">
                <div className="col-md-8 col-lg-8 col-sm-12">
                  <h1>
                    {" "}
                    <span className="icon-shop">
                      {" "}
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/></svg>
                    </span>{" "}
                    Email Notification
                  </h1>
                </div>
              </div>
              <div className="formset pr-5">
                <fieldset className="row pt-5">
                  <div className="col-sm-12 col-md-8 col-lg-8 pt-3 d-fley">
                    <input type="checkbox" id="switch" />
                    <label htmlFor="switch">Toggle</label>
                    <span className="notify">
                      {" "}
                      Notify me when buyer submits an offer{" "}
                    </span>
                  </div>
                </fieldset>
                <fieldset className="row">
                  <div className="col-sm-12 col-md-8 col-lg-8 pt-3 d-fley">
                    <input type="checkbox" id="switch2" />
                    <label htmlFor="switch2">Toggle</label>
                    <span className="notify">
                      {" "}
                      When new product added for auction{" "}
                    </span>
                  </div>
                </fieldset>
                <fieldset className="row">
                  <div className="col-sm-12 col-md-8 col-lg-8 pt-3 d-fley">
                    <input type="checkbox" id="switch3" />
                    <label htmlFor="switch3">Toggle</label>
                    <span className="notify">
                      {" "}
                      When you win an auction product{" "}
                    </span>
                  </div>
                </fieldset>
                <fieldset className="row">
                  <div className="col-sm-12 col-md-8 col-lg-8 pt-3 d-fley">
                    <input type="checkbox" id="switch4" />
                    <label htmlFor="switch4">Toggle</label>
                    <span className="notify">
                      {" "}
                      When the auction product is ending soon{" "}
                    </span>
                  </div>
                </fieldset>
                <fieldset className="row">
                  <div className="col-sm-12 col-md-8 col-lg-8 pt-3 d-fley">
                    <input type="checkbox" id="switch5" />
                    <label htmlFor="switch5">Toggle</label>
                    <span className="notify">
                      {" "}
                      Notify me when buyer counter an offer{" "}
                    </span>
                  </div>
                </fieldset>
                <fieldset className="row">
                  <div className="col-sm-12 col-md-8 col-lg-8 pt-3 d-fley">
                    <input type="checkbox" id="switch6" />
                    <label htmlFor="switch6">Toggle</label>
                    <span className="notify">
                      {" "}
                      Notify me when seller accept the counter offer
                    </span>
                  </div>
                </fieldset>
                <a href="" className="logout">
                  Save Changes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Notification