import React from 'react'
import WebLogo from '../assets/images/logo.png'
import WebLock from '../assets/images/lock.png'
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LogoutButton from './LogoutButton';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Offcanvas } from '../../node_modules/bootstrap/js/dist/offcanvas';


function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useSelector(state => state.user.userData)
    const wishlistData = useSelector(state => state.wishlist.counter)
    const activeTab = (name) => {
        if (name === location.pathname) {
            return "active"
        }
    }

    const token = userData.accessToken

    return (
        <>
            <section class="header-sec">
                <div class="container">
                    <div class="nav_box">
                        <div class="log">
                            <Link to="/">
                                <img src={WebLogo} alt="LOGO" />
                            </Link>
                            <button class="btn btn-primary canvas-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                                <span class="bg-light ms-2 rounded-5 d-flex align-items-center justify-content-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={26}
                                        height={26}
                                        fill="currentColor"
                                        className="bi bi-list"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                        />
                                    </svg>

                                </span>
                            </button>
                        </div>
                        <div class="main_menu">
                            <ul>
                                <li class={location.pathname === "/" ? "active" : ""} aria-current="page">
                                    <Link to="/">Home</Link>
                                </li>

                                <li class={location.pathname === "/Buy" ? "active" : ""} aria-current="page"><Link to="/Buy">Buy</Link></li>
                                <li class={location.pathname === "/Sell" ? "active" : ""} aria-current="page"><Link to="/Sell">Sell</Link></li>
                                <li class={location.pathname === "/AuctionSig" ? "active" : ""} aria-current="page"><Link to="/AuctionSig">Auctions</Link></li>
                                <li class={location.pathname === "/About" ? "active" : ""} aria-current="page"><Link to="/About">About</Link></li>
                                <li class={location.pathname === "/FAQ" ? "active" : ""} aria-current="page"><Link to="/FAQ">Faqs</Link></li>
                                <li class={location.pathname === "/Results" ? "active" : ""} aria-current="page"><Link to="/Results">Results</Link></li>
                                <li class={location.pathname === "/Contact" ? "active" : ""} aria-current="page"><Link to="/Contact">Contact Us</Link></li>

                            </ul>
                        </div>



                        <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasScrollingLabel"></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                    <li className="nav-item">
                                        <Link className="nav-link active" aria-current="page" to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/Buy">
                                            Buy
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/Sell">
                                            Sell
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/AuctionSig">
                                            Auctions
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/About">
                                            About
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/FAQ">
                                            Faqs
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/Results">
                                            Results
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/Contact">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <br />
                                    <Link class="login-btn actionbtn-pg-5 btn btn-3" to="/LoginApiTest">Login</Link><br />
                                    <Link class="login-btn actionbtn-pg-5 btn btn-3" to="/Register">Sign up</Link>
                                </ul>
                            </div>
                        </div>
                        <div class="top-btns">
                            {token ? null : <Link to="/LoginApiTest" className="login-btn actionbtn-pg-5 btn btn-3">Login</Link>}
                            {token ? null : <Link to="/Register" class="login-btn actionbtn-pg-5 btn btn-3">Sign Up</Link>}
                            <Link>{token && <><LogoutButton />
                            </>
                            }</Link>

                            <div class="cartBox">
                                <span>{wishlistData}</span>
                                <Link to="/watchList"><img src={WebLock} alt="lock" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Header