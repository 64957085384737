import React from "react";
import UserSidebar from "../../../../components/UserSidebar";
import { useParams } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { RevolvingDot } from "react-loader-spinner";
import { h1 } from "fontawesome";
import { useNavigate } from 'react-router-dom';

const GET_USERS_QUERY = gql`
  query user($id: Int!) {
    user(where: { id: $id }) {
      status
      id
      username
      email
      firstName
      lastName
      city
      country
      streetAddress
      city
      phoneNumber
      postCode
      state
    	accountName
    	accountNumber
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      username
    }
  }
`;

function Profile() {
  const Swal = require("sweetalert2");
  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: ["user"],
  });
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      firstName: event.target.fname.value,
      lastName: event.target.lname.value,
      postCode: event.target.postCode.value,
      state: event.target.state.value,
      streetAddress: event.target.streetAddress.value,
      accountName: event.target.accname.value,
      accountNumber: event.target.accNo.value,
    };

    updateUser({
      variables: {
        data: formData,
      },
    })
      .then((result) => {
        console.log("User updated:", result.data.updateUser);
        // window.location.reload()
        // Handle success or show a success message
        Swal.fire("Profile Successfully Updated!", "", "success");
      })
      .catch((error) => {
        Swal.fire("Something went wrong!", "", "Error");
        console.error("Error updating user:", error);
        // Handle error or show an error message
      });
    console.log(formData.firstName, ".....Updated Name");
  };

  // const { id } = useParams();
  const userData = useSelector((state) => state.user.userData);
  const id = userData.userID;

  const { loading, error, data } = useQuery(GET_USERS_QUERY, {
    variables: { id: parseInt(id) },
  });
  if (loading)
    return (
      <section className="dasgboard-miansection">
        <div className="user-seller-approved-unapproved-container">
          <div className="row pt-2">
            <UserSidebar />
            <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
              <div className="parent-profile-form">
                <div class="profile-box-parent sett-responsive-auction-box">
                  <div class="parent-auction">
                    <h1 style={{ color: "#6D6D6D" }}>
                      <RevolvingDot
                        height="10"
                        width="10"
                        radius="45"
                        color="#6D6D6D"
                        secondaryColor=""
                        ariaLabel="revolving-dot-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  if (error) return <div>Error: {error.message}</div>;

  const user = data?.user;
    if(user.status === "DECLINE"){
      navigate("/LoginApiTest")
    }
    else{
  return (
    <section className="dasgboard-miansection pb-5">
      <div className="user-seller-approved-unapproved-container pb-5">
        <div className="row pt-2">
          <UserSidebar />
          <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <h1>Profile</h1>
            </div>
            <div className="row my-4 Latest-Order gx-0">
              <div className="col-sm-12 col-md-10 col-lg-10 latest_order set-UserDash-box">
                <div className="order-heading">
                  <h1>
                    
                    <span className="icon-shop">
                      
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                      </svg>
                    </span>
                    Edit Profile
                  </h1>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="formset pr-5">
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>First Name</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          defaultValue={user.firstName}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Last Name</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="lname"
                          name="lname"
                          defaultValue={user.lastName}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Phone</legend>
                        
                        <input
                          className="form-input phoneNumber"
                          type="tel"
                          id="phone"
                          name="phone"
                          minLength="11"
                          maxLength="11"
                          size="11"
                          defaultValue={user.phoneNumber}
                          readOnly
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Email</legend>
                        <input
                          className="form-input"
                          type="email"
                          id="email"
                          name="email"
                          defaultValue={user.email}
                          readOnly
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 pt-3">
                        <legend>Street Address</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="streetAddress"
                          name="streetAddress"
                          defaultValue={user.streetAddress}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Post Code</legend>
                        <input
                          className="form-input phoneNumber"
                          type="text"
                          id="postCode"
                          name="postCode"
                          defaultValue={user.postCode}
                        />
                      </div>
                      <div style={{marginBottom: "30px"}} className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>State</legend>
                        <input

                          className="form-input"
                          type="text"
                          id="state"
                          name="state"
                          defaultValue={user.state}
                        />
                      </div>
                      <div className="order-heading">
                  <h1>
                    Enter Payment Details
                  </h1>
                </div>                      <div>
                        <legend>Account Name*</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="accname"
                          name="accname"
                          required
                          defaultValue={user.accountName}
                          // placeholder='Account Name'
                        />
                      </div>
                     
                      <div>
                        <legend>Account Number*</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="Number"
                          id="accNo"
                          name="accNo"
                          placeholder="8 digit Account No."
                          defaultValue={user.accountNumber}
                          maxLength={4}
                          required
                          // placeholder='Account Number'
                        />
                      </div>
                    </fieldset>
                    <div className="UserDash-Logut-Btn-Set">
                      <button className="logout" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
}

export default Profile;
