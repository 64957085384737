import React from 'react'
import { Link } from 'react-router-dom'

function Page404() {
  return (
    <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <Link to="/">Go Home</Link>
    </div>
      )
}

      export default Page404