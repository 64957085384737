import React, { useState } from 'react'
import UserSidebar from '../../../../components/UserSidebar'
import UserDashboardIcnAwesomeAddressBookpng from "../../../../assets/images/Icon-awesome-address-book.png"
import UserDashboardIcnIonicIosSettingspng from "../../../../assets/images/Icon-ionic-ios-settings.png"
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';




const GET_USERS_QUERY = gql`

  query user($id: Int!){
    user(where: { id: $id}) {
      id
      username
      email
      firstName
      lastName
      city
      country
      streetAddress
      city
      phoneNumber
      postCode
      state
    }
  }

`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      username
    }
  }
`;


function Address() {
  const [addingShipAddOrBillAdd, setAddingShipAddOrBillAdd] = useState("")
  const [addingAddressDetail, setAddingAddressDetail] = useState("")

  const [updateUser] = useMutation(UPDATE_USER_MUTATION);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      streetAddress: event.target.streetAddress.value,
    };

    updateUser({
      variables: {
        data: formData,
      },
    })
      .then((result) => {
        Swal.fire('Address Updated Successfully.','','success')
        console.log('User updated:', result.data.updateUser);
        // Handle success or show a success message
      })
      .catch((error) => {
        console.error('Error updating user:', error);
        // Handle error or show an error message
      });
  };






  // const { id } = useParams();
  const userData = useSelector(state => state.user.userData)
  const id = userData.userID;


  const { loading, error, data } = useQuery(GET_USERS_QUERY, {

    variables: { id: parseInt(id) },
  });
  if (loading) return(
    <section className="dasgboard-miansection">
    <div className="user-seller-approved-unapproved-container">
      <div className="row pt-2">
        <UserSidebar />
        </div>
        </div>
        </section>
  );
  if (error) return <div>Error: {error.message}</div>;

  const user = data?.user;


  return (
    <section className="dasgboard-miansection pb-5">
      {/* Dashboard */}
      {/* dashboard */}
      <div className="user-seller-approved-unapproved-container pb-5">
        <div className="row pt-2">
          <UserSidebar />
          <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <h1>ADDRESS</h1>
            </div>
            <div className="parent-profile-form">
              <div className="profile-box-parent">
                {addingAddressDetail === "" ?
                  <div className="adress-add-funtion-parent">
                    <div className="account-security-text gapping-set">
                      <img src={UserDashboardIcnAwesomeAddressBookpng} alt="" />
                      <h2>CHANGE Or UPDATE Address</h2>
                    </div>
                    <div className="parent-address-btns">
                      <div className="adress-para-text">
                        <p>Shipping Address</p>
                      </div>
                      <div className="adress-add-btn-flex">
                        <button className="add-screen" onClick={() => {
                          setAddingShipAddOrBillAdd("shippingAddress")
                          setAddingAddressDetail('addressForm')
                        }}>ADD</button>
                        <button className="background-sett">EDIT</button>
                      </div>
                      <div className="adress-add-btn-flex">
                      </div>
                    </div>
                    <div className="address-save-btn">
                      <button>Save Changes</button>
                    </div>
                  </div> : addingAddressDetail === "addressForm" ?
                    <div className="parent-address-form">
                      <div className="address-text-btn-flex">
                        <div className="address-flex-text">
                          <img src={UserDashboardIcnIonicIosSettingspng} alt="" />
                          <h2>CHANGE Or UPDATE Address</h2>
                        </div>
                        <div className="address-form-fi-btn">
                          <button className="add-more-screen" onClick={() => setAddingAddressDetail('shipAndBillAddress')}>ADD</button>
                          <button className="form-btn-none">EDIT</button>
                        </div>
                      </div>
                      <form action="">
                        <div className="address-form-parent placeholder-input">
                          <div className="row address-names">
                            <div className="col-lg-6"><input type="fname" placeholder="First Name"  value={data.user.firstName} />
                            </div>
                            <div className="col-lg-6"><input type="fname" placeholder="Last Name" value={data.user.lastName} /></div>
                          </div>
                          
                          <div className="row address-comp-count address-email">
                            <div className="col-lg-6">
                            <input type="email" placeholder="Email" value={data.user.email}/>
                            </div>
                            <div className="col-lg-6"><input type="text" placeholder="Country" value={data.user.country}/></div>
                          </div>
                          <div className="row address-street">
                            <div className="col-lg-12"><input type="text" placeholder="Street Address" value={data.user.streetAddress}/>
                            </div>
                          </div>
                          <div className="row address-suburb-state address-phone-code">
                            <div className="col-lg-6"><input type="state" placeholder="State" value={data.user.state}/></div>
                            <div className="col-lg-6"><input type="code" placeholder="Postcode" value={data.user.postCode}/></div>
                          </div>
                          <div className="row address-phone-code">
                           
                            <div className="col-lg-6"><input type="phone" placeholder="Phone" value={data.user.phoneNumber}/></div>
                          </div>
                        </div>
                        <div className="adress-form-save-btn">
                          <button className="add-more-screen" type='button' onClick={() => setAddingAddressDetail('shipAndBillAddress')}>Save Changes</button>
                        </div>
                      </form>
                    </div> : addingAddressDetail === "shipAndBillAddress" ?
                    <form onSubmit={handleSubmit}>
                      < div className="address-banner-parent">
                        <div className="address-text-btn-flex">
                          <div className="address-flex-text">
                            <img src={UserDashboardIcnAwesomeAddressBookpng} alt="" />
                            <h2>CHANGE Or UPDATE Address</h2>
                          </div>
                          <div className="address-banner-btn">
                            <button className="add-last-screen">EDIT</button>
                          </div>
                        </div>
                        <div className="address-parent-banner">
                          <div className="adress-banner-para">
                            <p>Shipping Address</p>
                          </div>
                          <div className="parent-banner-text-parent">
                            <div className="adress-banner-box address-input-sett">
                            <input
                                  className="form-input"
                                  type="text"
                                  id="streetAddress"
                                  name="streetAddress"
                                  defaultValue={user.streetAddress}
                                />
                            </div>
                            <div className="side-box-address">
                              <img src={UserDashboardIcnIonicIosSettingspng} alt="" />
                            </div>
                          </div>
                          <div className="address-banner-btn-parent">
                            <button className="add-last-screen">Save Changes</button>
                          </div>
                        </div>
                      </div>
                      </form>
                       : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Address