import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'

function Change_update_address() {
  return (
<section className="dasgboard-miansection pb-5">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="user-seller-approved-unapproved-container pb-5">
    <div className="row pt-2">
<UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <h1>ADDRESS</h1>
        </div>
        <div className="row pb-5 my-4 Latest-Order gx-0">
          <div className="col-sm-12 col-md-10 col-lg-10 latest_order">
            <div className="order-heading">
              <div className="row">
                <div className="col-8">
                  <h1>
                    {" "}
                    <span className="icon-shop">
                      {" "}
                      <i className="fa-solid fa-address-book" />{" "}
                    </span>{" "}
                    CHANGE Or UPDATE Address{" "}
                  </h1>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <a href="" className="logout Shipping update">
                    ADD
                  </a>
                  <a href="" className="logout Shipping edit update">
                    EDIT
                  </a>
                </div>
              </div>
            </div>
            <div className="formset pb-5 pr-5">
              <fieldset className="row pt-5">
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input restrict-numbers"
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="First Name"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input restrict-numbers"
                    placeholder="Last Name"
                    type="text"
                    id="fname"
                    name="fname"
                  />
                </div>
              </fieldset>
              <fieldset className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input restrict-numbers"
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Company ( Optional )"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input restrict-numbers"
                    placeholder="Country"
                    type="text"
                    id="fname"
                    name="fname"
                  />
                </div>
              </fieldset>
              <fieldset className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 pt-3">
                  <input
                    className="form-input"
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Street Address"
                  />
                </div>
              </fieldset>
              <fieldset className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input "
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Suburb"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input "
                    placeholder="State"
                    type="text"
                    id="fname"
                    name="fname"
                  />
                </div>
              </fieldset>
              <fieldset className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input "
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Postcode"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input phoneNumber"
                    placeholder="Phone"
                    type="tell"
                    id="fname"
                    name="fname"
                    minLength={11}
                    maxLength={11}
                    size={11}
                    required=""
                  />
                </div>
              </fieldset>
              <fieldset className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                  <input
                    className="form-input"
                    placeholder="Email"
                    type="email"
                    id="fname"
                    name="fname"
                  />
                </div>
              </fieldset>
              <a href="./update-address.html" className="logout">
                Save Changes
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Change_update_address