import { React, useState } from "react";
import logo from "../assets/images/logoas.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/slices/userSlice";
import { logoutWishlist } from "../redux/slices/wishlistSlice";
import { gql, useQuery } from "@apollo/client";
import UnApprovedSideBar from "./UnApprovedSideBar";
import RegisterUserDashboardGroup1 from "../assets/images/_Group_.png";
import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl, baseUrl2 } from "../utils/Function";

// import {  useParams } from 'react-router-dom';

const Me = gql`
  query {
    me {
      status
      firstName
      lastName
      email
      phoneNumber
      drivingLicense
    }
  }
`;
const QUERY_USER = gql`
  query User($id: Int!) {
    user(where: { id: $id }) {
      id
      avatar
    }
  }
`;
function UserSidebar() {
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let { userId } = useParams();

  const [selectedFile, setSelectedFile] = useState(null);
  const ID = userData.userID;
  const {
    loading,
    error: error3,
    data: data3,
  } = useQuery(QUERY_USER, {
    variables: { id: ID },
  });
  // console.log(data3, "----chal ja");
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append("avatar", selectedFile);
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${baseUrl}users`,
        headers: {
          Authorization: `Bearer ${userData?.accessToken}`, // Replace with your actual access token
        },
        data: formData,
      };

      axios
        .request(config)
        .then((response) => {
          Swal.fire(
            "Profile Picture is Updated!",
            "Kindly Reload Your Page",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("No file selected.");
    }
  };

  const handleLogOut = () => {
    dispatch(logoutUser());
    dispatch(logoutWishlist());
    navigate("/loginApiTest");
  };

  const { loadin, error, data } = useQuery(Me);
  if (loadin) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  // console.log(data?.me?.status, "-------Unappppp");
  if (data?.me?.status === "PENDING") {
    return <UnApprovedSideBar />;
  } else {
    return (
      <div className="col-sm-3 col-md-3 col-lg-3 vertical-sidebar">
        <div id="wrapper">
          <div className="navbar-header">
            <Link href="" className="navbar-brand" id="sidebar-toggle">
              <i className="fa fa-bars" />
            </Link>
          </div>
          <aside id="sidebar-wrapper">
            <div className="logo-img">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="profile-img">
              <div className="avatar-upload">
              <div className="avatar-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                            <label htmlFor="imageUpload"></label>
                        </div>
                        <div className="avatar-preview">
                        <img src={`${baseUrl2}${data3?.user?.avatar}`} alt="Avatar" class="avatar"></img>
                        </div>
              </div>
            </div>
            <ul className="sidebar-nav">
              <li
                className={
                  window.location.pathname === `/UserSellerDashboard`
                    ? "active-user-dashboard"
                    : ""
                }
                aria-current="page"
              >
                <Link to={`/UserSellerDashboard`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" />
                  </svg>
                  Dashboard
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === `/UserProfile`
                    ? "active-user-dashboard"
                    : ""
                }
                aria-current="page"
              >
                <Link to={`/UserProfile`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                  </svg>
                  Profile
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === `/AccountSecurity`
                    ? "active-user-dashboard"
                    : ""
                }
                aria-current="page"
              >
                <Link to={`/AccountSecurity`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z" />
                  </svg>
                  Account Security
                </Link>
              </li>

              {/* payment page  */}

              {/* <li className={window.location.pathname === `/UserPayment/${ID}` ? "active-user-dashboard" : ""} aria-current="page">
              <Link to={`/UserPayment/${ID}`}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" /></svg>
                Payment
              </Link>
            </li> */}

              {/* payment page  */}

              {/* <li className={window.location.pathname === `/Address` ? "active-user-dashboard" : ""} aria-current="page">
                <Link to={`/Address`}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M96 0C60.7 0 32 28.7 32 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H96zM208 288h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H144c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80zM496 192c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V336z" /></svg>
                  Address
                </Link>
              </li> */}

              <li
                className={
                  window.location.pathname === `/UserSellerAuction`
                    ? "active-user-dashboard"
                    : ""
                }
                aria-current="page"
              >
                <Link to={`/UserSellerAuction`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z" />
                  </svg>
                  Auction
                </Link>
              </li>
              <li
                className={
                  window.location.pathname === `/UserSellerOfferOne`
                    ? "active-user-dashboard"
                    : ""
                }
                aria-current="page"
              >
                <Link to={`/UserSellerOfferOne`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" />
                  </svg>
                  Offer
                </Link>
              </li>
              <div className="nick">
                <h2>
                  {" "}
                  <span className="pen-icon">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                    </svg>
                  </span>{" "}
                  {/* {firstName} */}
                  <span className="jobname">{data?.me?.firstName}</span>
                </h2>
                <Link onClick={handleLogOut} className="logout">
                  Logout
                </Link>
                <div className="form-img-txt set-hom-link set-responsive">
                  <img src={RegisterUserDashboardGroup1} alt="" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
                  </svg>
                  <Link to="/">Go to Signature +</Link>
                </div>
              </div>
            </ul>
          </aside>
        </div>
      </div>
    );
  }
}

export default UserSidebar;
