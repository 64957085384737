export const UserData = [
    {
      id: 1,
      year: "jjs",
      userGain: 4300,
      userLost: 234,
    },
    {
      id: 2,
      year: "os",
      userGain: 7567,
      userLost: 345,
    },
    {
      id: 3,
      year: "sl",
      userGain: 15288,
      userLost: 555,
    },
    {
      id: 4,
      year: "jxl",
      userGain: 11000,
      userLost: 4555,
    },
    {
      id: 5,
      year: "uy",
      userGain: 17000,
      userLost: 234,
    },
    {
        id: 6,
        year: "jm",
        userGain: 15000,
        userLost: 234,
      },
      {
        id: 7,
        year: "js",
        userGain: 18700,
        userLost: 234,
      },
      {
        id: 8,
        year: "hs",
        userGain: 17500,
        userLost: 234,
      },
      {
        id: 9,
        year: "kd",
        userGain: 15100,
        userLost: 234,
      },
      {
        id: 10,
        year: "ys",
        userGain: 23000,
        userLost: 234,
      },
      {
        id: 11,
        year: "tr",
        userGain: 21040,
        userLost: 234,
      },
      {
        id: 12,
        year: "kc",
        userGain: 26700,
        userLost: 234,
      },
  ];