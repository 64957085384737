import { Routes, Route, Outlet } from "react-router-dom";
// ADMIN DASHBOARD PAGES IMPORTS
import Customer from './pages/Admin-Dashboard/Customer/Customer'
import Test from './pages/signature/Register/Test'
import MainDashboard from './pages/Admin-Dashboard/Dasboard/DashboardMain'
import FormRequest from './pages/Admin-Dashboard/Form-Request/FormRequest'
import Analytic from './pages/Admin-Dashboard/Analytic/Analytic'
import BidsAct from './pages/Admin-Dashboard/BidsAct/BidsAct'
import BidsActModal1 from './pages/Admin-Dashboard/BidsActModal1/BidsActModal1'
import BidsActModal2 from './pages/Admin-Dashboard/BidsActModal2/BidActModal2'
import BidsActModal3 from './pages/Admin-Dashboard/BidsActModal3/BidsActModal3'
import BidsActModal4 from './pages/Admin-Dashboard/BidsActModal4/BidsActModal4'
import BidsActModal5 from './pages/Admin-Dashboard/BidsActModal5/BidsActModal5'
import BidsReceived from './pages/Admin-Dashboard/BidsReceived/BidsReceived'
import EditProfile from './pages/Admin-Dashboard/EditProfile/EditProfile'
import Main_first_modal from './pages/Admin-Dashboard/Main_first_modal/Main_first_modal'
import Main_fourth_modal from './pages/Admin-Dashboard/Main_fourth_modal/Main_fourth_modal'
import Main_second_modal from './pages/Admin-Dashboard/Main_second_modal/Main_second_modal'
import Main_third_modal from './pages/Admin-Dashboard/Main_third_modal/Main_third_modal'
import Main_fifth_modal from './pages/Admin-Dashboard/Main-fifth-modal/Main_fifth_modal'
// import Main_sixth_modal from './pages/Admin-Dashboard/Main-sixth-modal-modal/Main_sixth_modal'
import Payment from './pages/Admin-Dashboard/Payment/Payment'
import Payment2 from './pages/Admin-Dashboard/Payment2/Payment2'
import Plates_recieved from './pages/Admin-Dashboard/Plates_recieved/Plates_recieved'
import Plates_recieved_modal from './pages/Admin-Dashboard/Plates_recieved_modal/Plates_recieved_modal'
import Plates_record_modal_one from './pages/Admin-Dashboard/Plates_record_modal_one/Plates_record_modal_one'
import Reports from './pages/Admin-Dashboard/Reports/Reports'
import SellerDashboard from './pages/Admin-Dashboard/Seller-Dashboard/SellerDashBoard'
import UserDashBoard from './pages/Admin-Dashboard/User-Dashboard/UserDashBoard'
import Verify_account from './pages/Admin-Dashboard/Verify_account/Verify_account'
import Verify_code from './pages/Admin-Dashboard/Verify_code/Verify_code'
import Winner_list from './pages/Admin-Dashboard/Winner_list/Winner_list'
import Plates_recieved_details from "./pages/Admin-Dashboard/Plates_recieved/Plates_recieved_details";
// ADMIN DASHBOARD PAGES IMPORTS

// USER DASHBOARD SELLER AND BUYER PAGES IMPORTS
import AccountSecurity from './pages/User-Dashboard/Buyer-Dashboard/Account-Security/AccountSecurity'
import Address from './pages/User-Dashboard/Buyer-Dashboard/Address/Address'
import Auction from './pages/User-Dashboard/Buyer-Dashboard/Auction/Auction'
import Auction2 from './pages/User-Dashboard/Buyer-Dashboard/Auction2/Auction2'
import Auction3 from './pages/User-Dashboard/Buyer-Dashboard/Auction3/Auction3'
import Auction4 from './pages/User-Dashboard/Buyer-Dashboard/Auction4/Auction4'
import UserBuyerDashboard from './pages/User-Dashboard/Buyer-Dashboard/Buyer-Dashboard/BuyerDashboard'
import ChangeUpdateAddress from './pages/User-Dashboard/Buyer-Dashboard/Change-Update-Address/ChangeUpdateAddress'
import Notification from './pages/User-Dashboard/Buyer-Dashboard/Notification/Notification'
import OfferOne from './pages/User-Dashboard/Buyer-Dashboard/Offer-One/Offerone'
import OfferThree from './pages/User-Dashboard/Buyer-Dashboard/Offer-Three/Offerthree'
import OfferTwo from './pages/User-Dashboard/Buyer-Dashboard/Offer-Two/Offertwo'
import UserPayment from './pages/User-Dashboard/Buyer-Dashboard/Payment/Payment'
import UserPaymentStripe from './pages/User-Dashboard/Buyer-Dashboard/Payment-Stripe/Payment_strip'
import UserProfile from './pages/User-Dashboard/Buyer-Dashboard/Profile/Profile'
import UserUpdateAddress from './pages/User-Dashboard/Buyer-Dashboard//Update-Address/Update_address'
// BUYER
import UserAuction5 from './pages/User-Dashboard/Seller-Dashboard/Auction-5/Auction5'
import UserAuction6 from './pages/User-Dashboard/Seller-Dashboard/Auction-6/Auction6'
import UserOfferFive from './pages/User-Dashboard/Seller-Dashboard/Offer-Five/Offerfive'
import UserOfferFour from './pages/User-Dashboard/Seller-Dashboard/Offer-Four/Offerfour'
import UserOfferSix from './pages/User-Dashboard/Seller-Dashboard/Offer-Six/Offersix'
import UserSellerDashboard from './pages/User-Dashboard/Seller-Dashboard/User-Seller-Dashboard/SellerDashboard'
// BUYER
// USER DASHBOARD SELLER AND BUYER PAGES IMPORTS


// SIGNATURE WEB PAGES IMPORTS
import Index from './pages/signature/Home/index'
import About from './pages/signature/About/about'
import AskingPriceOne from './pages/signature/Asking-Price-One/AskingPriceOne'
import AskingPriceTwo from './pages/signature/Asking-Price-Two/AskingPriceTwo'
import OfferConsideredTwo from './pages/signature/Offer-Considered-Two/OfferConsideredTwo'
import Results from './pages/signature/Results/Results'
import Sell from './pages/signature/Sell/Sell'
// import Login from './pages/signature/LoginApiTest/LoginApiTest'
import Register from './pages/signature/Register/Register'
// import RegisterTest from './pages/signature/Register/RegisterTest'

import Buy from './pages/signature/Buy/Buy'
import AuctionSig from './pages/signature/Auction/Auction'
import LoginApiTest from "./pages/LoginTestApi/LoginApiTest";
import AuctionProduct from "./pages/signature/Auction/AuctionProduct";
import WebRegisterSuccuessFullPage from "./pages/signature/Register-Succuess/RegisterSuccuessFull";
// import FAQ from "./pages/signature/FAQ/FAQ";
// import Counter from "./components/Counter";


// SIGNATURE WEB PAGES IMPORTS

// ALL STYLESHEETS IMPORTS
import './assets/css/frontpages/stylesheet.css'
import './assets/css/userdashboard/style.css'
import './assets/css/style.css'
import './assets/css/all.css'
import Counter from "./components/Counter";
import ProtectedRoute, { PrivateRoute } from "./components/ProtectedRoutes";

// import Home from "./pages/signature/Home/Home";
// import About from "./pages/signature/About/About";
import FAQ from "./pages/signature/FAQ/FAQ";
// import Results from "./pages/signature/Results/Results";
import OfferConsider from "./pages/signature/OfferConsider/OfferConsider";
// import ContactUs from "./pages/signature/Contact/ContactUs";
import AuctionTest from "./pages/signature/Auction/AuctionTest";
import Plate from "./pages/signature/Buy/Plate";
import LeftMainGridBuy from "./pages/signature/Buy/LeftMainGridBuy";
import FormDetails from "./pages/Admin-Dashboard/Form-Request/FormDetails";
import { useSelector } from "react-redux";
import jwt_decode from 'jwt-decode';
import { gql, useQuery } from "@apollo/client";
import WatchList from "./components/WatchList";
import test from "./pages/Test1";
import Test1 from "./pages/Test1";
import Completion from "./components/payment/Completion"
import Page404 from "./components/Page404";




// ALL STYLESHEETS IMPORTS
function App() {

    //   const  isAuthenticated = !!localStorage.getItem("token")

    const userData = useSelector(state => state.user.userData)
    const isAuthenticated = !!userData.accessToken;

    return (
        <Routes>

            {/* ADMIN DASHBOARD PAGES ROUTES */}

            <Route path="/customer" element={<Customer />} />
            <Route path="/MainDashboard" element={<MainDashboard />} />
            <Route path="/FormRequest" element={<FormRequest />} />
            <Route path="/Analytic" element={<Analytic />} />
            <Route path="/BidsAct" element={<BidsAct />} />
            <Route path="/BidsActModal1" element={<BidsActModal1 />} />
            <Route path="/BidsActModal2" element={<BidsActModal2 />} />
            <Route path="/BidsActModal3" element={<BidsActModal3 />} />
            <Route path="/BidsActModal4" element={<BidsActModal4 />} />
            <Route path="/BidsActModal5" element={<BidsActModal5 />} />
            <Route path="/BidsReceived" element={<BidsReceived />} />
            <Route path="/EditProfile" element={<EditProfile />} />
            <Route path="/Main_first_modal" element={<Main_first_modal />} />
            <Route path="/Main_fourth_modal" element={<Main_fourth_modal />} />
            <Route path="/Main_second_modal" element={<Main_second_modal />} />
            <Route path="/Main_third_modal" element={<Main_third_modal />} />
            <Route path="/Main_fifth_modal" element={<Main_fifth_modal />} />
            {/* <Route path="/Main_sixth_modal" element={<Main_sixth_modal />} /> */}
            <Route path="/Payment" element={<Payment />} />
            <Route path="/Payment2" element={<Payment2 />} />
            <Route path="/Plates_recieved" element={<Plates_recieved />} />
            <Route path="/Plates_recieved_modal" element={<Plates_recieved_modal />} />
            <Route path="/Plates_record_modal_one" element={<Plates_record_modal_one />} />
            <Route path="/Reports" element={<Reports />} />
            <Route path="/SellerDashboard" element={<SellerDashboard />} />
            <Route path="/UserDashBoard/:id" element={<UserDashBoard />} />
            <Route path="/Verify_account" element={<Verify_account />} />
            <Route path="/Verify_code" element={<Verify_code />} />
            <Route path="/Winner_list" element={<Winner_list />} />
            <Route path="/Plates_recieved_details/:id" element={<Plates_recieved_details />} />
            {/* ADMIN DASHBOARD PAGES ROUTES */}

            {/* USER DASHBOARD SELLER AND BUYER PAGES ROUTES */}
            <Route path="/Auction2" element={<Auction2 />} />
            <Route path="/Auction3" element={<Auction3 />} />
            <Route path="/Auction4" element={<Auction4 />} />
            <Route path="/UserBuyerDashboard" element={<UserBuyerDashboard />} /> y
            <Route path="/ChangeUpdateAddress" element={<ChangeUpdateAddress />} /> y
            <Route path="/OfferThree" element={<OfferThree />} />
            <Route path="/OfferTwo" element={<OfferTwo />} />
            <Route path="/UserPaymentStripe" element={<UserPaymentStripe />} /> y
            <Route path="/UserUpdateAddress" element={<UserUpdateAddress />} /> y


            {/* BUYER */}
            <Route path="/UserAuction5" element={<UserAuction5 />} /> y
            <Route path="/UserAuction6" element={<UserAuction6 />} />
            <Route path="/UserOfferFive" element={<UserOfferFive />} />
            <Route path="/UserOfferFour" element={<UserOfferFour />} />
            <Route path="/UserOfferSix" element={<UserOfferSix />} />
            <Route path="/test1" element={<Test1 />} />



            {/* --------------Protected User DashBoard---------------- */}


            <Route
                path="/UserSellerDashboard"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><UserSellerDashboard /></ProtectedRoute>} />
            <Route
                path="/UserProfile"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><UserProfile /></ProtectedRoute>} />
            <Route
                path="/UserPayment"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><UserPayment /></ProtectedRoute>} />
            <Route
                path="/UserPayment"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><UserPayment /></ProtectedRoute>} />
            <Route
                path="/AccountSecurity"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><AccountSecurity /></ProtectedRoute>} />
            <Route
                path="/Address"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><Address /></ProtectedRoute>} />
            <Route
                path="/UserSellerAuction"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><Auction /></ProtectedRoute>} />
            <Route
                path="/UserSellerOfferOne"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><OfferOne /></ProtectedRoute>} />
            <Route
                path="/Notification/:id"
                element={<ProtectedRoute isUserLogIn={isAuthenticated}><Notification /></ProtectedRoute>} />

            {/* --------------    end   ---------------- */}

            {/* BUYER */}
            {/* USER DASHBOARD SELLER AND BUYER PAGES ROUTES */}
            {/* Components */}
            <Route path="/counter" element={<Counter />} />
            {/* <Route
          path="/offerConsider/:id"
          element={<ProtectedRoute isUserLogIn={true}><OfferConsider/></ProtectedRoute>}/> */}


            {/* SIGNATURE WEB PAGES ROUTES */}
            <Route path="/" element={<Index />} />
            <Route path="/About" element={<About />} />
            {/* <Route path="/WebAuction" element={<WebAuction />} />  */}
            {/* <Route path="/AskingPriceOne" element={<AskingPriceOne />} />  */}
            <Route path="/AskingPriceTwo/:id" element={<AskingPriceTwo />} />
            {/* <Route path="/AskingPriceTwo" element={<AskingPriceTwo />} /> */}

            <Route path="/OfferConsideredTwo/:id" element={<OfferConsideredTwo />} />
            {/* <Route path="/Results" element={<Results />} />  */}
            <Route path="/FAQ" element={<FAQ />} /> y
            <Route path="/LoginApiTest" element={<LoginApiTest />} />
            <Route path="/Sell" element={<Sell />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Buy" element={<Buy />} />
            <Route path="/watchList" element={<WatchList />} />
            <Route path="/Results" element={<Results />} />
            <Route path="/AuctionSig" element={<AuctionSig />} />
            <Route path="/WebRegisterSuccuessFullPage" element={<WebRegisterSuccuessFullPage />} />

            {/* SIGNATURE WEB PAGES ROUTES */}
            <Route path="/auctionProduct/:id" element={<AuctionProduct />} />
            <Route path="/auctionTest/:id" element={<AuctionTest />} />
            <Route path="/FormDetails/:id" element={<FormDetails />} />
            <Route path="/offerConsider/:id" element={<OfferConsider />} />
            <Route path="/plate" element={<Plate />} />
            <Route path="/Filter" element={<LeftMainGridBuy />} />

            {/* <Route path="/register-test" element={<RegisterTest/>}/> */}

            {/* payment routes */}
            <Route path="/completion" element={<Completion/>}/>
            <Route path="/*" element={<Page404/>}/>
        </Routes>
    );
}
export default App;