import React from 'react'
import Sidebar from '../../../components/Sidebar'
import cancelIcon from '../../../assets/images/cancel-icn.png'
import pendingBtn from '../../../assets/images/pending-btn-main.png'
function BidsActModal1() {
  return (
<div class="bg-dashboard">
        {/* <?php $page="bids-activity";?> */}
    <div id="main">

                <Sidebar/>
        <div class="dashboard-right-side main-fourth-modal-parent">
            <div class="modal-form-request-parent">
                <div style={{boxShadow: "none"}} class="modal-form-request-inner">
                    <div class="cancel-icn">
                        <a href="bids-activity.php"><img src={cancelIcon} alt=""/></a>
                    </div>
                    <div class="abc-heading text-center abc-chng">
                        <h1>AB-01</h1>
                    </div>
                    <div class="row">
                        
                        <div class="col-lg-6 bd-left">
                        <div class="main-modal-width">
                            <div class="abc-heading text-center">
                                <h1>Seller Details</h1>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Name
                                            <input type="text" placeholder="Ethen Booker"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Email
                                            <input type="email" placeholder="ethenbooker12@outlook.com"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label class="d-flex flex-column" for="modalinp1">Address
                                            <textarea placeholder="Lorem ipsum dolor sit amet, consectetur ad
                                                ipiscing elit. Etiam consectetur ex ut felis co
                                                ngue sagittis.">
                                            </textarea>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Reserve Price
                                            <input type="text" placeholder="$4000.00"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Desired Plate Price
                                            <input type="text" placeholder="$4000.00"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="send-req-btn">
                       
                                <a href="">Amount Transfer</a>
                            </div>
                            </div>
                        </div>
                        
                        
                        <div class="col-lg-6">
                        <div class="main-modal-width">
                            <div class="abc-heading text-center">
                                <h1>Buyer Details</h1>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Name
                                            <input type="text" placeholder="John Smith"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Email
                                            <input type="email" placeholder="jh_smith12@outlook.com"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label class="d-flex flex-column" for="modalinp1">Address
                                            <textarea placeholder="Lorem ipsum dolor sit amet, consectetur ad
                                                ipiscing elit. Etiam consectetur ex ut felis co
                                                ngue sagittis.">
                                            </textarea>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Winning Bid
                                            <input type="text" placeholder="$4000.00"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12">


                                
                                    <div class="modal-input-form-req py-2">
                                        <label for="modalinp1">Status
                                            </label>
                                        <img src={pendingBtn} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div class="send-req-btn">
                                <h6>Request for Initial Deposit</h6>
                                <a href="">Send Requests</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</div>
  )
}

export default BidsActModal1


{/* 
    <!-- Latest compiled JavaScript -->
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"></script>
    <script src="assets/js/custom.js"></script>
    <script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script>
 */}
