import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import cancel from "../../../assets/images/cancel-icn.png";
import IconWebModalSet from "../../../assets/images/Icon-feather-edit.png";
import license from "../../../assets/images/license-img.png";
import modal from "../../../assets/images/modal-plate.png";
import "../../../../node_modules/bootstrap/js/dist/tab";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { IpAddress } from "../../../utils/Function";
import {
  gql,
  useMutation,
  useQuery,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import ResponsiveNavBarDasbord from "../../../components/ResponsiveNavBarDasbord";

const customStyles = {
  content: {
    top: "53%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "75%",
  },
};
const customTimerStyles = {
  content: {
    top: "53%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "50%",
  },
};

const client = new ApolloClient({
  uri: IpAddress,
  cache: new InMemoryCache(),
});

const GET_AUCTION_PENDING_PLATES = gql`
  query Plates {
    plates(where: { purpose: AUCTION}) {
      combination
      id
      numberPlate
      status
      user {
        email
        firstName
        lastName
      }
    }
  }
`;

const GET_LISTING_PENDING_PLATES = gql`
  query Plates {
    plates(where: { purpose: LISTING }) {
      combination
      id
      numberPlate
      status
      user {
        email
        firstName
        lastName
      }
    }
  }
`;

const APPROVE_PLATES_MUTATION = gql`
  mutation UpdatePlate($id: Int!) {
    updatePlate(data: { status: APPROVED }, where: { id: $id }) {
      comments
      id
      status
    }
  }
`;

const CREATE_AUCTION_MUTATION = gql`
  mutation CreateAuction($endAt: DateTime!, $plateId: Int!) {
    createAuction(data: { endAt: $endAt, plateId: $plateId }) {
      id
    }
  }
`;

const REMOVE_PLATE_MUTATION = gql`
  mutation RemovePLate($id: Int!) {
    deletePlate(where: { id: $id }) {
      id
      combination
    }
  }
`;

function Plates_recieved() {
  const Swal = require("sweetalert2");

  const handleDisable = (id) => {
    console.log(id);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          RemovePLate({ variables: { id: parseInt(id) } })
            .then(() => {
              Swal.fire("Declined!", "Your file has been Declined.", "success");
              refetchListingPlates(); // Refetch the first query after the mutation is successful
              refetchListingPlates2(); // Refetch the second query after the mutation is successful
            })
            .catch((error) => {
              // Handle error
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: '<a href="">Why do I have this issue?</a>',
              });
            });
        }
      })
      .catch((error) => {
        // Handle error
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      });
  };
  const [RemovePLate, { data }] = useMutation(REMOVE_PLATE_MUTATION);

  const [createAuction] = useMutation(CREATE_AUCTION_MUTATION, {
    fetchPolicy: "network-only",
    client,
    refetchQueries: ["GET_AUCTION_PENDING_PLATES"],
  });
  const [durationString, setDurationString] = useState("");
  const addTime = async (e) => {
    e.preventDefault();
    // Get the input values
    const days = e.target.days.value;
    const hours = e.target.hours.value;
    const minutes = e.target.minutes.value;
    const seconds = e.target.seconds.value;
    const id = e.target.id.value;

    // Convert the values to numbers
    const daysToAdd = parseInt(days);
    const hoursToAdd = parseInt(hours);
    const minutesToAdd = parseInt(minutes);
    const secondsToAdd = parseInt(seconds);
    const ID = parseInt(id);

    // Get the current date and time
    const currentDate = new Date();

    // Add the specified duration to the current date and time
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + daysToAdd,
      currentDate.getHours() + hoursToAdd,
      currentDate.getMinutes() + minutesToAdd,
      currentDate.getSeconds() + secondsToAdd
    );

    // Log the result
    console.log("New Date:", newDate.toISOString());
    console.log(ID);

    try {
      const { data } = await createAuction({
        variables: {
          endAt: newDate.toISOString(),
          plateId: ID,
        },
      });

      Swal.fire("Congratulations You have Approved!", "success");
      refetchListingPlates2(); // Refetch the second query after the mutation is successful
      setIsPlateTimerMod("");
      // ... (existing code)
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };

  // ...

  const [approveUser] = useMutation(APPROVE_PLATES_MUTATION, {
    client,
    update: (cache, { data: { updatePlate } }) => {
      cache.modify({
        fields: {
          plates(existingPlates = [], { readField }) {
            return existingPlates.filter(
              (plateRef) => updatePlate.id !== readField("id", plateRef)
            );
          },
        },
      });
    },
  });

  const handleApprove = (id) => {
    console.log(id);
    approveUser({ variables: { id: parseInt(id) } })
      .then(() => {
        Swal.fire("Congratulations! You have Approved!", "success").then(() => {
          const updatedPlates = listingData?.plates.map((plate) => {
            if (plate.id === id) {
              return { ...plate, status: "APPROVED" };
            }
            return plate;
          });
          client.writeQuery({
            query: GET_LISTING_PENDING_PLATES,
            data: { plates: updatedPlates },
          });
        });

        refetchListingPlates2(); // Refetch the second query after the mutation is successful
      })
      .catch((error) => {
        // Handle error
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      });
  };

  // ...

  const [isplatereceviedaproval, setIsPlateReceviedAproval] = useState(false);
  const receviedplatemodal1 = () => {
    setIsPlateReceviedAproval((prev) => !prev);
  };
  const [isplateapprovedmod, setIsPlateApprovedMod] = useState(false);
  const settheapprovedplatemod = () => {
    setIsPlateApprovedMod((prev) => !prev);
  };
  const [isplatetimermod, setIsPlateTimerMod] = useState(false);

  const [idValue, setIdValue] = useState("");

  const setthetimerplatemod = (id) => {
    console.log(id);
    setIsPlateTimerMod((prev) => !prev);
    setIdValue(id); // Save the id value in state
  };
  const handleCancel = () => {
    setIsPlateTimerMod("");
    setIdValue("");
  };

  const {
    loading: auctionLoading,
    error: auctionError,
    data: auctionData,
  } = useQuery(
    GET_AUCTION_PENDING_PLATES,
    { fetchPolicy: "network-only", client },
    { refetchQueries: ["GET_AUCTION_PENDING_PLATES"] }
  );
  const {
    loading: listingLoading,
    error: listingError,
    data: listingData,
  } = useQuery(
    GET_LISTING_PENDING_PLATES,
    { fetchPolicy: "network-only", client },
    { refetchQueries: ["GET_LISTING_PENDING_PLATES   "] }
  );
  const { refetch: refetchListingPlates } = useQuery(
    GET_LISTING_PENDING_PLATES,
    {
      client,
    }
  );
  const { refetch: refetchListingPlates2 } = useQuery(
    GET_AUCTION_PENDING_PLATES,
    {
      client,
    }
  );
  if (auctionLoading || listingLoading)
    return (
      <div className="bg-dashboard">
        <main id="main">
          <ResponsiveNavBarDasbord />
          <Sidebar />
          <div className="dashboard-right-side">
            <div className="form-req-tble-parent">
              <div className="payment-inner-page-set">
                <div
                  style={{ backgroundColor: "#FFFBF0" }}
                  className="form-req-tble-inner d-flex"
                >
                  <h1 style={{ color: "#6D6D6D" }}>Loading....</h1>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  if (auctionError || listingError)
    return <p>Error: {auctionError?.message || listingError?.message}</p>;

  return (
    <div className="bg-dashboard">
      <main id="main">
        <ResponsiveNavBarDasbord />
        <Sidebar />
        <div className="dashboard-right-side plates-rec-parent">
          <div className="page-title">
            <h1>Plates Received</h1>
          </div>
          <Modal
            isOpen={isplatereceviedaproval}
            onRequestClose={receviedplatemodal1}
            style={customStyles}
          >
            <div className={`modal-form-request-parent`}>
              <div className="modal-form-request-inner">
                <div className="cancel-icn">
                  <a href="">
                    <img src={cancel} alt="" />
                  </a>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            First Name
                            <input type="text" placeholder="Steve" readOnly />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Last Name
                            <input type="email" placeholder="Smith" readOnly />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Email
                            <input
                              type="text"
                              placeholder="smith_jhon12@outlook.com"
                              readOnly
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Phone
                            <input
                              type="text"
                              placeholder="1234 567 890"
                              readOnly
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Plate Combination
                            <input type="text" placeholder="XX - 3 " readOnly />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Desired Plate Price
                            <input
                              type="text"
                              placeholder="$4000.00"
                              readOnly
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Reserve Price
                            <input
                              type="text"
                              placeholder="$3500.00"
                              readOnly
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label
                            className="d-flex flex-column"
                            htmlFor="modalinp1"
                          >
                            Comment
                            <textarea
                              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porttitor augue a leo imperdiet,
                                            sed tincidunt lorem suscipit. Ut nibh mauris, rutrum et amet, rutrum sed massa."
                              defaultValue={
                                "                                            "
                              }
                              readOnly
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="two-img-parent d-flex flex-column">
                      <div className="img-plate-modal">
                        <img src={license} alt="" />
                      </div>
                      <div className="img-plate-modal mt-3">
                        <img src={modal} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="approv-dec-btn-parent-plate-modal d-flex justify-content-center mt-4 mb-3">
                      <div className="approv-btn-plate-modal me-4">
                        <Link onClick={settheapprovedplatemod}>Approved</Link>
                      </div>
                      <div className="dec-btn-plate-modal">
                        <Link to="">Decline</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isplateapprovedmod}
            onRequestClose={setIsPlateApprovedMod}
            style={customStyles}
          >
            <div className="payment-inner-page-set set-plate-recieve set-plate-responsive">
              <div className="tabs-plate-rec-parent">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="form-req-tble-parent">
                      <div className="form-req-tble-inner d-flex">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Plate Number</h3>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Seller Name</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Email</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Status</h3>
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Mark Allen</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>allen.mark@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Rubin Parker</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>rubin-parker321@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Ethen Booker</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>booker_ethen@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>John Smith</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>john.123@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-01
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Nick Peter</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>peter.nick007@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="form-req-tble-parent">
                      <div className="form-req-tble-inner d-flex">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Plate Number</h3>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Seller Name</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Email</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Status</h3>
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Mark Allen</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>allen.mark@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Rubin Parker</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>rubin-parker321@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070;" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Ethen Booker</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>booker_ethen@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-04
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>John Smith</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>john.123@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                        <div
                          style={{ width: "15%" }}
                          className="form-req-tble-content text-center"
                        >
                          <a href="">
                            <h3 style={{ borderBottom: "2px solid #707070" }}>
                              XX-01
                            </h3>
                          </a>
                        </div>
                        <div
                          style={{ width: "30%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>Nick Peter</h3>
                        </div>
                        <div
                          style={{ width: "20%" }}
                          className="form-req-tble-content text-center"
                        >
                          <h3>peter.nick007@gmail.com</h3>
                        </div>
                        <div
                          style={{ width: "35%" }}
                          className="form-req-tble-content d-flex justify-content-center text-center"
                        >
                          <div className="approved-btn">
                            <a href="">Approved</a>
                          </div>
                          <img
                            onClick={setthetimerplatemod}
                            src={IconWebModalSet}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <div className="payment-inner-page-set set-plate-resposnive">
            <div className="tabs-plate-rec-parent">
              <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    style={{ marginLeft: 0 }}
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Listing
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Auction
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="form-req-tble-parent">
                    <div className="form-req-tble-inner d-flex">
                      <div
                        style={{ width: "15%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Plate Number</h3>
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Seller Name</h3>
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Email</h3>
                      </div>
                      <div
                        style={{ width: "35%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Status</h3>
                      </div>
                    </div>
                    {listingData.plates
                      .slice()
                      .reverse()
                      .map((plate) => (
                        <div
                          className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3 set-para"
                          key={plate.id}
                        >
                          <div
                            style={{ width: "15%" }}
                            className="form-req-tble-content text-center"
                          >
                            <Link
                              to={`/Plates_recieved_details/${plate.id}`}
                              style={{
                                borderBottom: "2px solid #707070",
                                color: "#000",
                              }}
                            >
                              {plate.combination}
                            </Link>
                          </div>
                          <div
                            style={{ width: "30%" }}
                            className="form-req-tble-content text-center"
                          >
                            <h3>
                              {plate.user.firstName} {plate.user.lastName}
                            </h3>
                          </div>
                          <div
                            style={{ width: "20%" }}
                            className="form-req-tble-content text-center"
                          >
                            <h3>{plate.user.email}</h3>
                          </div>
                          <div
                            style={{ width: "35%" }}
                            className="form-req-tble-content d-flex justify-content-center text-center"
                          >
                            {console.log(plate.status,"-----plate.status")}
                            {plate.status === "PENDING" ? (
                              <>
                                <div className="approved-btn">
                                  <button
                                    onClick={() => handleApprove(plate.id)}
                                  >
                                    Approved
                                  </button>
                                </div>
                                <div className="approved-btn decline-btn ms-2">
                                  <button
                                    onClick={() => handleDisable(plate.id)}
                                    className="sett-the-decline-color"
                                  >
                                    Decline
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <p>{plate.status}</p>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="form-req-tble-parent">
                    <Modal
                      isOpen={isplatereceviedaproval}
                      onRequestClose={receviedplatemodal1}
                      style={customStyles}
                    >
                      <div className={`modal-form-request-parent`}>
                        <div className="modal-form-request-inner">
                          <div className="cancel-icn">
                            <Link to="/">
                              <img src={cancel} alt="" />
                            </Link>
                          </div>
                          <div className="row mt-4">
                            <div className="col-lg-8">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="modal-input-form-req py-2">
                                    <label htmlFor="modalinp1">
                                      First Name
                                      <input
                                        type="text"
                                        placeholder="Steve"
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="modal-input-form-req py-2">
                                    <label htmlFor="modalinp1">
                                      Last Name
                                      <input
                                        type="email"
                                        placeholder="Smith"
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="modal-input-form-req py-2">
                                    <label htmlFor="modalinp1">
                                      Email
                                      <input
                                        type="text"
                                        placeholder="smith_jhon12@outlook.com"
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="modal-input-form-req py-2">
                                    <label htmlFor="modalinp1">
                                      Phone
                                      <input
                                        type="text"
                                        placeholder="1234 567 890"
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="modal-input-form-req py-2">
                                    <label htmlFor="modalinp1">
                                      Plate Combination
                                      <input
                                        type="text"
                                        placeholder="XX - 3 "
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="modal-input-form-req py-2">
                                    <label htmlFor="modalinp1">
                                      Desired Plate Price
                                      <input
                                        type="text"
                                        placeholder="$4000.00"
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="modal-input-form-req py-2">
                                    <label htmlFor="modalinp1">
                                      Reserve Price
                                      <input
                                        type="text"
                                        placeholder="$3500.00"
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="modal-input-form-req py-2">
                                    <label
                                      className="d-flex flex-column"
                                      htmlFor="modalinp1"
                                    >
                                      Comment
                                      <textarea
                                        placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porttitor augue a leo imperdiet,
                                                                                    sed tincidunt lorem suscipit. Ut nibh mauris, rutrum et amet, rutrum sed massa."
                                        defaultValue={
                                          "                                            "
                                        }
                                        readOnly
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="two-img-parent d-flex flex-column">
                                <div className="img-plate-modal">
                                  <img src={license} alt="" />
                                </div>
                                <div className="img-plate-modal mt-3">
                                  <img src={modal} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="approv-dec-btn-parent-plate-modal d-flex justify-content-center mt-4 mb-3">
                                <div className="approv-btn-plate-modal me-4">
                                  <Link to="" onClick={settheapprovedplatemod}>
                                    Approved
                                  </Link>
                                </div>
                                <div className="dec-btn-plate-modal">
                                  <a href="" className="sett-the-decline-color">
                                    Decline
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      isOpen={isplatetimermod}
                      onRequestClose={setthetimerplatemod}
                      style={customStyles}
                    >
                      <div className="hg-box">
                        <div className="box-pages-parent bid-strt">
                          <div className="cancel-icn">
                            <a href="#">
                              <img src={cancel} onClick={handleCancel} alt="" />
                            </a>
                          </div>
                          <div className="date-time-set">
                            <h2>Set Time for Auction</h2>
                            <form onSubmit={addTime}>
                              <span>
                                <div className="FlexAuc">
                                  <span>Day</span>
                                  <input
                                    type="number"
                                    name="days"
                                    placeholder="DD"
                                    defaultValue={0}
                                    min={0}
                                    required
                                  />
                                </div>
                                <div className="FlexAuc">
                                  <span>Hr</span>
                                  <input
                                    type="number"
                                    name="hours"
                                    placeholder="HH"
                                    defaultValue={0}
                                    min={0}
                                    required
                                  />
                                </div>
                                <div className="FlexAuc">
                                  <span>Mins</span>
                                  <input
                                    type="number"
                                    name="minutes"
                                    placeholder="MM"
                                    defaultValue={0}
                                    min={0}
                                    required
                                  />
                                </div>
                                <div className="FlexAuc">
                                  <span>Sec</span>
                                  <input
                                    type="number"
                                    name="seconds"
                                    placeholder="SS"
                                    defaultValue={0}
                                    min={0}
                                    required
                                  />
                                </div>
                                <input
                                  name="id"
                                  type="hidden"
                                  value={idValue}
                                />
                              </span>
                              <button
                                type="submit"
                                className="approved-btn-2 cust-verified-btn mt-3"
                              >
                                Create Auction
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <div className="form-req-tble-inner d-flex">
                      <div
                        style={{ width: "15%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Plate Number</h3>
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Seller Name</h3>
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Email</h3>
                      </div>
                      <div
                        style={{ width: "35%" }}
                        className="form-req-tble-content text-center"
                      >
                        <h3>Status</h3>
                      </div>
                    </div>
                    {auctionData.plates
                      .slice()
                      .reverse()
                      .map((plate) => (
                        <div
                          className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3"
                          key={plate.id}
                        >
                          <div
                            style={{ width: "15%" }}
                            className="form-req-tble-content text-center"
                          >
                            <Link to={`/Plates_recieved_details/${plate.id}`}>
                              <h3
                                onClick={receviedplatemodal1}
                                style={{ borderBottom: "2px solid #707070" }}
                              >
                                {plate.combination}
                              </h3>
                            </Link>
                          </div>
                          <div
                            style={{ width: "30%" }}
                            className="form-req-tble-content text-center"
                          >
                            <h3>
                              {plate.user.firstName} {plate.user.lastName}
                            </h3>
                          </div>
                          <div
                            style={{ width: "20%" }}
                            className="form-req-tble-content text-center"
                          >
                            <h3>{plate.user.email}</h3>
                          </div>

                          <div
                            style={{ width: "35%" }}
                            className="form-req-tble-content d-flex justify-content-center text-center"
                          >
                            {plate.status === "PENDING" ? (
                              <>
                                <div className="approved-btn">
                                  <Link
                                    to=""
                                    onClick={() => handleApprove(plate.id)}
                                  >
                                    Approved
                                  </Link>
                                </div>
                                <div className="approved-btn decline-btn ms-2">
                                  <Link
                                    href=""
                                    className="sett-the-decline-color"
                                    onClick={() => handleDisable(plate.id)}
                                  >
                                    Decline
                                  </Link>
                                </div>
                              </>
                            ) : plate.status === "APPROVED" ? (
                              <>
                                <button
                                  className="approved-btn-2"
                                  onClick={() => setthetimerplatemod(plate.id)}
                                >
                                  Create Auction
                                </button>
                              </>
                            ) : ( 
                              <p>{plate.status}</p>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Plates_recieved;
