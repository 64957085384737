import star from "../../../assets/images/star.png";
import auctionCart from "../../../assets/images/auction-cart-logo.png";
import { useQuery, gql, ApolloClient, InMemoryCache } from "@apollo/client";
import Header from "../../../components/Header";
import { Link, useLocation } from "react-router-dom";
import AnimatedCursor from "../../../components/AnimatedCursor";
import Footer from "../../../components/Footer";
import { IpAddress, baseUrl } from "../../../utils/Function";
import { useSelector } from "react-redux";
import CountdownTimer from "../../../components/Counter";
import { useState, useEffect } from "react";

import {
  addWishList,
  decreament,
  increament,
  removeWishList,
} from "../../../redux/slices/wishlistSlice";
import { useDispatch } from "react-redux";

const client = new ApolloClient({
  uri: IpAddress,
  cache: new InMemoryCache(),
});

const AUCTION_PLATES = gql`
  query Plates {
    plates(where: { purpose: AUCTION, status: IN_AUCTION }) {
      combination
      id
      askingPrice
      numberPlate

      detail {
        ... on PlateAuction {
          currentBid
          auction {
            endAt
            id
            bids {
              id
              price
              createdAt
            }
          }
        }
      }
      status
      comments
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;

function Auction(props) {
  const userData = useSelector((state) => state.user.userData);
  const wish_list = useSelector((state) => state.wishlist);
  // const [showImage, setShowImage] = useState(false);
  const { list, counter } = useSelector((value) => value.wishlist);
  const { plate } = props;
  console.log(wish_list?.list, "-----------wish_list");
  // const { counter } = useSelector((value) => value.wishlist);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (list) {
  //     if (list.includes(plate)) {
  //       setShowImage(true);
  //     } else {
  //       setShowImage(false);
  //     }
  //   }
  // }, [list, counter]);

  const { loading, error, data } = useQuery(
    AUCTION_PLATES,
    {
      fetchPolicy: "network-only",
      client,
    },
    { refetchQueries: ["AUCTION_PLATES"] }
  );

  if (loading) {
    return (
      <>
        {" "}
        <header class="header-bg">
          <Header />
          <section class="top_banner">
            <div class="container">
              <div class="grid-row auction-grid">
                <div class="grid-col-2">
                  <div class="contentBox content leFadeIn">
                    <h1 style={{ color: "white" }}>Loading....</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>
        <section class="auction-cart-sec">
          <div class="container">
            <div class="auction-cart-grid"></div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
  if (error) {
    return <div>Error {error.message}...</div>;
  }

  // const removeWatchList = () => {
  //   dispatch(decreament());
  // };

  // const addWatchList = () => {
  //   dispatch(increament());
  // };

  // const removeWatchList = () => {
  //   // toggleImage()
  //   const tempArray =
  //     list && list.length > 0 ? list.filter((item) => item != plate) : [];
  //   dispatch(removeWishList(tempArray));
  //   console.log("wish", list);
  //   dispatch(decreament());
  // };

  // const addWatchList = () => {
  //   // toggleImage()
  //   const tempArray = list && list.length > 0 ? [...list, plate] : [plate];
  //   dispatch(addWishList(tempArray));
  //   console.log("wish", list);
  //   dispatch(increament());
  // };

  const ItemRender = ({ plate }) => {
    const [showImage, setShowImage] = useState(false);
    useEffect(() => {
      if (list) {
        if (list.includes(plate)) {
          setShowImage(true);
        } else {
          setShowImage(false);
        }
      }
    }, [list, counter]);
    const removeWatchList = () => {
      const tempArray = list.length > 0 ? list.filter((item) => item.id != plate.id) : [];
      dispatch(removeWishList(tempArray));
      dispatch(decreament());
    };

    const addWatchList = () => {
      // toggleImage()
      const tempArray = list && list.length > 0 ? [...list, plate] : [plate];
      dispatch(addWishList(tempArray));
      console.log("wish", list);
      dispatch(increament());
    };
    return (
      <div class="auction-cart-outer">
        <div class="auction-cart">
          <div class="star-icn-cart">{showImage && <Image />}</div>
          <div class="logo-ing-cart">
            <img src={auctionCart} alt="" />
          </div>
          <div class="cart-img">
            <img
              style={{ width: "240px", height: "140px" }}
              src={`${baseUrl}${plate?.numberPlate}`}
            />
          </div>
          <p>{plate.comments}</p>
          <div class="auc-card-main-txt">
            <div class="auc-card-txt">
              <h1>{plate.combination}</h1>
            </div>
            <div class="auc-card-txt-btn">
              <a href="#">
                $ {plate?.detail?.currentBid ?? plate?.askingPrice}
              </a>
              <span>
                <a href="#">Current Bid</a>
              </span>
              {console.log(plate.detail.currentBid, "tttttt")}
            </div>
          </div>
        </div>
        <div id="countdown">
          <CountdownTimer
            targetDate={new Date(plate.detail?.auction?.endAt).getTime()}
            plateid={plate.id}
          />
          <ul>
            <li class="timer" style={{ fontSize: "12px", color: "white" }}>
              <span id="days"></span>Day(S)
            </li>
            <li class="timer" style={{ fontSize: "12px", color: "white" }}>
              <span id="hours"></span>hour(S)
            </li>
            <li class="timer" style={{ fontSize: "12px", color: "white" }}>
              <span id="minutes"></span>MIN(S)
            </li>
            <li class="timer" style={{ fontSize: "12px", color: "white" }}>
              <span id="seconds"></span>Sec(s)
            </li>
          </ul>
        </div>
        <div class="bid-wish">
          {token && (
            <Link to={`/auctionProduct/${plate.id}`} class="btn btn-3">
              BID NOW
            </Link>
          )}
          {!token && (
            <Link to={`/AskingPriceTwo/${plate.id}`} className="btn btn-3">
              BID NOW
            </Link>
          )}
          {/* {showImage ? (
            <button className="toggle-image-button" onClick={removeWatchList}>
              Remove watchList
            </button>
          ) : (
            <button className="toggle-image-button" onClick={addWatchList}>
              Add to watchList
            </button>
          )} */}
          {/* const ret = distributors.find((x) => x.Count === 1); */}
          {console.log(wish_list?.list?.find((x) => x?.id === plate?.id),"====")}
          {wish_list?.list?.find((x) => x?.id === plate?.id) == undefined ? (
            <button className="toggle-image-button" onClick={addWatchList}>
              Add to watchList
            </button>
          ) : (
            <button className="toggle-image-button" onClick={removeWatchList}>
              Remove watchList
            </button>
          )}
        </div>
      </div>
    );
  };

  const token = userData.accessToken;

  return (
    <>
      <AnimatedCursor />
      <div class="main-scroll-bar"></div>
      <header class="header-bg">
        <Header />
        <section class="top_banner">
          <div class="container">
            <div class="grid-row auction-grid">
              <div class="grid-col-2">
                <div class="contentBox content leFadeIn">
                  <h1
                    class="leFadeIn"
                    data-aos="fade-right"
                    data-aos-duration="1700"
                  >
                    Auction
                  </h1>
                  <p data-aos="fade-right" data-aos-duration="1800">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    convallis rhoncus eros, nec scelerisque nisi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
      <section class="auction-cart-sec">
        <div class="container">
          <div class="auction-cart-grid">
            {data &&
              data.plates.map((plate) => (
                <ItemRender plate={plate} />
                // <div class="auction-cart-outer">
                //   <div class="auction-cart">
                //     <div class="star-icn-cart">{showImage && <Image />}</div>
                //     <div class="logo-ing-cart">
                //       <img src={auctionCart} alt="" />
                //     </div>
                //     <div class="cart-img">
                //       <img
                //         style={{ width: "240px", height: "140px" }}
                //         src={`${baseUrl}${plate?.numberPlate}`}
                //       />
                //     </div>
                //     <p>{plate.comments}</p>
                //     <div class="auc-card-main-txt">
                //       <div class="auc-card-txt">
                //         <h1>{plate.combination}</h1>
                //       </div>
                //       <div class="auc-card-txt-btn">
                //         <a href="#">
                //           $ {plate?.detail?.currentBid ?? plate?.askingPrice}
                //         </a>
                //         <span>
                //           <a href="#">Current Bid</a>
                //         </span>
                //         {console.log(plate.detail.currentBid, "tttttt")}
                //       </div>
                //     </div>
                //   </div>
                //   <div id="countdown">
                //     <CountdownTimer
                //       targetDate={new Date(
                //         plate.detail?.auction?.endAt
                //       ).getTime()}
                //       plateid={plate.id}
                //     />
                //     <ul>
                //       <li
                //         class="timer"
                //         style={{ fontSize: "12px", color: "white" }}
                //       >
                //         <span id="days"></span>Day(S)
                //       </li>
                //       <li
                //         class="timer"
                //         style={{ fontSize: "12px", color: "white" }}
                //       >
                //         <span id="hours"></span>hour(S)
                //       </li>
                //       <li
                //         class="timer"
                //         style={{ fontSize: "12px", color: "white" }}
                //       >
                //         <span id="minutes"></span>MIN(S)
                //       </li>
                //       <li
                //         class="timer"
                //         style={{ fontSize: "12px", color: "white" }}
                //       >
                //         <span id="seconds"></span>Sec(s)
                //       </li>
                //     </ul>
                //   </div>
                //   <div class="bid-wish">
                //     {token && (
                //       <Link
                //         to={`/auctionProduct/${plate.id}`}
                //         class="btn btn-3"
                //       >
                //         BID NOW
                //       </Link>
                //     )}
                //     {!token && (
                //       <Link
                //         to={`/AskingPriceTwo/${plate.id}`}
                //         className="btn btn-3"
                //       >
                //         BID NOW
                //       </Link>
                //     )}
                //     {showImage ? (
                //       <button
                //         className="toggle-image-button"
                //         onClick={removeWatchList}
                //       >
                //         Remove watchList
                //       </button>
                //     ) : (
                //       <button
                //         className="toggle-image-button"
                //         onClick={addWatchList}
                //       >
                //         Add to watchList
                //       </button>
                //     )}
                //   </div>
                // </div>
              ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
const Image = () => {
  return (
    <div className="star-icn-cart">
      <img src={star} alt="Star Icon" />
    </div>
  );
};
export default Auction;
