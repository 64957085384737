import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import WebStarIcn from '../../../assets/images/star.png';
import { addWishList, decreament, increament, removeWishList } from '../../../redux/slices/wishlistSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

function PlateItem(props) {
  const { list, counter } = useSelector(value => value.wishlist);

  const [showImage, setShowImage] = useState(false)
  const { plate, baseUrl, token } = props;
  const dispatch = useDispatch()


  useEffect(() => {
    if (list) {
      if (list.includes(plate)) {
        setShowImage(true);
      } else {
        setShowImage(false);
      }
    }
  }, [list, counter])


  const removeWatchList = () => {
    // toggleImage()
    const tempArray = list && list.length > 0 ? list.filter(item => item != plate) : []
    dispatch(removeWishList(tempArray))
    console.log("wish", list);
    dispatch(decreament())
  }

  const addWatchList = () => {
    // toggleImage()
    const tempArray = list && list.length > 0 ? [...list, plate] : [plate]
    dispatch(addWishList(tempArray))
    console.log("wish", list);
    dispatch(increament())
  }

  if (!plate || !plate.detail || plate.detail.__typename === null) {
    return null; // or any appropriate fallback UI or error handling
  }
  let setPrice = plate?.detail?.settlePrice
  // console.log(setPrice, "ooooo")
  return (
    <div key={plate.id} className="card-parent-buy">
      <div className="card-inner-buy">
        <div className="card-buy-inner-parent">
          <div className="card-content-buy-top">
            <div className="buy-card-plate">
              <img src={`${baseUrl}${plate?.numberPlate}`} alt="" />
              {showImage && <Image />}
            </div>
          </div>
          <div className="gridBuy-text">
            <span>{plate.combination}</span>
          </div>

          {

          }
          {plate.detail.__typename === 'PlateListing' && setPrice === 0 ? (
            <div className="auction-price">
              <small className="bg-green-bg">Offer considered</small>
            </div>
          ) : (
            <div className="auction-price">

              <small><span style={{ color: "#393535", fontWeight: "700" }}>Asking price </span>${plate?.askingPrice}</small>
            </div>
          )}




          <div className="buy-btnBox">
            {token && (
              <Link
                to={`/OfferConsider/${plate.id}`}
                className="login-btn actionbtn-pg-5 btn btn-3 offer-btn"
              >
                Make an offer

              </Link>
            )}
            {!token && (
              <Link
                to={`/OfferConsideredTwo/${plate.id}`}
                className="login-btn actionbtn-pg-5 btn btn-3 offer-btn"
              >
                Make an offer
              </Link>
            )}

            {
              showImage ?
                <button className="toggle-image-button" onClick={removeWatchList}>
                  Remove watchList
                </button>
                :
                <button className="toggle-image-button" onClick={addWatchList}>
                  Add to watchList
                </button>
            }
            {/* <button className="toggle-image-button" onClick={this.toggleImage}>
                {showImage ? 'Remove watchList' : 'Add to watchList'}
              </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const Image = () => {
  return (
    <div className="star-icn-cart">
      <img src={WebStarIcn} alt="Star Icon" />
    </div>
  );
};


export default PlateItem


