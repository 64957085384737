import { Routes, Route, Outlet, Link } from "react-router-dom";
import WebHomePlate1 from '../../../assets/images/1.png'
import WebHomePlate2 from '../../../assets/images/2.png'
import Header from "../../../components/Header";
import AnimatedCursor from "../../../components/AnimatedCursor";
import VideoBgHome from '../../../assets/images/Concept1.mp4'
import VideoBgBannerHome from '../../../assets/images/banner.mp4'
function index() {
    return (
        // header-bg
        <>
            <AnimatedCursor />
            <header className="home-page">
                <div class="videoBtns">
                    <video id="background-video" autoPlay muted loop poster={VideoBgHome}>
                        <source src={VideoBgHome} type="video/mp4"/>
                    </video>
                    <video id="video2" autoPlay muted loop poster={VideoBgBannerHome} style={{zIndex: "-1"}}>
                        <source src={VideoBgBannerHome}  type="video/mp4"/>
                    </video>
                </div>
                <Header />
                <section className="top_banner">
                    <div className="container">
                        <div className="grid-row">
                            <div className="grid-col-2">
                                <div
                                    className="contentBox underlined_waved"
                                >
                                    <h1>Signatures +</h1>
                                    <p>
                                        Victoria's Boutique Signature  <br />
                                        Market Place
                                    </p>
                                </div>
                                <div
                                    className="buttonBox"
                                >
                                    <Link to="/AuctionSig" className="actionbtn-pg-5  btn btn-3">
                                        Auction
                                    </Link>
                                    <Link to="/Sell" className="actionbtn-pg-5  btn btn-3">
                                        Sell
                                    </Link>
                                </div>
                            </div>
                            <div className="grid-col-2">
                                <ul className="platesBox effect5 effectone">
                                    <li className="imgone">
                                        <Link to="/" className="animation5" data-tilt="">
                                            <img src={WebHomePlate1} alt="" />
                                        </Link>
                                    </li>
                                    <li className="imgtwo">
                                        <Link to="/" className="animation5" data-tilt="">
                                            <img src={WebHomePlate2} alt="" />
                                        </Link>
                                    </li>
                                    <li className="imgthree">
                                        <Link tp="/" className="animation5" data-tilt="">
                                            <img src={WebHomePlate1} alt="" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        </>
    )
}

export default index