import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { Link } from "react-router-dom";
import PaymentPDFIcn from "../../../assets/images/pdf-icn.png";
import PaymentExcelIcn from "../../../assets/images/excel-icn.png";
import ResponsiveNavBarDasbord from "../../../components/ResponsiveNavBarDasbord";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { baseUrl, baseUrl2 } from "../../../utils/Function";

const GET_PLATES = gql`
  query {
    plates(where: { status: SOLD }) {
      id
      combination
      user {
        username
      }
      detail {
        ... on PlateListing {
          buyer {
            username
          }
          currentOffer
        }
        ... on PlateAuction {
          currentBid
          auction {
            winner {
              username
            }
          }
        }
      }
    }
  }
`;

const GET_PAYMENT = gql`
  query ($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      combination
      payments {
        user {
          username
        }
        createdAt
        invoiceNumber
        proofUri
      }
    }
  }
`;

function formatDateTime(dateTime) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(dateTime).toLocaleString("en-US", options);
}

function Payment() {
  const [addingpaymenttwo, setAddingPaymentTwo] = useState("");
  const { userID } = useSelector((value) => value?.user?.userData);

  const { loading, error, data } = useQuery(GET_PLATES);

  const [getPayment, { loading: loading2, error: error2, data: data2 }] =
    useLazyQuery(GET_PAYMENT);

  console.log();
  if (loading || loading2)
    return (
      <div className="bg-dashboard">
        <main id="main">
          <ResponsiveNavBarDasbord />
          <Sidebar />
          <div className="dashboard-right-side">
            <div className="form-req-tble-parent">
              <div className="payment-inner-page-set">
                <div
                  style={{ backgroundColor: "#FFFBF0" }}
                  className="form-req-tble-inner d-flex"
                >
                  <h1 style={{ color: "#6D6D6D" }}>Loading....</h1>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );

  if (error) return <p>Error: {error.message}</p>;

  const plate = data?.plates || {};
  if (error2) return <p>Error: {error2.message}</p>;

  return (
    <div class="bg-dashboard">
      <main id="main">
        <ResponsiveNavBarDasbord />
        <Sidebar />
        <div className="dashboard-right-side payment-parent">
          <div className="page-title">
            <h1>PAYMENT</h1>
          </div>
          {addingpaymenttwo === "" ? (
            <div className="box-pages-parent">
              <div className="payment-table-parent">
                <div className="payment-table-inner-parent d-flex">
                  <div style={{ width: "6%" }} className="payment-table-inner">
                    <h4>
                      <input type="checkbox" />
                    </h4>
                  </div>
                  {/* <div style={{ width: "10%" }} className="payment-table-inner">
                    <h4>Invoice #</h4>
                  </div> */}
                  <div style={{ width: "15%" }} className="payment-table-inner">
                    <h4>Plate Number</h4>
                  </div>
                  <div style={{ width: "10%" }} className="payment-table-inner">
                    <h4>Buyer</h4>
                  </div>
                  <div style={{ width: "10%" }} className="payment-table-inner">
                    <h4>Seller</h4>
                  </div>
                  <div style={{ width: "10%" }} className="payment-table-inner">
                    <h4>Amount</h4>
                  </div>
                  {/* 
                    <div style={{ width: "10%" }} className="payment-table-inner">
                      <h4>Date</h4>
                    </div> */}
                  <div style={{ width: "20%" }} className="payment-table-inner">
                    <h4 />
                  </div>
                </div>

                {plate.map((plate) => (
                  <div
                    key={plate.id}
                    className="payment-table-inner-parent payment-table-inner-parent2 d-flex mt-3"
                  >
                    <div
                      style={{ width: "6%" }}
                      className="payment-table-inner"
                    >
                      <h4>
                        <input type="checkbox" />
                      </h4>
                    </div>
                    {/* <div
                      style={{ width: "10%" }}
                      className="payment-table-inner"
                    >
                      <h4>{plate?.combination}</h4>
                    </div> */}
                    <div
                      style={{ width: "13%" }}
                      className="payment-table-inner"
                    >
                      <h4>{plate.combination}</h4>
                    </div>
                    <div
                      style={{ width: "13%" }}
                      className="payment-table-inner"
                    >
                      <h4>
                        {plate.detail?.buyer?.username ||
                          plate.detail?.auction?.winner?.username}
                      </h4>
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="payment-table-inner"
                    >
                      <h4>{plate.user?.username}</h4>
                    </div>
                    <div
                      style={{ width: "10%" }}
                      className="payment-table-inner"
                    >
                      <h4>
                        $
                        {plate.detail?.currentOffer || plate.detail?.currentBid}
                      </h4>
                    </div>
                    {/* <div
                      style={{ width: "15%" }}
                      className="payment-table-inner"
                    >
                      <h4>{}</h4>
                    </div> */}
                    <div
                      style={{ width: "20%" }}
                      className="payment-table-inner payment-table-inner-anchor text-center"
                    >
                      <Link
                        to=""
                        onClick={() => {
                          getPayment({
                            variables: { id: plate?.id },
                          });
                          setAddingPaymentTwo("box-pages-parent");
                        }}
                      >
                        Details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : addingpaymenttwo === "box-pages-parent" ? (
            <div className="payment-inner-page-set">
              <div className="header-payment-2 d-flex">
                <div className="payment-header-left"></div>
                <div className="payment-header-left">
                  <h2>{data2?.plate?.combination}</h2>
                </div>
              </div>

              <div className="header-payment-2 d-flex mt-4">
                <div className="payment-header-left"></div>
              </div>
              <div className="payment2-table-parent">
                <div className="payment2-table-inner-parent d-flex mt-3">
                  <div
                    style={{ width: "10%" }}
                    className="payment2-table-inner"
                  >
                    <h4>
                      <input type="checkbox" />
                    </h4>
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="payment2-table-inner"
                  >
                    <h4>Invoice #</h4>
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="payment2-table-inner"
                  >
                    <h4>Buyer</h4>
                  </div>

                  <div
                    style={{ width: "20%" }}
                    className="payment2-table-inner"
                  >
                    <h4>Date</h4>
                  </div>
                  <div
                    style={{ width: "30%" }}
                    className="payment2-table-inner"
                  >
                    <h4>
                      Download Reciept{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                      </svg>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="payment2-table-parent mb-5">
                {data2?.plate?.payments.map((payment) => (
                  <div className="payment2-table-inner-parent payment2-table-inner-parent2 d-flex mt-3">
                    <>
                      <div
                        style={{ width: "10%" }}
                        className="payment2-table-inner"
                      ></div>
                      <div
                        style={{ width: "20%" }}
                        className="payment2-table-inner"
                      >
                        <h4>{payment.invoiceNumber}</h4>
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="payment2-table-inner"
                      >
                        <h4>{payment.user?.username}</h4>
                      </div>

                      <div
                        style={{ width: "20%" }}
                        className="payment2-table-inner"
                      >
                        <h4>{payment.createdAt}</h4>
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="payment2-table-inner"
                      >
                        <div className="downl-rep-tble-icn d-flex justify-content-center">
                          <div className="downl-rep-tble-icn-inner ms-2 me-3">
                          
                              <a
                              target="_blank"
                                href={`${baseUrl}${payment?.proofUri}`}
                                download
                              >
                                <img
                                  src={PaymentPDFIcn}
                                  alt="Download Payment Proof"
                                />
                              </a>
                         
                            
                          </div>
                          <div className="downl-rep-tble-icn-inner">
                          
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </main>
    </div>
  );
}

export default Payment;
