import React from 'react';
import { useQuery,gql } from '@apollo/client';
import { useParams } from 'react-router-dom';


const GET_AUCTION_PRODUCT = gql`
  query GetAuctionProduct($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      id
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            id
            endAt
            bids {
              id
              price
              createdAt
              user {
                firstName
                lastName
                id
                username
            }
            }
          }
        }
        ... on PlateListing {
          id
        }
      }
      status
      comments
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;


function Allbids() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_AUCTION_PRODUCT, {
    variables: { id: parseInt(id) },
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  

  const bids = data?.plate?.detail?.auction?.bids || [];
  const sortedBids = [...bids].sort((a, b) => b.price - a.price);

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    return new Date(dateTimeString).toLocaleString('en-US', options);
  };

  return (
    <section className="table-section-bid">
      <div className="container">
        <div className="label-top-tble">
          <h1>TOTAL BIDS PLACED</h1>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "30%", height: "55px" }}>Bidder Name</th>
              <th style={{ width: "30%", height: "55px" }}>Bidder Time</th>
              <th style={{ width: "30%", height: "55px" }}>Bid</th>
              <th style={{ height: "55px" }}>Auto</th>
            </tr>
          </thead>
          <tbody>
          {sortedBids.map((bid) => (
              <tr key={bid.id}>
                <td>{bid.user?.username}</td>
                <td>{formatDateTime(bid.createdAt)}</td>
                <td>{bid.price}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default Allbids;
