import React from 'react'
import Sidebar from '../../../components/Sidebar'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom'
import { IpAddress } from '../../../utils/Function';
import Swal from 'sweetalert2';
import { baseUrl } from "../../../utils/Function";
import ResponsiveNavBarDasbord from '../../../components/ResponsiveNavBarDasbord';



const client = new ApolloClient({
  uri: IpAddress,
  cache: new InMemoryCache(),
});

const QUERY_USER = gql`
query User($id: Int!) {
    user(where: { id: $id }) {
      city
      companyName
      country
      drivingLicense
      email
      firstName
      id
      lastName
      phoneNumber
      postCode
      status
      streetAddress
      username
      state
    }
  }
  
`;

const REMOVE_USER_MUTATION = gql`
mutation RemoveUser($id: Int!){
  removeUser(where: { id: $id }) {
     id
  }
}
`;

const APPROVE_USER_MUTATION = gql`
mutation ApproveUser($id: Int!){
  approveUser(where:{id: $id}){
    id
    }
  }
`;


function FormDetails() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(QUERY_USER, {
    variables: { id: parseInt(id) },
  });
  const Swal = require('sweetalert2')




  //new code


  const [removeUser] = useMutation(REMOVE_USER_MUTATION, {
    refetchQueries: ['GET_USERS_QUERY'],
  });


  const navigate = useNavigate();

  const handleDisable = (id) => {
    console.log(id)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.isConfirmed) {
        removeUser({ variables: { id: parseInt(id) } })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })


      .catch((error) => {
        // Handle error
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
        })
      });

    navigate("/FormRequest");

  };

  //delete end


  //approve
  const handleApprove = (id) => {
    approveUser({ variables: { id: parseInt(id) } })
      .then(() => {
        // Mutation completed successfully
        Swal.fire(
          'Good job!',
          'You clicked the button!',
          'success'
        )
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
        })
      });

    navigate("/FormRequest");
  };

  const [approveUser] = useMutation(APPROVE_USER_MUTATION, {
    client: client,
    update: (cache, { data: { approveUser } }) => {
      // Perform any necessary cache updates after mutation
    },
  });
  //approve end
  const handleDisable2 = () => {
    navigate("/FormRequest");
  }
  //end




  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const { user } = data;

  // Set the values from the query to the respective inputs and make them readonly


  return (
    <div class="bg-dashboard">
      <main id="main">
        <ResponsiveNavBarDasbord />
        <Sidebar />

        <div className="dashboard-right-side">
          <div className="page-title">
            <h1>Forms Request</h1>
          </div>
          <div className="modal-form-request-parent">
            <div className="modal-form-request-inner">
              <div className="modal-form-heading">
                <h1>User Details</h1>
              </div>
              <div className="cancel-icn">
                <a href="form-request.php">
                  <img src="assets/images/cancel-icn.png" alt="" />
                </a>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="modal-input-form-req py-2">
                    <label htmlFor="modalinp1">
                      Username
                      <input type="text" placeholder="Steve123" value={user.username} readOnly />

            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Email
              <input type="email" placeholder="smith.steve@outlook.com" value={user.email} />
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              First Name
              <input type="text" placeholder="Steve" value={user.firstName}/>
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Last Name
              <input type="email" placeholder="Smith" value={user.lastName}/>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Company Name (Optional)
              <input type="text" placeholder="Lorem Ipsum" value={user.companyName}/>
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              country
              <input type="email" placeholder="Australia" value={user.country}/>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Street Address
              <input type="text" placeholder="Lorem ipsum dolor sit" value={user.streetAddress}/>
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Town / City
              <input type="email" placeholder="Adelaide"  value={user.city}/>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              State
              <input type="text" placeholder="Lorem ipsum" value={user.state}/>
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Postcode / Zipcode
              <input type="email" placeholder={12345} value={user.postCode}/>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Phone Number
              <input type="text" placeholder="123 456 7890" value={user.phoneNumber}/>
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="driv-lice-img-modal d-flex justify-content-center mt-4">
            <img src={`${baseUrl}${user?.drivingLicense}`} alt="" />
          </div>
          
        </div>
      </div>
      <div className="row">
      <div className="BackButton">
            <button onClick={handleDisable2}>Back</button>
            </div>
        {/* <div className="col-lg-6">
          <div className="btn-form-req-modal-parent d-flex align-items-end justify-content-start">
            <div className="btn-form-req-modal me-3 approved-btn a">
            <button onClick={() => handleApprove(user.id)}>Approve</button>
            </div>
            <div className="btn-form-req-modal approved-btn-decline ">
            <button onClick={() => handleDisable(user.id)}>Decline</button>
            </div>
          </div>
        </div> */}
              </div>
            </div>
          </div>
        </div>

      </main>
    </div>

  )
}

export default FormDetails