import React, { useState, useEffect } from "react";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";
import { baseUrl } from "../utils/Function";
import { redirect, useNavigate } from "react-router-dom";

const client = new ApolloClient({
  uri: baseUrl, // Replace with your GraphQL API endpoint
  cache: new InMemoryCache(),
});

const UPDATE_PLATE = gql`
  mutation UpdatePlate($id: Int!, $status: PlateStatus!) {
    updatePlate(where: { id: $id }, data: { status: $status }) {
      id
    }
  }
`;

const CountdownTimer = ({ targetDate, plateid, redirectTo }) => {
  const [updatePlate] = useMutation(UPDATE_PLATE);
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const target = new Date(targetDate).getTime();
    const timeDiff = target - now;
    return Math.max(0, Math.floor(timeDiff / 1000));
  };

  const navigate = useNavigate();

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const [actionPerformed, setActionPerformed] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeRemaining = calculateTimeRemaining();
      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining === 0 && !actionPerformed) {
        updatePlate({ variables: { id: plateid, status: "SOLD" } })
          .then((result) => {
            console.log(result);
            const zero = 0;
          })
          .catch((error) => {
            console.error(error);
          });
        console.log("timerr", redirectTo);
        if (redirectTo) navigate(redirectTo);
        setActionPerformed(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [actionPerformed]);

  const getFormattedTime = (time) => {
    const days = Math.floor(time / (24 * 60 * 60));
    const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = time % 60;

    return `${days.toString().padStart(2, "0")} ${hours
      .toString()
      .padStart(2, "0")} ${minutes.toString().padStart(2, "0")} ${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      className="timer"
      style={{
        fontSize: "17px",
        color: "#ffd100",
        padding: "0px 10px",
        wordSpacing: "25px",
      }}
    >
      {getFormattedTime(timeRemaining)}
    </div>
  );
};

export default CountdownTimer;
