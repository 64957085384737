import React, { useState } from 'react'
import AskingPriceTwoSearchLogo from '../../../assets/images/search-logo.png'
import AskingPriceTwoLogo from '../../../assets/images/logo.png'
import AskingPriceTwoXX3Plate from '../../../assets/images/XX-3-plate.png'
import WebAskingPriceTwoBtmCardOne from '../../../assets/images/btm-card-one.png'
import WebAskingPriceTwoBtmCardtwo from '../../../assets/images/btm-card-two.png'
import { useMutation, gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom'
import AnimatedCursor from '../../../components/AnimatedCursor'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import { baseUrl } from '../../../utils/Function';
import AskingPriceOne from '../Asking-Price-One/AskingPriceOne'



const GET_AUCTION_PRODUCT = gql`
query GetAuctionProduct($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      numberPlate
      id
      detail {
        ... on PlateAuction {
            currentBid
          auction {
            id
            endAt
            bids {
              id
              price
              createdAt
              user {
                firstName
                lastName
                id
                username
            }
            }
          }
        }
        ... on PlateListing {
          id
        }
      }
      status
      comments
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;



function AskingPriceTwo() {
    
    const { id } = useParams();


    const { loading, error, data } = useQuery(GET_AUCTION_PRODUCT, {
      variables: { id: parseInt(id) },
    });
  
    if (loading) {
        return (
            <>      <header class="header-bg">
            <Header />
            <section class="top_banner">
              <div class="container">
                <div class="grid-row auction-grid">
                  <div class="grid-col-2">
                    <div class="contentBox content leFadeIn">
    
                    <h1 style={{color: "white"}}>Loading....</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>
             <section class="auction-cart-sec">
                <div class="container">
                  <div class="auction-cart-grid">
                </div>
                </div>
                </section>
                <Footer />
                </>
    
        );
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }
  
    const plate = data && data.plate;
    const { combination, comments, detail , numberPlate} = plate;
    const { auction } = detail;
    
   
    return (
        <>
        <AnimatedCursor/>
            <div className="main-scroll-bar">
                <header className="header-bg">
                    <Header/>
                    <section className="top_banner">
                        <div className="Web-Container">
                            <div className="grid-row contactpage">
                                <div className="grid-col-2">
                                    <div className="contentBox">
                                        <h1>{combination}</h1>
                                        <p>
                                            {comments}
                                        </p>
                                    </div>
                                </div>
                                <div className="grid-col-2"></div>
                            </div>
                        </div>
                    </section>
                </header>
                <div className="main_contact-page">
                    <section className="bid-main-sec">
                        <div className="Web-Container">
                            <div className="bid-parent">
                                <div className="bid-grid-main">
                                    <div className="bid-inner-grid-content">
                                        <div className="prent-left-grid">
                                            <div className="main-left-bid-grid">
                                                <div className="serch-icn-cont">
                                                    <img src={AskingPriceTwoSearchLogo} alt="" />
                                                </div>
                                                <div className="logo-inner-card">
                                                    <img src={AskingPriceTwoLogo} alt="" />
                                                </div>
                                                <div className="plate-bid-card">
                                                    <img src={`${baseUrl}${plate?.numberPlate}`} alt="" />
                                                    {/* <img src={`${baseUrl}${plate?.numberPlate}`} alt="" /> */}

                                                </div>
                                                <div className="text-bid-card">
                                                    <p style={{color:"white",padding:"20px"}}>
                                                        {comments}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="btm-card-img">
                                                <div className="btm-card-inner">
                                                    <div className="btm-img">
                                                        <img src={WebAskingPriceTwoBtmCardOne} alt="" />
                                                    </div>
                                                    <div className="btm-img">
                                                        <img src={WebAskingPriceTwoBtmCardtwo} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bid-inner-grid-content">
                                        <div className="top-txt-rightbid">
                                            <h2 className="fw-400">{combination}</h2>
                                            <h4 className="fw-400 color-fff pd-h4-custom">
                                                Current Bid :  ${plate?.detail?.currentBid ?? plate?.askingPrice}
                                            </h4>
                                        </div>
                                        <div className="btm-txt-right-col">
                                            <h2 className="para-askprice-one">
                                                you need to be logged in with a registered account to be able
                                                to Bid, buy now or make offers on our platform
                                            </h2>
                                        </div>
                                        <div className="anchor-ask-price-parent">
                                            <div className="mg-left-zero anchor-ask-price-inner">
                                                <Link to="/Register">Register</Link>
                                            </div>
                                            <div className="anchor-ask-price-inner">
                                                <h3 className="color-fff">OR</h3>
                                            </div>
                                            <div className="anchor-ask-price-inner">
                                                <Link to="/Login">Login</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>

        </>
    )
}
export default AskingPriceTwo