import React from 'react'
import Sidebar from '../../../components/Sidebar'
import cancel from '../../../assets/images/cancel-icn.png'
import license from '../../../assets/images/license-img.png'
import modal from '../../../assets/images/modal-plate.png'

function Plates_recieved_modal() {
  return (


    <div class="bg-dashboard">
      <main id="main">
        <Sidebar />
        <div className="dashboard-right-side">
            <div className="page-title">
              <h1>PLATES RECEIVED</h1>
            </div>
            <div className="modal-form-request-parent">
              <div className="modal-form-request-inner">
                <div className="cancel-icn">
                  <a href="plates-received.php">
                    <img src={cancel} alt="" />
                  </a>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            First Name
                            <input type="text" placeholder="Steve" />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Last Name
                            <input type="email" placeholder="Smith" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Email
                            <input type="text" placeholder="smith_jhon12@outlook.com" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Phone
                            <input type="text" placeholder="1234 567 890" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Plate Combination
                            <input type="text" placeholder="XX - 3 " />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Desired Plate Price
                            <input type="text" placeholder="$4000.00" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label htmlFor="modalinp1">
                            Reserve Price
                            <input type="text" placeholder="$3500.00" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="modal-input-form-req py-2">
                          <label className="d-flex flex-column" htmlFor="modalinp1">
                            Comment
                            <textarea
                              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porttitor augue a leo imperdiet,
                                          sed tincidunt lorem suscipit. Ut nibh mauris, rutrum et amet, rutrum sed massa."
                              defaultValue={
                                "                                            "
                              }
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="two-img-parent d-flex flex-column">
                      <div className="img-plate-modal">
                        <img src={license} alt="" />
                      </div>
                      <div className="img-plate-modal mt-3">
                        <img src={modal} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="approv-dec-btn-parent-plate-modal d-flex justify-content-center mt-4 mb-3">
                      <div className="approv-btn-plate-modal me-4">
                        <a href="plate-rec-modal-one.php">Approved</a>
                      </div>
                      <div className="dec-btn-plate-modal">
                        <a href="decline-plates-received.php">Decline</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </main>
    </div>
  )
}

export default Plates_recieved_modal