import React from 'react'
import pagenation from '../../../assets/images/circ-left-chevron.png'
import pagenation2 from '../../../assets/images/go-back-link.png'
import pagenation3 from '../../../assets/images/login-box-logo.png'






function Verify_account() {
  return (
<section className="login-parent-serious">
  <div className="container">
    <div className="login-box-parent">
      <div className="loginn-box d-flex flex-column justify-content-center">
        <div className="loginn-box-inner-parent">
          <div className="logo-login text-center">
            <img src={pagenation3} alt="" />
          </div>
          <div className="login-heading text-center">
            <h1>Verify your account</h1>
          </div>
          <div className="login-heading text-center">
            <h2>We will send a code to your Email</h2>
          </div>
          <div className="row mt-3 mb-4">
            <div className="col-lg-12 mt-3 mb-4">
              <div className="input-login my-3">
                <label className="py-3" htmlFor="login-1">
                  Enter Email ID
                  <input type="email" />
                </label>
              </div>
            </div>
          </div>
          <div className="submit-link-2-parent d-flex justify-content-center mb-5">
            <div className="submit-link-2 d-flex justify-content-center my-3">
              <a href="verify-code.php">Submit</a>
            </div>
            <div className="left-sub-back-link">
              <a href="index.php">
                {" "}
                <img src={pagenation} alt="" />
              </a>
            </div>
          </div>
          <div className="goback-link text-center my-2">
            <a href="">
              <img src={pagenation2} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Verify_account