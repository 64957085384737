import React, { useRef, useState } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import Header from "../../../components/Header";
import AnimatedCursor from "../../../components/AnimatedCursor";
import RegisterGroup1 from "../../../assets/images/_Group_.png";
import axios from "axios";
import { userRegister } from "../../../utils/Function";
import FormData from "form-data";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const COUNTRIES = gql`
  query {
    countries {
      code
      name
    }
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "40%",
  },
};

const Register = () => {
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    companyName: "",
    countryCode: "",
    streetAddress: "",
    cityCode: "",
    state: "",
    postCode: "",
    phoneNumber: "",
    drivingLicense: null,
  });

  const navigate = useNavigate();

  // ----------------------form validation part ---------------------
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!selectedFileName) {
      toast.error("No File Chosen.");
      return;
    }

    // Username validation: alphanumeric, 4-16 characters
    const usernameRegex = /^[a-zA-Z0-9]{2,16}$/;
    if (!usernameRegex.test(formData.username)) {
      newErrors.username =
        "Username must be alphanumeric and 4-16 characters or already taken";
      isValid = false;
      usernameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (formData.username.trim() === "") {
      newErrors.username = "Username is required";
      isValid = false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log("VALID EMAIL", emailRegex.test(formData.email));
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email or email already exist";
      isValid = false;
      usernameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
      isValid = false;
    }

    const weakpassword = /^[a-zA-Z0-9]{2,16}$/;
    // const weakpassword =/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{3,}$/;
    console.log("VALID PASSWORD", weakpassword.test(formData.password));
    if (formData.password.trim() === "") {
      newErrors.password = "Password is required";
      isValid = false;
      usernameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    // } else if (!weakpassword.test(formData.password)) {
    //   newErrors.password =
    //     "Must be at least 8 characters long. Should include a combination of uppercase and lowercase letters.Should contain at least one numeric digit (0-9).";
    //   isValid = false;
    //   usernameRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //   });
    }
    // First name validation: letters only
    const firstNameRegex = /^[A-Za-z]+$/;
    if (!firstNameRegex.test(formData.firstName)) {
      newErrors.firstName = "First name must contain letters only";
      isValid = false;
      usernameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (formData.firstName.trim() === "") {
      newErrors.firstName = "First name is required";
      isValid = false;
    }

    // Last name validation: letters only
    const lastNameRegex = /^[A-Za-z]+$/;
    if (!lastNameRegex.test(formData.lastName)) {
      newErrors.lastName = "Last name must contain letters only";
      isValid = false;
      usernameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (formData.lastName.trim() === "") {
      newErrors.lastName = "Last name is required";
      isValid = false;
    }

    // Company name validation: alphanumeric with spaces
    if (formData.companyName.trim() === "") {
      newErrors.companyName = "Company name is required";
      isValid = false;
    }

    if (formData.streetAddress.trim() === "") {
      newErrors.streetAddress = "Street address is required";
      usernameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      isValid = false;
    }

    if (formData.state.trim() === "") {
      newErrors.state = "State is required";
      isValid = false;
    }

    const phoneNumberRegex = /^\+614\d{2}\d{3}\d{3}$/;
    if (!phoneNumberRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber =
        "Phone Number is not valid Or number is already registered";
      isValid = false;
  } else if (formData.firstName.trim() === "") {
      newErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  // // ------------end form validation--------------------

  const formRef = useRef(null);
  const usernameRef = useRef(null); // Add this line
  const emailRef = useRef(null); //
  const phoneRef = useRef(null); //

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        drivingLicense: file,
      }));
      setSelectedFileName(file.name);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    console.log("Handle SUBMIT");
    if (validateForm()) {
      // Perform form submission logic
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      if (errors.username) {
        usernameRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else if (errors.email) {
        emailRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (errors.phoneNumber) {
        phoneRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      const data = new FormData();
      data.append("username", formData.username);
      data.append("email", formData.email);
      data.append("password", formData.password);
      data.append("firstName", formData.firstName);
      data.append("lastName", formData.lastName);
      data.append("companyName", formData.companyName);
      data.append("countryCode", formData.countryCode);
      data.append("streetAddress", formData.streetAddress);
      data.append("cityCode", formData.cityCode);
      data.append("state", formData.state);
      data.append("postCode", formData.postCode);
      data.append("phoneNumber", formData.phoneNumber);
      data.append("drivingLicense", formData.drivingLicense);

      axios
        .post(userRegister, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data);
          navigate("/WebRegisterSuccuessFullPage");
        })

        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message ==
              "Validation failed (expected size is less than 25000)"
          ) {
            toast.error("image size should be less than 1MB");
            // [P2002]: Unique constraint failed on the constraint: `users_phone_number_key`




          }else if (
            error.response &&
            error.response.data &&
            error.response.data.message ==
              "[P2002]: Unique constraint failed on the constraint: `users_username_key`"
          ) {
            toast.error("Username already exist");
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message ==
              "[P2002]: Unique constraint failed on the constraint: `users_phone_number_key`"
          ) {
            toast.error("Phone number is already exist");
          } 
          else if (
            error.response &&
            error.response.data &&
            error.response.data.message ==
              "[P2002]: Unique constraint failed on the constraint: `users_email_key`"
          ) {
            toast.error("Email already exist");
          } 
          else if (
            error.response &&
            error.response.data &&
            error.response.data.message ==
              "phoneNumber must be a valid phone number"
          ) {
            toast.error("Phone Number is not valid");
          } else {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  const [cititesList, setcititesList] = useState([]);

  const CITIES = gql`
  query {
    countries(countryCode: "${formData.countryCode}") {
      cities {
        code
        name
      }
    }
  }
  `;

  const countiresList = useQuery(COUNTRIES);

  const [getCities] = useLazyQuery(CITIES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log(data);
      setcititesList([...data?.countries[0]?.cities]);
    },
  });

  useEffect(() => {
    if (formData.countryCode !== "") {
      getCities();
    }
  }, [formData.countryCode]);

  const onClick = () => {
    console.log(formData);
    // console.log(RegUser)
  };

  console.log(errors, "-- errors");

  const [isregistersubmitsucuess, setIsRegisterSubmitSucuess] = useState("");


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };  
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <AnimatedCursor />
      <div className="main-scroll-bar login-page">
        <Header />

        <section className="login-sect">
          <div className="container">
            <div className="form-box">
              {isregistersubmitsucuess === "" ? (
                <div className="form-border-box" ref={formRef}>
                  <div className="icn hide">
                    <i className="fa-solid fa-xmark" />
                  </div>
                  <div className="form-img">
                    <img src={RegisterGroup1} alt="" />
                  </div>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <input
                      type="text"
                      name="username"
                      placeholder="Username*"
                      value={formData.username}
                      required
                      ref={usernameRef}
                      onChange={handleChange}
                    />
                    {errors.username && (
                      <span
                        className="span"
                        style={{
                          color: "red",
                          margin: "0px",
                          textAlign: "left",
                        }}
                      >
                        {errors.username}
                      </span>
                    )}

                    <input
                      type="text"
                      name="email"
                      placeholder="Email*"
                      value={formData.email}
                      ref={emailRef}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="span" style={{ color: "red" }}>
                        {errors.email}
                      </span>
                    )}
                    <div className="set-form-pswrd-feild">
                    <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                      placeholder="Password*"
                      value={formData.password}
                      onChange={handleChange}
                    />
                     {showPassword ? (
                    <FaEyeSlash onClick={togglePasswordVisibility} />
                  ) : (
                    <FaEye onClick={togglePasswordVisibility} />
                  )}

                    {errors.password && (
                      <span className="span" style={{ color: "red" }}>
                        {errors.password}
                      </span>
                    )}
                    </div>
                    <div className="sign-para">
                      <p>Valid Drivers License*</p>
                      <div className="num-plate-photo-parent">
                        <div class="choose-file">
                          <label className="custom-file-upload">
                            <img />
                            <input
                              name="numberPlate"
                              type="file"
                              onChange={handleFileChange}
                            />
                            Choose File
                          </label>
                          <span>
                            <span>
                              {selectedFileName ? (
                                <span>{selectedFileName}</span>
                              ) : (
                                <span> No file chosen</span>
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="sign-bill">
                      <h3>Billing Details</h3>
                      <p>
                        *You will only be charged a 5% buyer's premium if you
                        are the highest bidder &amp; win the auction
                      </p>
                      <input
                        type="text"
                        name="fname"
                        placeholder="First Name*"
                        value={formData.firstName}
                        required
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          });
                        }}
                      />
                      {errors.firstName && (
                        <span className="span" style={{ color: "red" }}>
                          {errors.firstName}
                        </span>
                      )}

                      <input
                        type="text"
                        name="lname"
                        placeholder="Last Name*"
                        value={formData.lastName}
                        required
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            lastName: e.target.value,
                          });
                        }}
                      />
                      {errors.lastName && (
                        <span className="span" style={{ color: "red" }}>
                          {errors.lastName}
                        </span>
                      )}

                      <input
                        type="name"
                        placeholder="Company Name"
                        name="company"
                        value={formData.companyName}
                        required
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            companyName: e.target.value,
                          });
                        }}
                      />
                      {errors.companyName && (
                        <span className="span" style={{ color: "red" }}>
                          {errors.companyName}
                        </span>
                      )}
                    </div>
                    <div className="plate-sell-drp-dwn signup-slct">
                      <select
                        name="country"
                        value={formData.countryCode}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFormData({
                            ...formData,
                            countryCode: e.target.value,
                          });
                        }}
                      >
                        <option value="">Choose Country*</option>
                        {countiresList?.data?.countries.map((country, i) => (
                          <option key={i} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <textarea
                      id=""
                      cols={30}
                      rows={8}
                      placeholder="Street Address*"
                      name="address"
                      // defaultValue={""}
                      value={formData.streetAddress}
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          streetAddress: e.target.value,
                        });
                      }}
                    />
                    {errors.streetAddress && (
                      <span className="span" style={{ color: "red" }}>
                        {errors.streetAddress}
                      </span>
                    )}

                    <div className="plate-sell-drp-dwn signup-slct">
                      <select
                        name="city*"
                        value={formData.cityCode}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            cityCode: e.target.value,
                          });
                        }}
                      >
                        <option value="">Choose City*</option>
                        {cititesList.map((item, i) => (
                          <option key={i} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <input
                      type="name"
                      placeholder="State*"
                      name="state"
                      value={formData.state}
                      required
                      onChange={(e) => {
                        setFormData({ ...formData, state: e.target.value });
                      }}
                    />
                    {errors.state && (
                      <span className="span" style={{ color: "red" }}>
                        {errors.state}
                      </span>
                    )}

                    <input
                      type="name"
                      placeholder="Postcode/Zipcode*"
                      name="post_code"
                      value={formData.postCode}
                      required
                      onChange={(e) => {
                        setFormData({ ...formData, postCode: e.target.value });
                      }}
                    />
                    <input
                      type="tel"
                      placeholder="+614XXXXXXXX*"
                      name="phone"
                      value={formData.phoneNumber}
                      required
                      maxLength="12"
                      ref={phoneRef}
                      onChange={(e) => {
                        if (e.target.value.length <= 12) {
                          setFormData({
                            ...formData,
                            phoneNumber: e.target.value,
                          });
                        }
                      }}
                    />
                    {errors.phoneNumber && (
                      <span className="span" style={{ color: "red" }}>
                        {errors.phoneNumber}
                      </span>
                    )}

                    <div className="sign-last-para">
                      <p>
                        Your personal data will be used to support your
                        experience throughout this website, to manage access to
                        your account and for other purposes described in our
                        Privacy Policy.
                      </p>
                    </div>
                    <div className="form-box-btn">
                      <button
                        className="btn w-100"
                        name="registerBtn"
                        type="submit"
                        disabled={isLoading}
                      >
                       {isLoading ? 'Loading...' : 'Register'}
                      </button>
                    </div>
                    <div className="form-img-txt signup-form-got-to">
                      <img src={RegisterGroup1} alt="" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
                      </svg>
                      <Link to="/">Go to Signature +</Link>
                    </div>
                  </form>
                </div>
              ) : isregistersubmitsucuess === "form-box" ? (
                <div className="form-box">
                  <div className="form-border-box">
                    <div className="icn hide">
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div className="form-img"></div>
                    <form>
                      <div className="form-check">
                        <p>Remember Me</p>
                      </div>
                      <div className="form-box-btn">
                        <Link to="/LoginApiTest">Login</Link>
                      </div>
                      <div className="form-img-txt">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
                        </svg>
                        <Link to="/">Go to Signature +</Link>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Register;
