import { Routes, Route, Outlet, Link } from "react-router-dom";
import WebAskingPriceOneSearchLogo from '../../../assets/images/search-logo.png'
import WebAskingPriceOneLogo from '../../../assets/images/logo.png'
import WebAskingPriceOneXX3Plate from '../../../assets/images/XX-3-plate.png'
import WebAskingPriceOneBtmCardOne from '../../../assets/images/btm-card-one.png'
import WebAskingPriceOneBtmCardtwo from '../../../assets/images/btm-card-two.png'
import AnimatedCursor from "../../../components/AnimatedCursor";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
function AskingPriceOne() {
    return (
        <>
        <AnimatedCursor/>
            <div className="main-scroll-bar">
                <header className="header-bg">
                    <Header/>
                    <section className="top_banner">
                        <div className="Web-Container">
                            <div className="grid-row contactpage">
                                <div className="grid-col-2">
                                    <div className="contentBox">
                                        <h1>
                                            VIC XX3
                                        </h1>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur <br />
                                            adipiscing elit. Nam convallis rhoncus eros, <br />
                                            nec scelerisque nisi
                                        </p>
                                    </div>
                                </div>
                                <div className="grid-col-2"></div>
                            </div>
                        </div>
                    </section>
                </header>
                <div className="main_contact-page">
                    <section className="bid-main-sec">
                        <div className="Web-Container">
                            <div className="bid-parent">
                                <div className="bid-grid-main">
                                    <div className="bid-inner-grid-content">
                                        <div className="prent-left-grid">
                                            <div className="main-left-bid-grid">
                                                <div className="serch-icn-cont">
                                                    <img src={WebAskingPriceOneSearchLogo} alt="" />
                                                </div>
                                                <div className="logo-inner-card">
                                                    <img src={WebAskingPriceOneLogo} alt="" />
                                                </div>
                                                <div className="plate-bid-card">
                                                    <img src={WebAskingPriceOneXX3Plate} alt="" />
                                                </div>
                                                <div className="text-bid-card">
                                                    <h2>
                                                        Victorian Signature <br />
                                                        Number Plate
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="btm-card-img">
                                                <div className="btm-card-inner">
                                                    <div className="btm-img">
                                                        <img src={WebAskingPriceOneBtmCardOne} alt="" />
                                                    </div>
                                                    <div className="btm-img">
                                                        <img src={WebAskingPriceOneBtmCardtwo} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bid-inner-grid-content">
                                        <div className="top-txt-rightbid">
                                            <h2 className="fw-400">Asking Price</h2>
                                            <h4 className="fw-400 color-fff pd-h4-custom">
                                                Current Bid : $2,500.00
                                            </h4>
                                        </div>
                                        <div className="btm-txt-right-col">
                                            <h2 className="para-askprice-one">
                                            you need to be verified first with a registered account to be
                                            able to visit this page.
                                            </h2>
                                          
                                        </div>
                                        <div className="anchor-ask-price-parent">
                                        <div className="anchor-ask-price-inner">
                                                <Link to="/">BACK TO HOME</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>

        </>
    )
}

export default AskingPriceOne