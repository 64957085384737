
import React from "react";
import { Link } from "react-router-dom";
function Main_fifth_modal() {
  return (
    <>
      <main id="main">
        <div class="dashboard-right-side main-fourth-modal-parent">
          <div class="modal-form-request-parent">
            <div style="box-shadow: none;" class="modal-form-request-inner">
              <div class="cancel-icn">
                <Link to="/">
                  <img src="assets/images/cancel-icn.png" alt="" />
                </Link>
              </div>
              <div class="abc-heading text-center abc-chng">
                <h1>AB-01</h1>
              </div>
              <div class="row">
                <div class="col-lg-6 bd-left">
                  <div class="main-modal-width">
                    <div class="abc-heading text-center">
                      <h1>Seller Details</h1>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Name
                            <input type="text" placeholder="Ethen Booker" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Email
                            <input
                              type="email"
                              placeholder="ethenbooker12@outlook.com"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label class="d-flex flex-column" for="modalinp1">
                            Address
                            <textarea
                              placeholder="Lorem ipsum dolor sit amet, consectetur ad
                                        ipiscing elit. Etiam consectetur ex ut felis co
                                        ngue sagittis."
                            ></textarea>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Reserve Price
                            <input type="text" placeholder="$4000.00" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Desired Plate Price
                            <input type="text" placeholder="$4000.00" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="send-req-btn">
                      <a href="">Amount Transfer</a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="main-modal-width">
                    <div class="abc-heading text-center">
                      <h1>Buyer Details</h1>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Name
                            <input type="text" placeholder="John Smith" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Email
                            <input
                              type="email"
                              placeholder="jh_smith12@outlook.com"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label class="d-flex flex-column" for="modalinp1">
                            Address
                            <textarea
                              placeholder="Lorem ipsum dolor sit amet, consectetur ad
                                        ipiscing elit. Etiam consectetur ex ut felis co
                                        ngue sagittis."
                            ></textarea>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Deposit Amount
                            <input type="text" placeholder="$0" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-lg-12">
                        <div class="modal-input-form-req py-2">
                          <label for="modalinp1">
                            Total Amount
                            <input type="text" placeholder="$4000.00" />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="status-text">
                        <h6>Status</h6>
                      </div>
                      <div class="three-btns">
                        <a class="btn-bg-red-col-white" href="">
                          Pending
                        </a>
                        <a class="" href="">
                          Deposit
                        </a>
                        <a href="">Paid</a>
                      </div>
                    </div>
                    <div class="send-req-btn">
                      <h6>Request for Initial Deposit</h6>
                      <a href="">Send Requests</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Main_fifth_modal;
