import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";

const ProtectedRoutes = ({ children, isUserLogIn }) => {
  let location = useLocation();

  if (!isUserLogIn) {
    return <Navigate to="/LoginApiTest" state={{ from: location }} replace />;
  }
  return children;
};

export const PrivateRoute = ({ element: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
        // {...rest}
        path="/UserSellerDashboard"
        // element={isAuthenticated ? <Component/> : <Navigate to="/LoginApiTest"/>}
        element={<Component/>}
    />
  );
};

export const PublicRoute = ({ element: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}


    />
  );
};
export default ProtectedRoutes;
