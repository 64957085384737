import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
import Pic from '../../../../assets/images/auctioncardimg1.png'
function Auction3() {
  return (
<section className="dasgboard-miansection hundred-payment pb-5">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="container pb-5">
    <div className="row pt-2">
    <UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <div className="row">
            <div className="sllermain-title">
              <h1>Auction</h1>
              <div className="sidebar-brand">
                {/*<h2>Switch to Saller  */}
                {/*    <label class="switch">*/}
                {/*      <input type="checkbox" id="formButton" onclick="location.href='./saller/auctionfive.html';">*/}
                {/*        <span class="slider round"></span>*/}
                {/*    </label>*/}
                {/*</h2>*/}
                <h2
                  id="formButton"
                  onclick="location.href='./saller/auctionfive.html';"
                >
                  Switch to Seller{" "}
                  <i className="fa fa-refresh" aria-hidden="true" />
                  {/*  <label class="switch">*/}
                  {/*    <input type="checkbox" id="formButton" onclick="location.href='index.html';">*/}
                  {/*    <span class="slider round"></span>*/}
                  {/*</label>  */}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-5 my-4 Latest-Order gx-0">
          <div className="col-sm-12 col-md-12 col-lg-12 latest_order">
            <div className="order-heading">
              <div className="row payment-heading">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <h1>
                    {" "}
                    <span className="icon-shop">
                      {" "}
                      <i className="fa-solid fa-gavel" />{" "}
                    </span>{" "}
                    Auction
                  </h1>
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <ul className="Auction-list">
                    <li onclick="location.href='auctiontwo.html';">
                      {" "}
                      Active Bids <span> (3)</span>{" "}
                    </li>
                    <li
                      className="active"
                      onclick="location.href='auctionthree.html';"
                    >
                      {" "}
                      Bids Won <span> (3) </span>
                    </li>
                    <li onclick="location.href='auctionfour.html';">
                      {" "}
                      Bids Lost <span> (3) </span>{" "}
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="row auctioncard auctionthree">
                    <div className="card col-4">
                      <img
                        src={Pic}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <div className="title-card-auction">
                          <h5 className="card-title">VIC XX - 3</h5>
                        </div>
                      </div>
                      <div className="card-body Another-link">
                        <ul>
                          <li>
                            {" "}
                            <a href=""> My Bid </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="">$5,400.00</a>{" "}
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {" "}
                            <a href=""> Highest Bid </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="">$6,100.00</a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="card-body status-auction">
                        <a href="#" className="stat-auction">
                          Status
                        </a>
                        <a
                          href="#"
                          className="stat-auction-one"
                          style={{ background: "#6A6A6A" }}
                        >
                          Closed
                        </a>
                      </div>
                    </div>
                    {/* 1 */}
                    <div className="card col-4">
                      <img
                        src={Pic}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <div className="title-card-auction">
                          <h5 className="card-title">VIC XX - 3</h5>
                        </div>
                      </div>
                      <div className="card-body Another-link">
                        <ul>
                          <li>
                            {" "}
                            <a href=""> My Bid </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="">$5,400.00</a>{" "}
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {" "}
                            <a href=""> Highest Bid </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="">$6,100.00</a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="card-body status-auction">
                        <a href="#" className="stat-auction">
                          Status
                        </a>
                        <a
                          href="#"
                          className="stat-auction-one"
                          style={{ background: "#6A6A6A" }}
                        >
                          Closed
                        </a>
                      </div>
                    </div>
                    {/* 2 */}
                    <div className="card col-4">
                      <img
                        src={Pic}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <div className="title-card-auction">
                          <h5 className="card-title">VIC XX - 3</h5>
                        </div>
                      </div>
                      <div className="card-body Another-link">
                        <ul>
                          <li>
                            {" "}
                            <a href=""> My Bid </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="">$5,400.00</a>{" "}
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {" "}
                            <a href=""> Highest Bid </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="">$6,100.00</a>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="card-body status-auction">
                        <a href="#" className="stat-auction">
                          Status
                        </a>
                        <a
                          href="#"
                          className="stat-auction-one"
                          style={{ background: "#6A6A6A" }}
                        >
                          Closed
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* 3 */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Auction3