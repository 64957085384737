import React from 'react'
import Sidebar from '../../../components/Sidebar'

function Plates_record_modal_one() {
  return (
    <div class="bg-dashboard">
  <main id="main">
  <Sidebar />
    <div className="dashboard-right-side ">
      <div className="page-title">
        <h1>Plates Received</h1>
      </div>
      <div className="hg-box">
        <div className="box-pages-parent bid-strt">
          <div className="cancel-icn">
            <a href="dashboard-main.php">
              <img src="assets/images/cancel-icn.png" alt="" />
            </a>
          </div>
          <div className="date-time-set">
            <h2>Starting Bid</h2>
            <input type="text" placeholder="$$$$" />
          </div>
          <div className="date-time-set">
            <h2>Set Time for Auction</h2>
            <span>
              <input type="number" placeholder="DD" />
              <input type="number" placeholder="HH" />
              <input type="number" placeholder="MM" />
              <input type="number" placeholder="SS" />
            </span>
          </div>
          <div className="approved-btn cust-verified-btn">
            <a href="">Approve for Auction</a>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
  )
}

export default Plates_record_modal_one