import Sidebar from '../../../components/Sidebar'
import pagenation from '../../../assets/images/customer-btm-img.png'
import { ApolloClient, InMemoryCache, gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { IpAddress } from '../../../utils/Function';
import ResponsiveNavBarDasbord from '../../../components/ResponsiveNavBarDasbord';

// console.log(IpAddress, 'ip addressss customrrrrr')

const client = new ApolloClient({
  uri: IpAddress,
  cache: new InMemoryCache(),
});
const GET_USERS_QUERY = gql`
  query {
    users(where: { status: APPROVED }) {
      id
      firstName
      lastName
      username
      email
      phoneNumber
    }
  }
`;

function Customer() {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Adjust the number of items per page according to your needs


  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    const totalPages = Math.ceil(data.users.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };



  function renderPageNumbers() {
    const totalPages = Math.ceil(users.length / itemsPerPage);
    const pageNumbers = [];


    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === currentPage ? 'active' : ''}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  }

  const { loading, error, data } = useQuery(GET_USERS_QUERY, { client });


  if (loading) return (
    <div className="bg-dashboard">
    <main id="main">
    <ResponsiveNavBarDasbord/>
      <Sidebar />
      <div className="dashboard-right-side">
            <div className="form-req-tble-parent">
            <div className="payment-inner-page-set">
              <div style={{backgroundColor: "#FFFBF0"}} className="form-req-tble-inner d-flex">
            <h1 style={{color: "#6D6D6D"}}>Loading....</h1>
            </div>
            </div>
          </div>
          </div>
        </main>
        </div>
  );
  if (error) return <div>Error: {error.message}</div>;
  const users = data?.users || [];
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Slice the users array based on pagination indices
  const displayedUsers = [...users].reverse().filter((user) => user.username !== "Admin").slice(startIndex, endIndex);

  // console.log([...users].reverse(),"-------users")
  return (
    <>
      <div class="bg-dashboard">
        <main id="main">
          <ResponsiveNavBarDasbord/>
          <Sidebar />
          <div class="dashboard-right-side">
            <div class="page-title">
              <h1>CUSTOMER</h1>
            </div>
            <div class="payment-inner-page-set">
              <div class="cust-tble-parent">
                <div class="form-req-tble-inner d-flex">
                  <div style={{ width: "20%" }} class="form-req-tble-content text-center"><h3>Username</h3></div>
                  <div style={{ width: "30%" }} class="form-req-tble-content text-center"><h3>Fullname</h3></div>
                  <div style={{ width: "30%" }} class="form-req-tble-content text-center"><h3>Email</h3></div>
                  <div style={{ width: "20%" }} class="form-req-tble-content text-center"><h3>Phone</h3></div>
                </div>
                {displayedUsers.slice().map((user) =>  (
                  <div class="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3">
                    <div style={{ width: "20%" }} class="form-req-tble-content text-center"><Link to={`/UserDashboard/${user.id}`}><h3 style={{ borderBottom: "2px solid #707070" }}>{user.username}</h3></Link></div>
                    <div style={{ width: "30%" }} class="form-req-tble-content text-center"><h3>{`${user.firstName} ${user.lastName}`}</h3></div>
                    <div style={{ width: "30%" }} class="form-req-tble-content text-center"><h3>{user.email}</h3></div>
                    <div style={{ width: "20%" }} class="form-req-tble-content text-center"><h3>{user.phoneNumber}</h3></div>
                  </div>
                ))}
              </div>
              <div class="btm-cust-img d-flex justify-content-end">
                <button onClick={goToPreviousPage} className='approved-btn-3 mx-2'>
                  Previous
                </button>
                <button onClick={goToNextPage} className='approved-btn-3'>
                  Next
                </button>
              </div>

              <div className="pagination">
                {renderPageNumbers()}
              </div>


            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Customer