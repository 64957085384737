import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
import pic from '../../../../assets/images/auctionfiveplate.png'
function Auction5() {
  return (
    <section className="dasgboard-miansection">
      <div className="container">
        <div className="row pt-2">
          <UserSidebar />
          <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <div className="sllermain-title">
                <h1>DASHBOARD</h1>
                <div className="sidebar-brand">
                  <h2 id="formButton" onclick="location.href='../auction.html';">
                    Switch to buyer{" "}
                    <i className="fa fa-refresh" aria-hidden="true" />
                  </h2>
                </div>
              </div>
            </div>
            <div className="row my-4 Latest-Order gx-0">
              <div className="col-10 latest_order">
                <div className="order-heading">
                  <h1>
                    {" "}
                    <span className="icon-shop">
                      {" "}
                      <i className="fa-solid fa-gavel" />{" "}
                    </span>{" "}
                    Auction
                  </h1>
                </div>
                <div className="tabmake">
                  <div className="orderhead auction-table">
                    <ul className="order-first auction-head">
                      <li> Image </li>
                      <li> Products </li>
                      <li> Starting Amount </li>
                      <li> Remaining Time </li>
                      <li> Status </li>
                    </ul>
                    <ul className="order-five auction-table-body">
                      <li onclick="location.href='./auctionsix.html';">
                        {" "}
                        <img
                          src={pic}
                          alt=""
                        />{" "}
                      </li>
                      <li> VIC XX3 </li>
                      <li> $5,500.00 </li>
                      <li> 00 : 15 : 26 </li>
                      <li> Closed </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Auction5