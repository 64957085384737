import React from 'react'
import Sidebar from '../../../components/Sidebar'

function Main_third_modal() {
  return (
<div class="bg-dashboard">
  <main id="main">
  <Sidebar />
  <div className="dashboard-right-side main-first-modal-parent">
  <div className="modal-form-request-parent">
    <div className="modal-form-request-inner">
      <div className="cancel-icn">
        <a href="main-second-modal.php">
          <img src="assets/images/cancel-icn.png" alt="" />
        </a>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              First Name
              <input type="text" placeholder="Ethen" />
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Last Name
              <input type="email" placeholder="Booker" />
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Phone
              <input type="text" placeholder="123 456 7890" />
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Email
              <input type="email" placeholder="booker.ethen@outlook.com" />
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Street Address
              <input
                type="text"
                placeholder="Lorem ipsum dolor sit amet, consectetur."
              />
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Suburb
              <input type="email" placeholder="Lorem ipsum" />
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              Post Code
              <input type="text" placeholder={12345} />
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="modal-input-form-req py-2">
            <label htmlFor="modalinp1">
              State
              <input type="email" placeholder="Victoria" />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  </main>
  {/* Latest compiled JavaScript */}
</div>
  )
}

export default Main_third_modal