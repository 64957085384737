import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
function Offerthree() {
  return (
<section className="dasgboard-miansection hundred-payment pb-5">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="container pb-5">
    <div className="row pt-2">
<UserSidebar/>
<div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
  <div className="dashboard-title">
    <div className="row">
      <div className="sllermain-title">
        <h1>Offer</h1>
        <div className="sidebar-brand">
          {/*<h2>Switch to Seller*/}
          {/*    <label class="switch">*/}
          {/*        <input type="checkbox" id="formButton" onclick="location.href='./saller/offerfour.html';">*/}
          {/*        <span class="slider round"></span>*/}
          {/*    </label>*/}
          {/*</h2>*/}
          <h2
            id="formButton"
            onclick="location.href='./saller/offerfour.html';"
          >
            Switch to Seller <i className="fa fa-refresh" aria-hidden="true" />
            {/*  <label class="switch">*/}
            {/*    <input type="checkbox" id="formButton" onclick="location.href='index.html';">*/}
            {/*    <span class="slider round"></span>*/}
            {/*</label>  */}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div className="row pb-5 my-4 Latest-Order gx-0">
    <div className="col-sm-12 col-md-12 col-lg-12 latest_order">
      <div className="order-heading">
        <div className="row payment-heading">
          <div className="col-md-12 col-lg-12 col-sm-12 pb-3">
            <h1>
              {" "}
              <span className="icon-shop">
                {" "}
                <i className="fa-solid fa-location-arrow" />{" "}
              </span>
              Offer
            </h1>
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12 offerthree">
            <p> Balance to be paid now </p>
            <h3> $6,400.00 </h3>
            <p> Select Deposit Payment Method </p>
            <h3> $6,400.00 </h3>
            <fieldset className="row pt-1 pb-3">
              <div className="col-sm-12 col-md-8 col-lg-8 pt-3">
                <input
                  type="radio"
                  id="credit"
                  name="fav_language"
                  defaultValue="credit"
                />
                &nbsp;{" "}
                <label htmlFor="credit">Credit or Debit Card via Stripe</label>
                <br />
              </div>
            </fieldset>
            <a href="" className="logout deposit">
              Pay Deposit via Stripe
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  </div>
</section>

  )
}

export default Offerthree