import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import cancelIcon from "../../../assets/images/cancel-icn.png";
import counter from "../../../assets/images/encounter.png";
import group24 from "../../../assets/images/Group-24.png";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import PriceCountComponent from "../../../components/PriceCountComponent";
import ResponsiveNavBarDasbord from "../../../components/ResponsiveNavBarDasbord";
import CountdownTimer from "../../../components/Counter";

const PLATES_QUERY = gql`
  query {
    plates(where: { purpose: LISTING }) {
      combination
      id
      askingPrice
      user {
        firstName
        lastName
      }
    }
  }
`;

const AUCTION_PLATES_QUERY = gql`
  query {
    plates(where: { purpose: AUCTION }) {
      id
      combination
      askingPrice
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            endAt
            bids {
              price
            }
          }
        }
      }
    }
  }
`;

const GET_PLATE = gql`
  query GetPlate($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      combination
      askingPrice
      user {
        firstName
        lastName
      }
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            bids {
              user {
                firstName
                lastName
              }
              price
            }
          }
        }
      }
    }
  }
`;

const GET_PLATE_LISTING = gql`
  query GetPlateListing($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      combination
      askingPrice
      user {
        firstName
        lastName
      }
      detail {
        ... on PlateListing {
          offers {
            user {
              firstName
            }
            price
          }
          buyer {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const customStyles = {
  content: {
    top: "35%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "70%",
  },
};
const setcustomstyle = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "60%",
  },
};

function BidsReceived() {
  const [plateID, setPlateID] = useState(null);
  const handleImageClick = (id) => {
    bidauctionreceivemodal(id);
  };

  const [getPlate, { called, loading: Loading2, data: data2, error: error2 }] =
    useLazyQuery(GET_PLATE);
  const [
    getPlatelisting,
    { called: called2, loading: Loading3, data: data3, error: error3 },
  ] = useLazyQuery(GET_PLATE_LISTING);
  const [isbidrecivemodal, setIsBidReciveModal] = useState(false);
  const bidreceivemodal = () => {
    getPlate({
      variables: { id: parseInt(plateID) },
    });
    setIsBidReciveModal((prev) => !prev);
  };
  const [isbidauctionrecivemodal, setIsBidAuctionReciveModal] = useState(false);
  const bidauctionreceivemodal = (pId) => {
    getPlate({
      variables: { id: pId },
    });
    setIsBidAuctionReciveModal((prev) => !prev);
  };

  const { loading, error, data } = useQuery(PLATES_QUERY);
  const {
    loading: auctionPlatesLoading,
    error: auctionPlatesError,
    data: auctionPlatesData,
  } = useQuery(AUCTION_PLATES_QUERY);

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (auctionPlatesLoading || loading || Loading2 || Loading3) {
    return (
      <div className="bg-dashboard">
      <main id="main">
      <ResponsiveNavBarDasbord/>
        <Sidebar />
        <div className="dashboard-right-side">
              <div className="form-req-tble-parent">
              <div className="payment-inner-page-set">
                <div style={{backgroundColor: "#FFFBF0"}} className="form-req-tble-inner d-flex">
              <h1 style={{color: "#6D6D6D"}}>Loading....</h1>
              </div>
              </div>
            </div>
            </div>
          </main>
          </div>
  )}

  if (auctionPlatesError) {
    return <p>Error: {auctionPlatesError.message}</p>;
  }

  const plates = data?.plates || [];
  const auctionPlates = auctionPlatesData?.plates || [];

  // if (Loading2) return <p>Loading...</p>;
  if (error2) return <p>Error: {error3.message}</p>;

  // if (Loading3) return <p>Loading...</p>;
  if (error3) return <p>Error: {error2.message}</p>;

  const plateData = data2?.plate;
  {
    console.log(data3, "dataaaa3");
  }
  return (
    <div class="bg-dashboard">
      <div id="main">
        <Sidebar />
        <ResponsiveNavBarDasbord />
        <div class="dashboard-right-side">
          <div class="page-title dashbard-main-head-sett">
            <h1>BIDS Received</h1>
          </div>
          <div class="parent-of-bids">
            <div class="parent-box-bids">
              <div class="parent-border-box sett-box-width">
                <div class="payment-inner-page-set sett-bids-box">
                  <div class="bid-fi-table-none">
                    <ul
                      class="nav nav-pills mb-5"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          style={{ marginLeft: "0" }}
                          class="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Listing
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Auction
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div class="bid-table-parent">
                          <div class="bid-table-start">
                            <div class="parent-offer-table">
                              <div class="box-table-start">
                                <div class="bid-create-tble d-flex w-100 justify-content-center">
                                  <div
                                    style={{ width: "26%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Plate Combination</h3>
                                  </div>
                                  <div
                                    style={{ width: "15%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Asking Price</h3>
                                  </div>
                                  <div
                                    style={{ width: "28%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Seller Name</h3>
                                  </div>
                                  <div
                                    style={{ width: "15%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>No. Offers Received</h3>
                                  </div>
                                  <div
                                    style={{ width: "15%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Details</h3>
                                  </div>
                                </div>

                                <div>
                                  {/* Map over the plates array and render the data */}
                                  {plates
                                    .slice()
                                    .reverse()
                                    .map((plate, index) => (
                                      <div
                                        className="bid-create-tble d-flex w-100 justify-content-center"
                                        key={index}
                                      >
                                        <div
                                          style={{ width: "26%" }}
                                          className="bid-tble-next-line text-center"
                                        >
                                          <h3>{plate?.combination}</h3>
                                        </div>
                                        <div
                                          style={{ width: "15%" }}
                                          className="bid-tble-next-line text-center"
                                        >
                                          <h3>{plate?.askingPrice}</h3>
                                        </div>
                                        <div
                                          style={{ width: "26%" }}
                                          className="bid-tble-next-line text-center"
                                        >
                                          <h3>
                                            {plate?.user.firstName}
                                            {plate?.user.lastName}
                                          </h3>
                                        </div>
                                        <div
                                          style={{ width: "23%" }}
                                          className="bid-tble-next-line text-center"
                                        >
                                          <PriceCountComponent
                                            plateId={plate?.id}
                                          />
                                        </div>
                                        <div
                                          style={{ width: "9%" }}
                                          className="bid-tble-next-line text-center"
                                        >
                                          {" "}
                                          {/* <Link to="/Main_first_modal"> */}
                                          <img
                                            onClick={() => {
                                              getPlatelisting({
                                                variables: {
                                                  id: parseInt(plate.id),
                                                },
                                              });
                                              console.log("d3", data3);
                                              setIsBidReciveModal(
                                                (prev) => !prev
                                              );
                                            }}
                                            className="sec-tab-move-new-table"
                                            src={group24}
                                            alt=""
                                          />
                                          {/* </Link>{" "} */}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div class="bid-table-parent">
                          <div class="bid-table-start">
                            <div class="parent-offer-table">
                              <div class="box-table-start">
                                <div class="bid-create-tble d-flex w-100">
                                  <div
                                    style={{ width: "25%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Plate Combination</h3>
                                  </div>
                                  <div
                                    style={{ width: "26%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Starting Bid</h3>
                                  </div>
                                  <div
                                    style={{ width: "20%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Current Bid</h3>
                                  </div>
                                  <div
                                    style={{ width: "27%" }}
                                    class="bid-tble-inner text-center"
                                  >
                                    <h3>Remaining Time</h3>
                                  </div>
                                </div>
                                <div>
                                  {/* Map over the auctionPlates array and render the data */}
                                  {auctionPlates.slice().reverse().map((plate, index) => (
                                    <div
                                      className="bid-create-tble d-flex w-100"
                                      key={index}
                                    >
                                      <div
                                        style={{ width: "25%" }}
                                        className="bid-tble-next-line text-center"
                                      >
                                        <h3>{plate.combination}</h3>
                                      </div>
                                      <div
                                        style={{ width: "26%" }}
                                        className="bid-tble-next-line text-center"
                                      >
                                        <h3>{plate.askingPrice}</h3>
                                      </div>
                                      <div
                                        style={{ width: "18%" }}
                                        className="bid-tble-next-line text-center"
                                      >
                                        <h3>{plate?.detail?.currentBid || "0"}</h3>
                                      </div>
                                      <div
                                        style={{ width: "24%" }}
                                        className="bid-tble-next-line text-center"
                                      >
                                        <p>
                                          {" "}
                                          <CountdownTimer
                                            targetDate={new Date(
                                              plate.detail?.auction?.endAt
                                            ).getTime()}
                                          />
                                        </p>
                                      </div>
                                      <div
                                        style={{ width: "5%" }}
                                        className="bid-tble-next-line text-center"
                                      >
                                        {/* <Link to=""> */}
                                        <img
                                          onClick={() => {
                                            handleImageClick(plate.id);
                                          }}
                                          className="sec-tab-move-new-table"
                                          src={group24}
                                          alt=""
                                        />
                                        {/* </Link> */}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                isOpen={isbidrecivemodal}
                // onRequestClose={()=>setIsBidAuctionReciveModal(false)}
                style={customStyles}
              >
                <div class="next-bid-table">
                  <div class="next-bid-table-flex">
                    <div class="next-bid-border-box">
                      <div class="next-bid-table-img">
                        <img
                          onClick={() => setIsBidReciveModal(false)}
                          class="move-to-previous-bid-table"
                          src={cancelIcon}
                          alt=""
                        />
                      </div>
                      <div class="next-bid-table-start">
                        <div class="bid-next-tble d-flex w-100 text-center justify-content-center">
                          <div
                            style={{ width: "50%" }}
                            class="bid-next-tble-inner text-center"
                          >
                            <h3>Name</h3>
                          </div>
                          <div
                            style={{ width: "50%" }}
                            class="bid-next-tble-inner text-center"
                          >
                            <h3>Offer</h3>
                          </div>
                        </div>
                        {data3?.plate?.detail?.offers.map((offer, index) => (
                          <div
                            key={index}
                            className="bid-next-tble d-flex w-100 text-center justify-content-center"
                          >
                            <div
                              style={{ width: "50%" }}
                              className="bid-next-tble-next-line text-center"
                            >
                              <h3>{offer?.user?.firstName}</h3>
                            </div>
                            <div
                              style={{ width: "50%" }}
                              className="bid-next-tble-next-line text-center"
                            >
                              <h3>{offer?.price}</h3>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                isOpen={isbidauctionrecivemodal}
                // onRequestClose={bidauctionreceivemodal}
                style={setcustomstyle}
              >
                <div class="modal-form-request-parent">
                  <div class="modal-form-request-inner">
                    <div class="cancel-icn">
                      <img
                        onClick={() => setIsBidAuctionReciveModal(false)}
                        src={cancelIcon}
                        alt=""
                      />
                    </div>
                    <div class="abc-heading text-center">
                      <h1>{plateData?.combination}</h1>
                    </div>
                    <div class="counter-main-modal text-center mt-3">
                      {/* <img src={counter} alt="" /> */}
                    </div>
                    <div class="winner-table-parent mt-3">
                      <div class="winner-table d-flex w-100">
                        <div
                          style={{ width: "33.3%" }}
                          class="winner-table-inner text-center"
                        >
                          <h3>Name of Bidder</h3>
                        </div>
                        <div
                          style={{ width: "33.3%" }}
                          class="winner-table-inner text-center"
                        >
                          <h3>Bidding Amount</h3>
                        </div>
                        <div
                          style={{ width: "33.3%" }}
                          class="winner-table-inner text-center"
                        >
                          <h3>Current Highest Bid</h3>
                        </div>
                      </div>
                      <div class="winner-table winner-table-2 d-flex w-100">
                        <div class="winner-table-parent mt-3 w-100">
                          <div class="winner-table w-100 justify-content-between">
                            {plateData &&
                              plateData.detail.auction.bids.map(
                                (bid, index) => (
                                  <React.Fragment key={index}>
                                  <div className="d-flex justify-content-around">
                                      <div class="text-center py-2" >
                                        <h3 className="text-light">
                                          {bid?.user?.firstName}{" "}
                                          {bid?.user?.lastName}
                                        </h3>
                                      </div>
                                      <div class="text-center py-2" >
                                        <h3 className="text-light">${bid?.price}</h3>
                                      </div>
                                      <div class="text-center py-2" >
                                        <h3 className="text-light">${plateData?.detail?.currentBid}</h3>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidsReceived;
