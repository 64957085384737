import React, { useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import SellerGroup115 from '../../../assets/images/Group-115.png'
import SellerGroup113 from '../../../assets/images/Group-113.png'
import SellerIcnSvg1 from '../../../assets/images/icon1.svg'
import SellerProfile from '../../../assets/images/profile.png'
import SellerSecuritySvg from '../../../assets/images/security.svg'
import SellerSAddressBookSvg from '../../../assets/images/address-book.svg'
import SellerSPaymentSvg from '../../../assets/images/payment.svg'
import SellerNotificationSvg from '../../../assets/images/notifications.svg'
import SellerHammerSvg from '../../../assets/images/hammer.svg'
import SellerOfferSvg from '../../../assets/images/offer.svg'
import SellerLogoDale from '../../../assets/images/logodale.png'
import SellerCartSvg from '../../../assets/images/cart.svg'
import SellerLostIcnSvg from '../../../assets/images/lost-icon.svg'
import SellerEyeIcnSvg from '../../../assets/images/eye-icon.svg'
import SellerWonIcnSvg from '../../../assets/images/wonicon.svg'
import SellerIcnMaterialPerson from '../../../assets/images/Icon-material-person.png'
import SellerIcnMaterialSecurity from '../../../assets/images/Icon-material-security.png'
import SellerIcnAwesomeAddressBook from '../../../assets/images/Icon-awesome-address-book.png'
import SellerIcnIonicIosSettings from '../../../assets/images/Icon-ionic-ios-settings.png'
import SellerIcnMaterialPayment from '../../../assets/images/Icon-material-payment.png'
import SellerIcnAwesomeCreditCard from '../../../assets/images/Icon-awesome-credit-card.png'
import SellerIcnIonicIosNotification from '../../../assets/images/Icon-ionic-ios-notifications.png'
import SellerGroup191 from '../../../assets/images/Group-191.png'
import SellerIcnMaterialLocalOffer from '../../../assets/images/Icon-material-local-offer.png'
import SellerIcGroup24 from '../../../assets/images/Group-24.png'
import SellerIcnMetroHammer from '../../../assets/images/Icon-metro-hammer.png'
import Modal from 'react-modal';
import Switch from "react-switch";

const customStyles = {
    content: {
        top: '60%',
        left: '60%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "transparent",
        border: "none",
        width: "60%"
    },
};


function SellerDashBoard() {
    const [isReqVerificationModal, setIsReqVerificationModal] = useState(false)
    const [addingShipAddOrBillAdd, setAddingShipAddOrBillAdd] = useState("")
    const [addingAddressDetail, setAddingAddressDetail] = useState("")
    const [addpaymentdetail, setAddPaymentDetail] = useState("")
    const [addauctiontable, setAddAuctionTable] = useState("")
    const [addoffertable, setAddOfferTable] = useState("")
    const [addoffertablesecond, setAddOfferTableSecond] = useState("")
    const [addcardcounterapproved, setCardCounterApproved] = useState("")
    const onReqVerification = () => {
        setIsReqVerificationModal((prev) => !prev)
    }





    return (
        <>
            <Modal
                isOpen={isReqVerificationModal}
                onRequestClose={onReqVerification}
                style={customStyles}
            // contentLabel="Example Modal"
            >
                <div className={`request-verification-parent`}>
                    <div className="request-verification-code-parent">
                        <div className="request-verification-box">
                            <div className="request-verificaton-box-txt-flex">
                                <img src="assets/images/Icon-material-person.png" alt="" />
                                <h2>REQUEST VERIFICATION</h2>
                            </div>
                            <form action="">
                                <div className="request-verification-form-parent">
                                    <div className="row request-verification-input">
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">First Name</label>
                                                <span>*</span>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">Last Name</label>
                                                <span>*</span>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                    </div>
                                    <div className="row request-verification-input">
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">Phone</label>
                                                <span>*</span>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">Email</label>
                                                <span>*</span>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                    </div>
                                    <div className="row request-verification-input">
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">Street Address</label>
                                                <span>*</span>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">Suburb</label>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                    </div>
                                    <div className="row request-verification-input">
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">Post Code</label>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">State</label>
                                                <span>*</span>
                                            </div>
                                            <input type="fname" />
                                        </div>
                                    </div>
                                    <div className="row request-verification-input">
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">Post Code</label>
                                            </div>
                                            <input className="sett-the-file" type="file" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="request-verify-label-flex">
                                                <label for="">State</label>
                                                <span>*</span>
                                            </div>
                                            <input className="sett-the-file" type="file" />
                                        </div>
                                    </div>
                                    <div className="row sett-the-verification-para">
                                        <div className="col-lg-6">
                                            <p>Maximum file size: 32 MB</p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Maximum file size: 32 MB</p>
                                        </div>
                                    </div>
                                    <div className="row sett-the-verification-sec-para">
                                        <div className="col-lg-6">
                                            <p>Please provide a copy of your Driver Licence so that<br />
                                                we can verify your identity for our live bidding<br />
                                                platform.
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>If you have an updated address label, please
                                                provide a photo of the back of the licence.</p>
                                        </div>
                                    </div>
                                    <div className="request-verify-done-btn">
                                        <button className="move-to-previous-verification-code" onClick={onReqVerification}>Request Verification</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='bg-dashboard'>
                <main id="main">


                    <Sidebar />

                    <div className="dashboard-right-side">
                        <div className="page-title">
                            <h1>User Dashboard</h1>
                        </div>
                        <div className="tabs-pages">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">



                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                                        type="button" role="tab" aria-controls="home" aria-selected="true"><span><img
                                            src={SellerIcnSvg1} alt="" /></span> Dashboard</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                                        type="button" role="tab" aria-controls="profile" aria-selected="false"> <span><img
                                            src={SellerProfile} alt="" /></span> Profile</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                                        type="button" role="tab" aria-controls="contact" aria-selected="false"><span><img
                                            src={SellerSecuritySvg} alt="" /></span>Account Security</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="four-tab" data-bs-toggle="tab" data-bs-target="#four" type="button"
                                        role="tab" aria-controls="four" aria-selected="false"><span><img
                                            src={SellerSAddressBookSvg} alt="" /></span>Address</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="five-tab" data-bs-toggle="tab" data-bs-target="#five" type="button"
                                        role="tab" aria-controls="five" aria-selected="false"><span><img
                                            src={SellerSPaymentSvg} alt="" /></span>Payment Method</button>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="six-tab" data-bs-toggle="tab" data-bs-target="#six" type="button"
                                        role="tab" aria-controls="six" aria-selected="false"><span><img
                                            src={SellerNotificationSvg} alt="" /></span>Notification</button>
                                </li> */}
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="seven-tab" data-bs-toggle="tab" data-bs-target="#seven"
                                        type="button" role="tab" aria-controls="seven" aria-selected="false"><span><img
                                            src={SellerHammerSvg} alt="" /></span>Auction</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="eight-tab" data-bs-toggle="tab" data-bs-target="#eight"
                                        type="button" role="tab" aria-controls="eight" aria-selected="false"><span><img
                                            src={SellerOfferSvg} alt="" /></span>Offer</button>
                                </li>
                            </ul>




                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="dashboard-page-content">
                                        <div className="hello-box">
                                            <div className="hello-content">
                                                <img src={SellerLogoDale} alt="" />
                                                <p>Hello Nick! <br />
                                                    Welcome to Signature+ <br />
                                                    We have terms regarding eligibility to allow participants for the bidding
                                                    services. The condition specifies <br />
                                                    to register and start bidding. You must get your account verified to
                                                    participate.
                                                </p>
                                            </div>
                                            <button className="move-to-verification-code" onClick={onReqVerification}>Request Verification</button>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="recent-activity sett-first-tab-box">
                                                    <p><span><img src={SellerCartSvg} alt="" /></span>Recent Activity</p>

                                                    <div className="table-activity">
                                                        <div className="tb-row">
                                                            <span>Date</span>
                                                            <span>Plate Number</span>
                                                            <span>Bid Status</span>
                                                            <span>Your Bid</span>
                                                            <span>Highest Bid</span>
                                                            <span>Action</span>
                                                        </div>
                                                        <div className="tb-data">
                                                            <span>28 Mar 2023</span>
                                                            <span>VIC XX-09</span>
                                                            <span> <img src={SellerLostIcnSvg} alt="" /> Lost</span>
                                                            <span>$2500.00</span>
                                                            <span>$3999.00</span>
                                                            <span><img src={SellerEyeIcnSvg} alt="" /></span>
                                                        </div>
                                                        <div className="tb-data">
                                                            <span>28 Mar 2023</span>
                                                            <span>VIC XX-08</span>
                                                            <span> <img src={SellerWonIcnSvg} alt="" /> Won</span>
                                                            <span>$2500.00</span>
                                                            <span>$3999.00</span>
                                                            <span><img src={SellerEyeIcnSvg} alt="" /></span>
                                                        </div>
                                                        <div className="tb-data">
                                                            <span>28 Mar 2023</span>
                                                            <span>VIC XX-08</span>
                                                            <span> <img src={SellerLostIcnSvg} alt="" /> Lost</span>
                                                            <span>$2500.00</span>
                                                            <span>$3999.00</span>
                                                            <span><img src={SellerEyeIcnSvg} alt="" /></span>
                                                        </div>
                                                        <div className="tb-data">
                                                            <span>28 Mar 2023</span>
                                                            <span>VIC XX-08</span>
                                                            <span> <img src={SellerLostIcnSvg} alt="" /> Lost</span>
                                                            <span>$2500.00</span>
                                                            <span>$3999.00</span>
                                                            <span><img src={SellerEyeIcnSvg} alt="" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div>
                                                    <div className="parent-seller-box">
                                                        <div className="parent-border-box">
                                                            <div className="seller-box-img">
                                                                <img src={SellerGroup115} alt="" />
                                                            </div>
                                                            <div className="seller-total-sales-para">
                                                                <p>Total Sales</p>
                                                            </div>
                                                            <div className="seller-box-rates">
                                                                <span>8.4k</span>
                                                            </div>
                                                            {/* <div className="seller-box-btn">
                                                                <button>View More</button>
                                                            </div> */}
                                                        </div>
                                                        <div className="parent-border-box">
                                                            <div className="seller-box-img">
                                                                <img src={SellerGroup113} alt="" />
                                                            </div>
                                                            <div className="seller-total-sales-para">
                                                                <p>Total Sales</p>
                                                            </div>
                                                            <div className="seller-box-rates">
                                                                <span>80</span>
                                                            </div>
                                                            <div className="seller-box-btn">
                                                                <button>View More</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="parent-profile-form">
                                        <div className="profile-box-parent">
                                            <div className="profile-edit-text">
                                                <h2>Edit Profile</h2>
                                            </div>
                                            <form action="">
                                                <div className="profile-form-input-flex">
                                                    <div className="profile-input-name">
                                                        <label for="">First Name</label><br />
                                                        <input type="fname" />
                                                    </div>
                                                    <div className="profile-input-lname">
                                                        <label for="">Last Name</label><br />
                                                        <input type="lname" />
                                                    </div>
                                                    <div className="profile-input-phone">
                                                        <label for="">Phone</label><br />
                                                        <input type="phone" />
                                                    </div>
                                                    <div className="profile-input-email">
                                                        <label for="">Email</label><br />
                                                        <input type="email" />
                                                    </div>
                                                    <div className="profile-input-street-address">
                                                        <label for="">Street Address</label><br />
                                                        <input type="address" />
                                                    </div>
                                                    <div className="profile-input-suburb">
                                                        <label for="">Suburb</label><br />
                                                        <input type="suburb" />
                                                    </div>
                                                    <div className="profile-input-post-code">
                                                        <label for="">Post Code</label><br />
                                                        <input type="code" />
                                                    </div>
                                                    <div className="profile-input-state">
                                                        <label for="">State</label><br />
                                                        <input type="state" />
                                                    </div>
                                                </div>
                                                <div className="profile-form-btn">
                                                    <a href="#">Update</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    <div className="parent-profile-form">
                                        <div className="profile-box-parent">
                                            <div className="account-security-text">
                                                <img src={SellerIcnMaterialSecurity} alt="" />
                                                <h2>Security / Change password</h2>
                                            </div>
                                            <form action="">
                                                <div className="parent-account-security">
                                                    <label for="">New Password</label><br />
                                                    <input type="password" /><br />
                                                    <label for="">Confirm Password</label><br />
                                                    <input type="Cpassword" />
                                                </div>

                                                <div className="account-security-btn">
                                                    <a href="#">Update</a>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">
                                    <div className="parent-profile-form">
                                        <div className="profile-box-parent">
                                            {addingAddressDetail === "" ?
                                                <div className="adress-add-funtion-parent">
                                                    <div className="account-security-text gapping-set">
                                                        <img src={SellerIcnAwesomeAddressBook} alt="" />
                                                        <h2>CHANGE Or UPDATE Address</h2>
                                                    </div>
                                                    <div className="parent-address-btns">
                                                        <div className="adress-para-text">
                                                            <p>Shipping Address</p>
                                                        </div>
                                                        <div className="adress-add-btn-flex">
                                                            <button className="add-screen" onClick={() => {
                                                                setAddingShipAddOrBillAdd("shippingAddress")
                                                                setAddingAddressDetail('addressForm')
                                                            }}>ADD</button>
                                                            <button className="background-sett">EDIT</button>
                                                        </div>
                                                        <div className="adress-para-text">
                                                            <p>Billing Address</p>
                                                        </div>
                                                        <div className="adress-add-btn-flex">
                                                            <button className="add-screen" onClick={() => {
                                                                setAddingShipAddOrBillAdd("shippingAddress")
                                                                setAddingAddressDetail('addressForm')
                                                            }}>ADD</button>
                                                            <button className="background-sett">EDIT</button>
                                                        </div>
                                                    </div>
                                                    <div className="address-save-btn">
                                                        <button>Save Changes</button>
                                                    </div>
                                                </div> : addingAddressDetail === "addressForm" ?
                                                    <div className="parent-address-form">
                                                        <div className="address-text-btn-flex">
                                                            <div className="address-flex-text">
                                                                <img src={SellerIcnAwesomeAddressBook} alt="" />
                                                                <h2>CHANGE Or UPDATE Address</h2>
                                                            </div>
                                                            <div className="address-form-fi-btn">
                                                                <button className="add-more-screen" onClick={() => setAddingAddressDetail('shipAndBillAddress')}>ADD</button>
                                                                <button className="form-btn-none">EDIT</button>
                                                            </div>
                                                        </div>
                                                        <form action="">
                                                            <div className="address-form-parent placeholder-input">
                                                                <div className="row address-names">
                                                                    <div className="col-lg-6"><input type="fname" placeholder="First Name" />
                                                                    </div>
                                                                    <div className="col-lg-6"><input type="fname" placeholder="Last Name" /></div>
                                                                </div>
                                                                <div className="row address-comp-count">
                                                                    <div className="col-lg-6"><input type="text" placeholder="Company ( Optional )" />
                                                                    </div>
                                                                    <div className="col-lg-6"><input type="text" placeholder="Country" /></div>
                                                                </div>
                                                                <div className="row address-street">
                                                                    <div className="col-lg-12"><input type="text" placeholder="Street Address" />
                                                                    </div>
                                                                </div>
                                                                <div className="row address-suburb-state">
                                                                    <div className="col-lg-6"><input type="suburb" placeholder="Suburb" /></div>
                                                                    <div className="col-lg-6"><input type="state" placeholder="State" /></div>
                                                                </div>
                                                                <div className="row address-phone-code">
                                                                    <div className="col-lg-6"><input type="code" placeholder="Postcode" /></div>
                                                                    <div className="col-lg-6"><input type="phone" placeholder="Phone" /></div>
                                                                </div>
                                                                <div className="row address-email">
                                                                    <div className="col-lg-6"><input type="email" placeholder="Email" /></div>
                                                                </div>
                                                            </div>
                                                            <div className="adress-form-save-btn">
                                                                <button className="add-more-screen" onClick={() => setAddingAddressDetail('shipAndBillAddress')}>Save Changes</button>
                                                            </div>
                                                        </form>
                                                    </div> : addingAddressDetail === "shipAndBillAddress" ?
                                                        <div className="address-banner-parent">
                                                            <div className="address-text-btn-flex">
                                                                <div className="address-flex-text">
                                                                    <img src={SellerIcnAwesomeAddressBook} alt="" />
                                                                    <h2>CHANGE Or UPDATE Address</h2>
                                                                </div>
                                                                <div className="address-banner-btn">
                                                                    <button className="add-last-screen">EDIT</button>
                                                                </div>
                                                            </div>
                                                            <div className="address-parent-banner">
                                                                <div className="adress-banner-para">
                                                                    <p>Shipping Address</p>
                                                                </div>
                                                                <div className="parent-banner-text-parent">
                                                                    <div className="adress-banner-box">
                                                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr <br />
                                                                            consetetur
                                                                            sadipscing elitr</p>
                                                                    </div>
                                                                    <div className="side-box-address">
                                                                        <img src={SellerIcnIonicIosSettings} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="address-banner-btn-parent">
                                                                    <button className="add-last-screen">Save Changes</button>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="five" role="tabpanel" aria-labelledby="five-tab">
                                    <div className="parent-profile-form">
                                        <div className="profile-box-parent set-payment-box">
                                            {addpaymentdetail === "" ?
                                                <div className="parent-of-payment">
                                                    <div className="payment-text-flex-parent">
                                                        <div className="payment-text-img-flex">
                                                            <img src={SellerIcnMaterialPayment} alt="" />
                                                            <h2>Payment</h2>
                                                        </div>
                                                        <div className="payment-fi-btn show">
                                                            <button className="add-payment-screen" onClick={() => setAddPaymentDetail('payment-next-text-flex-parent')}>Add Payment Method</button>
                                                        </div>
                                                    </div>
                                                </div> : addpaymentdetail === "payment-next-text-flex-parent" ?
                                                    <div className="parent-next-payment">
                                                        <div className="check payment-next-text-flex-parent">
                                                            <div className="payment-next-img-text-flex">
                                                                <h2>Payment via Stripe</h2>
                                                            </div>
                                                            <div className="payment-next-btn">
                                                                <button>Add Payment Method</button>
                                                            </div>
                                                        </div>
                                                        <form action="">
                                                            <div className="parent-payment-form">
                                                                <div className="parent-labels-flex">
                                                                    <div className="parent-span-flex">
                                                                        <label for="">Credit Card </label>
                                                                        <span>*</span>
                                                                    </div>
                                                                    <div className="parent-span-flex">
                                                                        <label for="">Expiry</label>
                                                                        <span>*</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row payment-row-inputs">
                                                                    <div className="col-lg-6 parent-payment-input">
                                                                        <input type="number" placeholder="Card Number" />
                                                                        <img src={SellerIcnAwesomeCreditCard} alt="" />
                                                                    </div>
                                                                    <div className="col-lg-2 second-payment-input">
                                                                        <input type="text" placeholder="MM/YY" />
                                                                    </div>
                                                                    <div className="col-lg-2 second-payment-input">
                                                                        <input type="text" placeholder="CVC" />
                                                                    </div>
                                                                </div>
                                                                <div className="third-payment-input">
                                                                    <input type="card" placeholder="Card Holder Name" />
                                                                </div>
                                                            </div>
                                                            <div className="payment-form-btn">
                                                                <button>Save Changes</button>
                                                            </div>
                                                        </form>
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="seven" role="tabpanel" aria-labelledby="seven-tab">
                                    <div className="parent-profile-form">
                                        <div className="profile-box-parent">

                                            <div className="parent-auction">
                                                <div className="parent-auction-text-flex">
                                                    <img src={SellerIcnMetroHammer} alt="" />
                                                    <h2>Auction</h2>
                                                </div>
                                                {addauctiontable === "" ?
                                                    <div className="parent-auction-table">
                                                        <div className="box-table-start">
                                                            <div className="auction-seller-tble d-flex w-100">
                                                                <div style={{ width: "16%" }} className="auction-seller-tble-inner">
                                                                    <h3>Image</h3>
                                                                </div>
                                                                <div style={{ width: "16%" }} className="auction-seller-tble-inner">
                                                                    <h3>Products</h3>
                                                                </div>
                                                                <div style={{ width: "25%" }} className="auction-seller-tble-inner">
                                                                    <h3>Starting Amount</h3>
                                                                </div>
                                                                <div style={{ width: "25%" }} className="auction-seller-tble-inner">
                                                                    <h3>Remaining Time</h3>
                                                                </div>
                                                                <div style={{ width: "16.6%" }} className="auction-seller-tble-inner">
                                                                    <h3>Status</h3>
                                                                </div>
                                                            </div>
                                                            <div className="auction-seller-tble d-flex w-100">
                                                                <div style={{ width: "16%" }}
                                                                    className="auction-seller-tble-inner next-row-none text-align-start set-responsive-img">
                                                                    <img onClick={() => setAddAuctionTable('seller-next-screen-table-parent')} className="seller-next-screen" src={SellerGroup191}
                                                                        alt="" />
                                                                </div>
                                                                <div style={{ width: "16%" }}
                                                                    className="auction-seller-tble-inner next-row-none set-the-content">
                                                                    <h3>VIC XX3</h3>
                                                                </div>
                                                                <div style={{ width: "25%" }}
                                                                    className="auction-seller-tble-inner next-row-none set-this-content">
                                                                    <h3>$5,500.00</h3>
                                                                </div>
                                                                <div style={{ width: "25%" }}
                                                                    className="auction-seller-tble-inner next-row-none set-this-content">
                                                                    <h3>00 : 15 : 59</h3>
                                                                </div>
                                                                <div style={{ width: "16.6%" }}
                                                                    className="auction-seller-tble-inner next-row-none set-this-content">
                                                                    <h3>Open</h3>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div> : addauctiontable === "seller-next-screen-table-parent" ?
                                                        <div className="seller-next-screen-table-parent">
                                                            <div className="next-seller-screen-para">
                                                                <p>Victorian Signature plate VIC XX3</p>
                                                            </div>
                                                            <div className="seller-next-table">
                                                                <div className="auction-seller-next-tble d-flex w-100">
                                                                    <div style={{ width: "30%" }} className="auction-seller-tble-next text-center">
                                                                        <h3>Bidder Name</h3>
                                                                    </div>
                                                                    <div style={{ width: "45%" }} className="auction-seller-tble-next text-center">
                                                                        <h3>Bidding TIme</h3>
                                                                    </div>
                                                                    <div style={{ width: "25%" }} className="auction-seller-tble-next text-center">
                                                                        <h3>Bidding Amount</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="seller-next-table">
                                                                <div className="auction-seller-next-tble d-flex w-100">
                                                                    <div style={{ width: "30%" }} className="auction-seller-tble-next-line text-center">
                                                                        <h3>Lorem Ipsum</h3>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}
                                                                        className="auction-seller-tble-next-line text-align-start">
                                                                        <h3>08 April 2023, 08:00 PM</h3>
                                                                    </div>
                                                                    <div style={{ width: "25%" }} className="auction-seller-tble-next-line text-center">
                                                                        <h3>$6,800.00</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="seller-next-table">
                                                                <div className="auction-seller-next-tble d-flex w-100">
                                                                    <div style={{ width: "30%" }}
                                                                        className="auction-seller-tble-next-line text-center background-color-sett-offer">
                                                                        <h3>Lorem Ipsum</h3>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}
                                                                        className="auction-seller-tble-next-line text-align-start background-color-sett-offer">
                                                                        <h3>08 April 2023, 08:00 PM</h3>
                                                                    </div>
                                                                    <div style={{ width: "25%" }}
                                                                        className="auction-seller-tble-next-line text-center background-color-sett-offer">
                                                                        <h3>$6,800.00</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="seller-next-table">
                                                                <div className="auction-seller-next-tble d-flex w-100">
                                                                    <div style={{ width: "30%" }} className="auction-seller-tble-next-line text-center">
                                                                        <h3>Lorem Ipsum</h3>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}
                                                                        className="auction-seller-tble-next-line text-align-start">
                                                                        <h3>08 April 2023, 08:00 PM</h3>
                                                                    </div>
                                                                    <div style={{ width: "25%" }} className="auction-seller-tble-next-line text-center">
                                                                        <h3>$6,800.00</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="seller-next-table">
                                                                <div className="auction-seller-next-tble d-flex w-100">
                                                                    <div style={{ width: "30%" }}
                                                                        className="auction-seller-tble-next-line text-center background-color-sett-offer">
                                                                        <h3>Lorem Ipsum</h3>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}
                                                                        className="auction-seller-tble-next-line text-align-start background-color-sett-offer">
                                                                        <h3>08 April 2023, 08:00 PM</h3>
                                                                    </div>
                                                                    <div style={{ width: "25%" }}
                                                                        className="auction-seller-tble-next-line text-center background-color-sett-offer">
                                                                        <h3>$6,800.00</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="seller-next-table">
                                                                <div className="auction-seller-next-tble d-flex w-100">
                                                                    <div style={{ width: "30%" }} className="auction-seller-tble-next-line text-center">
                                                                        <h3>Lorem Ipsum</h3>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}
                                                                        className="auction-seller-tble-next-line text-align-start">
                                                                        <h3>08 April 2023, 08:00 PM</h3>
                                                                    </div>
                                                                    <div style={{ width: "25%" }} className="auction-seller-tble-next-line text-center">
                                                                        <h3>$6,800.00</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="seller-next-table">
                                                                <div className="auction-seller-next-tble d-flex w-100">
                                                                    <div style={{ width: "30%" }}
                                                                        className="auction-seller-tble-next-line text-center background-color-sett-offer">
                                                                        <h3>Lorem Ipsum</h3>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}
                                                                        className="auction-seller-tble-next-line text-align-start background-color-sett-offer">
                                                                        <h3>08 April 2023, 08:00 PM</h3>
                                                                    </div>
                                                                    <div style={{ width: "25%" }}
                                                                        className="auction-seller-tble-next-line text-center background-color-sett-offer">
                                                                        <h3>$6,800.00</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="eight" role="tabpanel" aria-labelledby="eight-tab">
                                    <div className="parent-profile-form">
                                        <div className="profile-box-parent">
                                            <div className="parent-offer">
                                                <div className="parent-auction-text-flex">
                                                    <img src={SellerIcnMaterialLocalOffer} alt="" />
                                                    <h2>OFFER</h2>
                                                </div>
                                                {addoffertable === "" ?
                                                <div className="parent-offer-table">
                                                    <div className="box-table-start">
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "28%" }} className="offer-seller-tble-inner text-center">
                                                                <h3>Product Name</h3>
                                                            </div>
                                                            <div style={{ width: "45%" }} className="offer-seller-tble-inner set-width">
                                                                <h3>Description</h3>
                                                            </div>
                                                            <div style={{ width: "17%" }} className="offer-seller-tble-inner text-center">
                                                                <h3>Asking Price</h3>
                                                            </div>
                                                            <div style={{ width: "10%" }}
                                                                className="offer-seller-tble-inner text-center set-offer-width">
                                                                <h3>Offers</h3>
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "28%" }}
                                                                className="offer-seller-tble-next-line set-offer-plate-num">
                                                                <h3>VIC XX - 3</h3>
                                                            </div>
                                                            <div style={{ width: "45%" }}
                                                                className="offer-seller-tble-next-line set-offer-para set-width">
                                                                <h3>Lorem ipsum dolor sit amet, consetetur</h3>
                                                            </div>
                                                            <div style={{ width: "17%" }}
                                                                className="offer-seller-tble-next-line text-center set-offer-text">
                                                                <h3>-</h3>
                                                            </div>
                                                            <div style={{ width: "10%" }}
                                                                className="offer-seller-tble-next-line set-offer-img text-center set-offer-width">
                                                                <img onClick={() => {
                                                                setAddOfferTable("offer-next-screen-parent")
                                                                setAddOfferTableSecond('third-table-add-parent')
                                                            }} className="seller-next-offer-screen" src={SellerIcGroup24}
                                                                    alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "28%" }}
                                                                className="offer-seller-tble-next-line set-offer-plate-num back-color-sett-offfer-change">
                                                                <h3>VIC XX - 3</h3>
                                                            </div>
                                                            <div style={{ width: "45%" }}
                                                                className="offer-seller-tble-next-line set-offer-para back-color-sett-offfer-change set-width">
                                                                <h3>Lorem ipsum dolor sit amet, consetetur</h3>
                                                            </div>
                                                            <div style={{ width: "17%" }}
                                                                className="offer-seller-tble-next-line text-center set-offer-text back-color-sett-offfer-change">
                                                                <h3>-</h3>
                                                            </div>
                                                            <div style={{ width: "10%" }}
                                                                className="offer-seller-tble-next-line set-offer-img text-center back-color-sett-offfer-change set-offer-width">
                                                                <img onClick={() => {
                                                                setAddOfferTable("offer-next-screen-parent")
                                                                setAddOfferTableSecond('third-table-add-parent')
                                                            }} className="seller-next-offer-screen" src={SellerIcGroup24}
                                                                    alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "28%" }}
                                                                className="offer-seller-tble-next-line set-offer-plate-num">
                                                                <h3>VIC XX - 3</h3>
                                                            </div>
                                                            <div style={{ width: "45%" }}
                                                                className="offer-seller-tble-next-line set-offer-para set-width">
                                                                <h3>Lorem ipsum dolor sit amet, consetetur</h3>
                                                            </div>
                                                            <div style={{ width: "17%" }}
                                                                className="offer-seller-tble-next-line text-center set-offer-text">
                                                                <h3>-</h3>
                                                            </div>
                                                            <div style={{ width: "10%" }}
                                                                className="offer-seller-tble-next-line set-offer-img text-center set-offer-width">
                                                                <img onClick={() => {
                                                                setAddOfferTable("offer-next-screen-parent")
                                                                setAddOfferTableSecond('third-table-add-parent')
                                                            }} className="seller-next-offer-screen" src={SellerIcGroup24}
                                                                    alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "28%" }}
                                                                className="offer-seller-tble-next-line set-offer-plate-num back-color-sett-offfer-change">
                                                                <h3>VIC XX - 3</h3>
                                                            </div>
                                                            <div style={{ width: "45%" }}
                                                                className="offer-seller-tble-next-line set-offer-para back-color-sett-offfer-change set-width">
                                                                <h3>Lorem ipsum dolor sit amet, consetetur</h3>
                                                            </div>
                                                            <div style={{ width: "17%" }}
                                                                className="offer-seller-tble-next-line text-center set-offer-text back-color-sett-offfer-change">
                                                                <h3>-</h3>
                                                            </div>
                                                            <div style={{ width: "10%" }}
                                                                className="offer-seller-tble-next-line set-offer-img text-center back-color-sett-offfer-change set-offer-width">
                                                                <img onClick={() => {
                                                                setAddOfferTable("offer-next-screen-parent")
                                                                setAddOfferTableSecond('third-table-add-parent')
                                                            }} className="seller-next-offer-screen" src={SellerIcGroup24}
                                                                    alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "28%" }}
                                                                className="offer-seller-tble-next-line set-offer-plate-num">
                                                                <h3>VIC XX - 3</h3>
                                                            </div>
                                                            <div style={{ width: "45%" }}
                                                                className="offer-seller-tble-next-line set-offer-para set-width">
                                                                <h3>Lorem ipsum dolor sit amet, consetetur</h3>
                                                            </div>
                                                            <div style={{ width: "17%" }}
                                                                className="offer-seller-tble-next-line text-center set-offer-text">
                                                                <h3>-</h3>
                                                            </div>
                                                            <div style={{ width: "10%" }}
                                                                className="offer-seller-tble-next-line set-offer-img text-center set-offer-width">
                                                                <img onClick={() => {
                                                                setAddOfferTable("offer-next-screen-parent")
                                                                setAddOfferTableSecond('third-table-add-parent')
                                                            }} className="seller-next-offer-screen" src={SellerIcGroup24}
                                                                    alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "28%" }}
                                                                className="offer-seller-tble-next-line set-offer-plate-num back-color-sett-offfer-change">
                                                                <h3>VIC XX - 3</h3>
                                                            </div>
                                                            <div style={{ width: "45%" }}
                                                                className="offer-seller-tble-next-line set-offer-para back-color-sett-offfer-change set-width">
                                                                <h3>Lorem ipsum dolor sit amet, consetetur</h3>
                                                            </div>
                                                            <div style={{ width: "17%" }}
                                                                className="offer-seller-tble-next-line text-center set-offer-text back-color-sett-offfer-change">
                                                                <h3>-</h3>
                                                            </div>
                                                            <div style={{ width: "10%" }}
                                                                className="offer-seller-tble-next-line set-offer-img text-center back-color-sett-offfer-change set-offer-width">
                                                                <img onClick={() => {
                                                                setAddOfferTable("offer-next-screen-parent")
                                                                setAddOfferTableSecond('third-table-add-parent')
                                                            }} className="seller-next-offer-screen" src={SellerIcGroup24}
                                                                    alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : addoffertable === "offer-next-screen-parent" ?
                                                <div className="offer-next-screen-parent">
                                                    <div className="offer-next-table-para">
                                                        <p>Victorian Signature plate VIC XX3</p>
                                                    </div>
                                                    <div className="offer-next-table-parent">
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "25%" }} className="offer-seller-tble-inner text-center">
                                                                <h3>Name</h3>
                                                            </div>
                                                            <div style={{ width: "18%" }} className="offer-seller-tble-inner">
                                                                <h3>BUYER OFFER</h3>
                                                            </div>
                                                            <div style={{ width: "22%" }} className="offer-seller-tble-inner text-center">
                                                                <h3>COUNTER OFFER</h3>
                                                            </div>
                                                            <div style={{ width: "35%" }} className="offer-seller-tble-inner text-center">
                                                                <h3></h3>
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "25%" }} className="offer-seller-tble-next-page text-center">
                                                                <h3>Lorem Ipsum</h3>
                                                            </div>
                                                            <div style={{ width: "18%" }} className="offer-seller-tble-next-page text-center">
                                                                <h3>$6,500.00</h3>
                                                            </div>
                                                            <div style={{ width: "22%" }} className="offer-seller-tble-next-page text-center">
                                                                <h3>$6,500.00</h3>
                                                            </div>
                                                            <div style={{ width: "35%" }}
                                                                className="offer-seller-tble-next-page next-page-offer-btn text-center">
                                                                <button
                                                                    className="offer-table-accept move-to-main-screen-offer">Accept</button>
                                                                <button
                                                                    className="offer-table-decline move-to-main-screen-offer">Decline</button>
                                                                <button onClick={() => {
                                                                setAddOfferTable('third-table-add-parent')
                                                            }}
                                                                    className="offer-table-counter add-more-offer-table">Counter</button>
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "25%" }}
                                                                className="offer-seller-tble-next-page text-center second-tabel-background-change-offer">
                                                                <h3>Lorem Ipsum</h3>
                                                            </div>
                                                            <div style={{ width: "18%" }}
                                                                className="offer-seller-tble-next-page text-center second-tabel-background-change-offer">
                                                                <h3>$6,500.00</h3>
                                                            </div>
                                                            <div style={{ width: "22%" }}
                                                                className="offer-seller-tble-next-page text-center second-tabel-background-change-offer">
                                                                <h3>$6,500.00</h3>
                                                            </div>
                                                            <div style={{ width: "35%" }}
                                                                className="offer-seller-tble-next-page next-page-offer-btn text-center second-tabel-background-change-offer">
                                                                <button
                                                                    className="offer-table-accept move-to-main-screen-offer">Accept</button>
                                                                <button
                                                                    className="offer-table-decline move-to-main-screen-offer">Decline</button>
                                                                <button onClick={() => {
                                                                setAddOfferTable('third-table-add-parent')
                                                            }}
                                                                    className="offer-table-counter add-more-offer-table">Counter</button>
                                                            </div>
                                                        </div>
                                                        <div className="offer-seller-tble d-flex w-100">
                                                            <div style={{ width: "25%" }} className="offer-seller-tble-next-page text-center">
                                                                <h3>Lorem Ipsum</h3>
                                                            </div>
                                                            <div style={{ width: "18%" }} className="offer-seller-tble-next-page text-center">
                                                                <h3>$6,500.00</h3>
                                                            </div>
                                                            <div style={{ width: "22%" }} className="offer-seller-tble-next-page text-center">
                                                                <h3>$6,500.00</h3>
                                                            </div>
                                                            <div style={{ width: "35%" }}
                                                                className="offer-seller-tble-next-page next-page-offer-btn text-center">
                                                                <button
                                                                    className="offer-table-accept move-to-main-screen-offer">Accept</button>
                                                                <button
                                                                    className="offer-table-decline move-to-main-screen-offer">Decline</button>
                                                                <button onClick={() => {
                                                                setAddOfferTable('third-table-add-parent')
                                                            }}
                                                                    className="offer-table-counter add-more-offer-table">Counter</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : addoffertable === "third-table-add-parent" ?
                                                <div className="third-table-add-parent">
                                                    <div className="third-screen-parent">
                                                        <div className="third-screen-form">
                                                            <label for="">Amount</label>
                                                        </div>
                                                        <div className="third-screen-input-para">
                                                            <p>$</p>
                                                            <input type="amount" />
                                                        </div>
                                                        <div className="thired-screen-offer-form-btn">
                                                            <button className="move-to-offer-main-screen">Submit</button>
                                                        </div>
                                                    </div>
                                                </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default SellerDashBoard