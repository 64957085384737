import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/slices/userSlice';
import { logoutWishlist } from '../redux/slices/wishlistSlice';


const GET_USERS_QUERY = gql`

  query user($id: Int!){
    user(where: { ID: 2}) {
      id
    }
  }

`;




const extractIdFromToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const sub = decodedToken.sub;
    // console.log(sub,"----------token getting")
    return sub;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

const token = localStorage.getItem("token");
const sub = extractIdFromToken(token);


const LogoutButton = () => {

  const dispatch = useDispatch()
  const userData = useSelector(state => state.user.userData)

  // const { id } = useParams();


  // const { loading, error, data } = useQuery(GET_USERS_QUERY, { 

  //   variables: { id: parseInt(id) },
  // });
  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  // const ID = data?.id;


  const ID = userData.userID;

  const handleLogout = () => {
    dispatch(logoutUser())
    dispatch(logoutWishlist())
  };


  return (
    <>
      {/* <div className='parent-logout-btn'>
        <div className='set-log-head-btn'>
          <Link to='/'>My Account</Link>
        </div>
        <div className='my-account-dashboard-btns'>
          <Link to='/'>Dashboard</Link>
          <Link onClick={handleLogout}>Logout</Link>
        </div>
      </div> */}
      <div class="dropdown set-head-toggle">
        <button class="btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          My Account
        </button>
        <ul class="dropdown-menu sett-back-color-item" aria-labelledby="dropdownMenuButton1">
          <li><Link class="dropdown-item"  to={`/UserSellerDashboard`}>Dashboard</Link></li>
          <li><Link onClick={handleLogout} class="dropdown-item" to="/">Logout</Link></li>
        </ul>
      </div>
    </>
  );
};

export default LogoutButton;
