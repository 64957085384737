import React from 'react'
import { useState } from 'react';
import Sidebar from '../../../components/Sidebar'

export default function EditProfile() {



    const [password, setPassword] = useState('');
    const generatePassword = () => {
        const length = 16; // Password length
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()'; // Characters to include in the password
        let newPassword = '';
    
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          newPassword += charset.charAt(randomIndex);
        }
    
        setPassword(newPassword);
      };

      const copyPassword = () => {
        navigator.clipboard.writeText(password);
      };
    


  return (
<div class="bg-dashboard">
    <div id="main">

        {/* <?php include 'includes/sidebar.php';?> */}
        <Sidebar/>
        
        <div class="dashboard-right-side payment-parent">
            <div class="page-title">
                <h1>EDIT PROFILE</h1>
            </div>
            <div class="modal-form-request-parent">
                <div style={{boxShadow: "none"}} class="modal-form-request-inner">
                    <div class="modal-form-heading">
                        <h1>User Details</h1>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="modal-input-form-req py-2">
                                <label for="modalinp1">Username
                                    <input type="text" placeholder="Steve123"/>
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                        <div class="modal-input-form-req py-2">
                                <label for="modalinp1">Email
                                    <input type="email" placeholder="smith.steve@outlook.com"/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="modal-input-form-req py-2">
                                <label for="modalinp1">First Name
                                    <input type="text" placeholder="Steve"/>
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                        <div class="modal-input-form-req py-2">
                                <label for="modalinp1">Last Name
                                    <input type="email" placeholder="Smith"/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-form-heading mt-3">
                        <h1>Password</h1>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="modal-input-form-req py-2">
                                <label for="modalinp1">New Password
                                    <input type="password"  />
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                        <div class="modal-input-form-req py-2">
                                <label for="modalinp1">Confirm Password
                                    <input type="password"/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <div class="gen-pass-btn">      <button onClick={generatePassword}>Generate Password</button>
                            
                            <p onClick={copyPassword} className="copy-password">Generated Password: {password}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-5 d-flex justify-content-end">
                            <div class="gen-pass-btn"><a href="#">Save Changes</a></div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
  )
}

