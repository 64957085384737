import React, { useState } from 'react'
import UserSidebar from '../../../../components/UserSidebar'
import logo from '../../../../assets/images/logoas.png'
import Img1 from "../../../../assets/images/saller1.png"
import Img2 from "../../../../assets/images/saller2.png"
import Img3 from "../../../../assets/images/saller3.png"
import Img4 from "../../../../assets/images/saller4.png"
import BuyerChartIconImg from "../../../../assets/images/charticon.png"
import BuyerCartIconImg from "../../../../assets/images/cart.png"
import Switch from "react-switch";
import { ApolloClient, gql, useQuery, useMutation } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'




const GET_USERS_QUERY = gql`

  query user($id: Int!){
    user(where: { id: $id}) {
      id
      username
      email
      firstName
      lastName
      city
      country
      streetAddress
      city
      phoneNumber
      postCode
      state
    }
  }

`;
const GET_USER_ACTIVITIES = gql`
  query {
    me {
      activities {
        id
        createdAt
        mode
        user {
          username
          status
        }
      }
    }
  }
`;






function UnApprovedUser() {
  const [isTwoFactorEnable, setIsTwoFactorEnable] = useState(true);


  // const { id } = useParams();
  const userData = useSelector(state => state.user.userData)
  const id = userData.userID;

  const { loading, error, data } = useQuery(GET_USERS_QUERY, {

    variables: { id: parseInt(id) },
  });

  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_USER_ACTIVITIES);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  if (loading2) return <p>Loading...</p>;
  if (error2) return <p>Error: {error2.message}</p>;




  const user = data?.user;

  const activities = data2?.me?.activities;

  const handleToggle = () => {
    setIsTwoFactorEnable(prev => !prev);
  };
  return (
    <section className="dasgboard-miansection">
      {/* Dashboard */}
      {/* dashboard */}
      <div className="container">
        <div className="row pt-2">
          <UserSidebar />
          <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <div className="sllermain-title">
                <h1>DASHBOARD</h1>
                <div className="sidebar-brand">


                  <div className='set-switch-flex'>
                    {isTwoFactorEnable ? (
                      <h2 id="formButton">
                        Switch to Seller{" "}
                      </h2>
                    ) : (
                      <h2 id="formButton">
                        Switch to Buyer{" "}
                      </h2>
                    )}
                    <Switch
                      className="react-switch user-seller-buyer-dash-set"
                      onChange={handleToggle}
                      checked={isTwoFactorEnable}
                      aria-labelledby="neat-label"
                      uncheckedIcon={
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#fff",
                            fontWeight: "400",
                            padding: "4px 0 0 4px"
                          }}
                        >
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#fff",
                            fontWeight: "400",
                            padding: "4px 0 0 6px"
                          }}
                        >
                        </div>
                      }
                      onColor="#7A724E"
                      offColor="#7A724E"
                      height={35}
                      width={90}
                    />

                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-destailbox">
              <div className="wellcome-message-one">
                <img
                  src={logo}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="wellcome-message-two">
                <p>
                  Hello {user?.username}! <br />
                  Welcome to Signature+
                  <br />
                  From your account dashboard you can view your recent orders,
                  manage your account's setting and billing addresses, and edit your
                  password and account details.
                </p>
                <a
                  // data-bs-toggle="modal"
                  // href="#exampleModalToggle"
                  // role="button"
                  className="reqvalidation"
                >
                  Waiting for Approval
                </a>
              </div>
            </div>

            {/* modal  */}
            <div
              className="modal fade"
              // id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabIndex={-1}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    {/* form  */}
                    <div className="col-sm-12 col-md-12 col-lg-12 latest_order">
                      <div className="order-heading set-ordering">
                        <h1>
                          {" "}
                          <span className="icon-shop">
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                          </span>{" "}
                          Regestration Form{" "}
                        </h1>
                      </div>
                      <div className="formset pr-5">
                        <fieldset className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>First Name</legend>
                            <input
                              className="form-input restrict-numbers"
                              type="text"
                              id="fname"
                              name="fname"
                              value={data.user.firstName}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>Last Name</legend>
                            <input
                              className="form-input restrict-numbers"
                              type="text"
                              id="fname"
                              name="fname"
                              value={data.user.lastName}
                            />
                          </div>
                        </fieldset>
                        <fieldset className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>Phone</legend>
                            <input
                              className="form-input phoneNumber"
                              type="tell"
                              id="fname"
                              name="fname"
                              minLength={11}
                              maxLength={11}
                              size={11}
                              required=""
                              value={data.user.phoneNumber}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>Email:</legend>
                            <input
                              className="form-input"
                              type="email"
                              id="fname"
                              name="fname"
                              value={data.user.email}
                            />
                          </div>
                        </fieldset>
                        <fieldset className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>Street Address</legend>
                            <input
                              className="form-input"
                              type="text"
                              id="fname"
                              name="fname"
                              value={data.user.streetAddress}
                            />
                          </div>
                        </fieldset>
                        <fieldset className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>Post Code</legend>
                            <input
                              className="form-input phoneNumber"
                              type="text"
                              id="fname"
                              name="fname"
                              value={data.user.postCode}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>State</legend>
                            <input
                              className="form-input"
                              type="text"
                              id="fname"
                              name="fname"
                              value={data.user.state}
                            />
                          </div>
                        </fieldset>
                        <fieldset className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>Driver Licence Front</legend>
                            <input type="file" id="myfile" name="myfile" />
                          </div>

                        </fieldset>
                        <fieldset className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                            <legend>Maximum file size: 32 MB</legend>
                            <legend>
                              Please provide a copy of your Driver Licence so that
                              we can verify your identity for our live bidding
                              platform.
                            </legend>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                          </div>
                        </fieldset>
                        {/* <button href="" className="logout">
                          Request Verification
                        </button> */}
                      </div>
                    </div>
                    {/* form */}
                  </div>
                </div>
              </div>
            </div>
            {/* modal */}

            {isTwoFactorEnable ? (
              <div className="row my-4 Latest-Order gx-0">
                <div className="col-8 latest_order">
                  <div className="order-heading set-ordering">
                    <h1>
                      {" "}
                      <span className="icon-shop">
                        {" "}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>{" "} */}
                      </span>{" "}
                      NOTIFICATIONS
                    </h1>
                  </div>
                  <div className="tabmake">
                    <div className="orderhead">
                      {/* <ul className="order-first">
                        <li>Type Of Activity</li>
                        <li>Username</li>
                        <li>User Status</li>
                        <li>Created At</li>
                      </ul> */}
                      {/* <ul className="order-second">
                        <li> 28 Mar 2023 </li>
                        <li> VIC XX-09 </li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "red" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Lost
                        </li>
                        <li> $2500.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li>
                      </ul>
                      <ul className="order-third">
                        <li> 28 Mar 2023 </li>
                        <li> VIC XX-09</li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "#92FF26" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Won
                        </li>
                        <li> $3999.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li>
                      </ul>
                      <ul className="order-four">
                        <li> 28 Mar 2023 </li>
                        <li> VIC XX-09 </li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "red" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Lost
                        </li>
                        <li> $2500.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li>
                      </ul>
                      <ul className="order-five">
                        <li> 28 Mar 2023 </li>
                        <li> VIC XX-09 </li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "red" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Lost
                        </li>
                        <li> $2500.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li>
                      </ul>
                      <ul className="order-six">
                        <li> 28 Mar 2023 </li>
                        <li> VIC XX-09 </li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "red" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Lost
                        </li>
                        <li> $2500.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li>
                      </ul>
                      <ul className="order-seven">
                        <li> 28 Mar 2023 </li>
                        <li> VIC XX-09</li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "#92FF26" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Won
                        </li>
                        <li> $3999.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li>
                      </ul>
                      <ul className="order-eight">
                        <li> 28 Mar 2023 </li>
                        <li> VIC XX-09</li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "#92FF26" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Won
                        </li>
                        <li> $3999.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li>
                      </ul>
                      <ul className="order-nine"> 
                        {/* <li> 28 Mar 2023 </li>
                        <li> VIC XX-09 </li>
                        <li>
                          {" "}
                          <span className="pen-icon" style={{ fill: "red" }}>
                            {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>{" "}
                          </span>{" "}
                          Lost
                        </li>
                        <li> $2500.00</li>
                        <li> $3999.00</li>
                        <li>
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>{" "}
                        </li> 
                       </ul> */}
                    </div>
                  </div>
                </div>

                <div className="col-4 set-user-box ">
                  <div className="order-firstbox sallerfashboart">
                    <div className="bids-images-saller">
                      <img
                        src={Img1}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="bids-text-saller">
                      <h2> Total Bids </h2>
                      <h1 className="my-4">0</h1>
                      <a href="" className="btnorder">
                        View More
                      </a>
                    </div>
                  </div>
                  {/* 2 */}
                  <div className="order-firstbox sallerfashboart">
                    <div className="bids-images-saller">
                      <img
                        src={Img2}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="bids-text-saller">
                      <h2> Bids Won </h2>
                      <h1 className="my-4">0</h1>
                      <a href="" className="btnorder">
                        View More
                      </a>
                    </div>
                  </div>
                  {/* 3 */}
                  <div className="order-firstbox sallerfashboart">
                    <div className="bids-images-saller">
                      <img
                        src={Img3}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="bids-text-saller">
                      <h2> Bids Lost </h2>
                      <h1 className="my-4">0</h1>
                      <a href="" className="btnorder">
                        View More
                      </a>
                    </div>
                  </div>
                  {/* 4 */}
                  <div className="order-firstbox sallerfashboart">
                    <div className="bids-images-saller">
                      <img
                        src={Img4}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="bids-text-saller">
                      <h2> Watchlist </h2>
                      <h1 className="my-4">0</h1>
                      <a href="" className="btnorder">
                        View More
                      </a>
                    </div>
                  </div>
                  {/* 5 */}
                </div>
              </div>
            ) : (
              <div className="row my-4 Latest-Order gx-0">
                <div className="col-8 latest_order">
                  <div className="order-heading set-ordering">
                    <h1>
                      {" "}
                      <span className="icon-shop">
                        {" "}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>{" "} */}
                      </span>{" "}
                      NOTIFICATIONS
                    </h1>
                  </div>
                  <div className="tabmake">
                    <div className="orderhead">
                      {/* <ul className="order-first">
                        <li>Type Of Activity</li>
                        <li>Username</li>
                        <li>User Status</li>
                        <li>Created At</li>
                      </ul> */}


                      {/* <ul className="order-second">
          
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
             
                     </ul> */}



                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="order-firstbox mb-5">
                    <img
                      src={BuyerChartIconImg}
                      alt=""
                      className="img-fluid"
                    />
                    <h2> Total Sales </h2>
                    <h1 className="my-4">0</h1>
                    {/* <Link to="" className="btnorder">
                      View More
                    </Link> */}
                  </div>
                  <div className="order-firstbox">
                    <img
                      src={BuyerCartIconImg}
                      alt=""
                      className="img-fluid"
                    />
                    <h2> No. Plates For Sale </h2>
                    <h1 className="my-4">0</h1>
                    {/* <Link to="" className="btnorder">
                      View More
                    </Link> */}
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </section>



  )
}

export default UnApprovedUser