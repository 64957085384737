import React from 'react'
import Sidebar from '../../../components/Sidebar'
import pdfimg from '../../../assets/images/pdf-icn.png';
import excelimg from '../../../assets/images/excel-icn.png';
import ResponsiveNavBarDasbord from '../../../components/ResponsiveNavBarDasbord';


function Reports() {
  return (
    <>
      <div class="bg-dashboard">
        <main id="main">
        <ResponsiveNavBarDasbord/>
          <Sidebar />
          <div className="dashboard-right-side">
            <div className="page-title">
              <h1>Reports</h1>
            </div>
            <div className="payment-inner-page-set">
              <div className="content-pages-footer-parent">
                <div className="flex-table-report-parent py-2">
                  <div className="flex-table-report">
                    <div style={{ width: "6%" }} className="flex-table-report-inner">
                      <input type="checkbox" />
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="flex-table-report-inner justify-content-start"
                    >
                      <h3>Report Name</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <h3>Date From</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <h3>Date to</h3>
                    </div>
                    <div style={{ width: "20%" }} className="flex-table-report-inner set-color">
                      <h3>
                        Download <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                      </h3>
                    </div>
                  </div>
                  <div className="flex-table-report bg-repo-tble mt-4">
                    <div style={{ width: "6%" }} className="flex-table-report-inner">
                      <input type="checkbox" />
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="flex-table-report-inner justify-content-start"
                    >
                      <h3 className="h3-gery-col-rep">Seller</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>Jan,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>May,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "20%" }} className="flex-table-report-inner">
                      <div className="downl-rep-tble-icn d-flex">
                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                          <img src={pdfimg} alt="" />
                        </div>
                        <div className="downl-rep-tble-icn-inner">
                          <img src={excelimg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-table-report bg-repo-tble mt-4">
                    <div style={{ width: "6%" }} className="flex-table-report-inner">
                      <input type="checkbox" />
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="flex-table-report-inner justify-content-start"
                    >
                      <h3 className="h3-gery-col-rep">Buyers</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>Jan,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>May,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "20%" }} className="flex-table-report-inner">
                      <div className="downl-rep-tble-icn d-flex">
                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                          <img src={pdfimg} alt="" />
                        </div>
                        <div className="downl-rep-tble-icn-inner">
                          <img src={excelimg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-table-report bg-repo-tble mt-4">
                    <div style={{ width: "6%" }} className="flex-table-report-inner">
                      <input type="checkbox" />
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="flex-table-report-inner justify-content-start"
                    >
                      <h3 className="h3-gery-col-rep">All Customers</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>Jan,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>May,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "20%" }} className="flex-table-report-inner">
                      <div className="downl-rep-tble-icn d-flex">
                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                          <img src={pdfimg} alt="" />
                        </div>
                        <div className="downl-rep-tble-icn-inner">
                          <img src={excelimg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-table-report bg-repo-tble mt-4">
                    <div style={{ width: "6%" }} className="flex-table-report-inner">
                      <input type="checkbox" />
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="flex-table-report-inner justify-content-start"
                    >
                      <h3 className="h3-gery-col-rep">Auction Sales</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>Jan,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>May,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "20%" }} className="flex-table-report-inner">
                      <div className="downl-rep-tble-icn d-flex">
                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                          <img src={pdfimg} alt="" />
                        </div>
                        <div className="downl-rep-tble-icn-inner">
                          <img src={excelimg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-table-report bg-repo-tble mt-4">
                    <div style={{ width: "6%" }} className="flex-table-report-inner">
                      <input type="checkbox" />
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="flex-table-report-inner justify-content-start"
                    >
                      <h3 className="h3-gery-col-rep">Listing Sales</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>Jan,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>May,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "20%" }} className="flex-table-report-inner">
                      <div className="downl-rep-tble-icn d-flex">
                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                          <img src={pdfimg} alt="" />
                        </div>
                        <div className="downl-rep-tble-icn-inner">
                          <img src={excelimg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-table-report bg-repo-tble mt-4">
                    <div style={{ width: "6%" }} className="flex-table-report-inner">
                      <input type="checkbox" />
                    </div>
                    <div
                      style={{ width: "40%" }}
                      className="flex-table-report-inner justify-content-start"
                    >
                      <h3 className="h3-gery-col-rep">Total Sales</h3>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>Jan,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "22%" }} className="flex-table-report-inner">
                      <div className="wrap-rep-date-inner">
                        <span>
                          <img src="assets/images/tble-cal-icn.png" alt="" />
                        </span>
                        <span>May,2023</span>
                      </div>
                    </div>
                    <div style={{ width: "20%" }} className="flex-table-report-inner">
                      <div className="downl-rep-tble-icn d-flex">
                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                          <img src={pdfimg} alt="" />
                        </div>
                        <div className="downl-rep-tble-icn-inner">
                          <img src={excelimg} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Reports