import React from 'react'
import logo from '../assets/images/logoas.png'
import { Link } from 'react-router-dom'
function ResponsiveNavBarDasbord() {
  return (
    <>
    <div className='ResNavbar'>
<nav className="navbar bg-light fixed-top">
  <div className="container-fluid">
    <a className="navbar-brand" href="#">
      <img src={logo} className='logoNav'></img>
    </a>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasNavbar"
      aria-controls="offcanvasNavbar"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
          <img src={logo}/>
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/MainDashboard">
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/FormRequest">
              Form Request
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/customer">
              Customer
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Plates_recieved">
            Plate Received
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/BidsReceived">
            Bids Received
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/BidsAct">
              Bids Activity Log
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Payment">
              Payments
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Reports">
              Reports
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Analytic">
              Analytic
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Winner_list">
              Winner List
            </Link>
          </li>
            <button className='log-out'>Logout</button>
        </ul>
       
      </div>
    </div>
  </div>
</nav>


    </div>
    </>
  )
}

export default ResponsiveNavBarDasbord