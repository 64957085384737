import axios from "axios";
import { baseUrl } from "../utils/Function";

const baseUrls = {
    api: `${baseUrl}graphql`,
}

export const PostReq = (data, headers = {}) => {
    return axios.post(`${baseUrls.api}`, data, headers);
};
