import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
import '../../../../../node_modules/bootstrap/js/dist/dropdown'

function Offertwo() {
  return (
<section className="dasgboard-miansection hundred-payment pb-5">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="container pb-5">
    <div className="row pt-2">
    <UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <div className="row">
            <div className="sllermain-title">
              <h1>Offer</h1>
              <div className="sidebar-brand">
                {/*<h2>Switch to Seller*/}
                {/*    <label class="switch">*/}
                {/*        <input type="checkbox" id="formButton" onclick="location.href='./saller/offerfour.html';">*/}
                {/*        <span class="slider round"></span>*/}
                {/*    </label>*/}
                {/*</h2>*/}
                <h2
                  id="formButton"
                  onclick="location.href='./saller/offerfour.html';"
                >
                  Switch to Seller{" "}
                  <i className="fa fa-refresh" aria-hidden="true" />
                  {/*  <label class="switch">*/}
                  {/*    <input type="checkbox" id="formButton" onclick="location.href='index.html';">*/}
                  {/*    <span class="slider round"></span>*/}
                  {/*</label>  */}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-5 my-4 Latest-Order gx-0">
          <div className="col-sm-12 col-md-12 col-lg-12 latest_order">
            <div className="order-heading">
              <div className="row payment-heading">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <h1>
                    {" "}
                    <span className="icon-shop">
                      {" "}
                      <i className="fa-solid fa-location-arrow" />{" "}
                    </span>
                    Offer
                  </h1>
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="row auctioncard auctionthree auctionfour ">
                    <div className="card offertwomain col-4">
                      <div className="card-body offertwo">
                        <div className="title-card-auction">
                          <h5 className="card-title">VIC XX - 3</h5>
                        </div>
                        <br />
                        <h1 style={{ textAlign: "center" }}>Counter Offer</h1>
                        <br />
                        <div className="title-card-auction">
                          <h5 className="card-title">$6,400.00</h5>
                        </div>
                      </div>
                      <div className="card-body status-auction offer">
                        <a
                          href="./offerthree"
                          className="offer"
                          style={{ background: "#3C720D" }}
                        >
                          Accept
                        </a>
                        <a
                          href="./offerone.html"
                          className="offer"
                          style={{ background: "#BF2D2D" }}
                        >
                          {" "}
                          Decline{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Offertwo