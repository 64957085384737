import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import row1 from "../../../assets/images/row-icn1.png"
import row2 from "../../../assets/images/row-icn2.png"
import row3 from "../../../assets/images/row-icn3.png"
import dashMain from "../../../assets/images/dash-main-user-icn.png"
import shopIcon from "../../../assets/images/shop-icn.png"
import graph3 from "../../../assets/images/graph-3.png"
import prStats from "../../../assets/images/pr-stats-icn.png"
import lastOrdr from "../../../assets/images/lat-ordr-icn.png"
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as cityCountryAct from '../../../store/action/cityCountryAct'
import orderCustomer from "../../../assets/images/order-customer-box-check.png";
import { UserData } from "../../Dasboard/Data";
import platesinqueue from "../../../assets/images/platesinqueue.png"
import sales from "../../../assets/images/sales (1).png"
import order from "../../../assets/images/order.png"
import noofplates from '../../../assets/images/noofplates.png'


import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import ResponsiveNavBarDasbord from '../../../components/ResponsiveNavBarDasbord'

import { useQuery, gql } from '@apollo/client';



const GET_MAIN_ACTIVITY = gql`
  query {
    mainActivity {
      totalAskingPriceProducts
      totalAuctionProducts
      totalOfferConsiderProducts
      totalUsers
    }
  }
`;


const GET_USERS = gql`
  query {
    users(where: { status: APPROVED }) {
      id
    }
  }
`;


function DashboardMain({ get_countries }) {

    const [userData, setUserData] = useState({
        labels: UserData.map((data) => data.year),
        datasets: [
            {
                data: UserData.map((data) => data.userGain),
                backgroundColor: ["#7A724E", "#DFD5AA"],
                width: "20px",
            },
        ],
    });


    const { loading, error, data: datan } = useQuery(GET_MAIN_ACTIVITY);

    if (loading) return(
        <div className="bg-dashboard">
    <main id="main">
    <ResponsiveNavBarDasbord/>
      <Sidebar />
      <div className="dashboard-right-side">
            <div className="form-req-tble-parent">
            <div className="payment-inner-page-set">
              <div style={{backgroundColor: "#FFFBF0"}} className="form-req-tble-inner d-flex">
            <h1 style={{color: "#6D6D6D"}}>Loading....</h1>
            </div>
            </div>
          </div>
          </div>
        </main>
        </div>
    );
    if (error) return <p>Error: {error.message}</p>;



    return (
        <div class="bg-dashboard">
            <div id="main">
                <Sidebar />
                <ResponsiveNavBarDasbord />
                <div class="dashboard-right-side dashboard-main-right-side-sett">
                    <div class="page-title dashbard-main-head-sett">
                        <h1>ADMIN DASHBOARD</h1>
                    </div>
                    <div class="admin-dashboard-page-parent">
                        <div class="container admin-container">
                            <div class="top-rectangle-parent">
                                <div class="top-rectangle-inner">
                                    <div class="top-rectangle-inner-content my-4">
                                        <h1>Hi <span>Admin !</span></h1>
                                        <h2>Welcome to</h2>
                                        <h3>Signatures +</h3>
                                        <h4>Admin Dashboard</h4>
                                        <h5>Victoria's Boutique Signature<br />
                                            Market Place</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="row set-responsive">
                                <div class="col-lg-6">
                                    <div class="col-first-main-page-parent">
                                        <div class="col-first-box-parent d-flex">
                                            <div class="left-box-col-firs">
                                                <div class="icn-left-box-icn-main">
                                                    <img src={dashMain} alt="" />
                                                    <h3>Total Users</h3>
                                                    <h4>{datan.mainActivity.totalUsers}</h4>
                                                </div>
                                            </div>
                                            <div class="row-first-white-box-parent">
                                                <div
                                                    class="row-first-white-box d-flex justify-content-between align-items-center">
                                                    <div
                                                        class="row-first-white-box-inner d-flex flex-column align-items-center">
                                                        <img src={row1} alt="" />
                                                        <h3 class="text-center">Total Asking <br />
                                                            Price Products</h3>
                                                        <h4>{datan.mainActivity.totalAskingPriceProducts}</h4>
                                                    </div>
                                                    <div
                                                        class="row-first-white-box-inner d-flex flex-column align-items-center pt-2">
                                                        <img src={row2} alt="" />
                                                        <h3 class="text-center">Total Offer <br />
                                                            Considered Products</h3>
                                                        <h4>{datan.mainActivity.totalOfferConsiderProducts}</h4>
                                                    </div>
                                                    <div
                                                        class="row-first-white-box-inner d-flex flex-column align-items-center pt-2">
                                                        <img src={row3} alt="" />
                                                        <h3 class="text-center">Total Products <br />
                                                            for Auction</h3>
                                                        <h4>{datan.mainActivity.totalAuctionProducts}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="right-box-first-row-main-parent">
                                        <div class="right-box-first-row">
                                            <div class="heading-right-box-first-row-main">
                                                <h3><span class="me-3"><img src={shopIcon}
                                                    alt="" /></span>Seller Activity</h3>
                                            </div>
                                            {/* <div class="right-box-first-row-content">
                                                <div class="inner-right-box-main-content d-flex"><img
                                                    src={sales} alt="" /></div>
                                            </div> */}
                                            <div className='row-first-white-box d-flex justify-content-between align-items-center pt-0'>
                                            <div class="row-first-white-box-inner d-flex flex-column align-items-center pt-2">
                                                <img src={sales} alt="" width="70"/>
                                                    <h3 class="text-center">Total Sales</h3>
                                                    <h4>8.4k</h4>
                                            </div>
                                            <div class="row-first-white-box-inner d-flex flex-column align-items-center pt-2">
                                                <img src={order} alt="" width="91"/>
                                                    <h3 class="text-center">Total Orders</h3>
                                                    <h4>80</h4>
                                            </div>
                                            <div class="row-first-white-box-inner d-flex flex-column align-items-center pt-2">
                                                <img src={platesinqueue} alt="" width="90"/>
                                                    <h3 class="text-center">No. of Plates</h3>
                                                    <h4>10</h4>
                                            </div>
                                            <div class="row-first-white-box-inner d-flex flex-column align-items-center pt-2">
                                                <img src={noofplates} alt="" width="90"/>
                                                    <h3 class="text-center">No. of Plates<br/>for Auction & Listing</h3>
                                                    <h4>10</h4>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4 set-responsive">
                                <div class="col-lg-6">
                                    <div class="right-box-first-row-main-parent">
                                        <div class="right-box-first-row">
                                            <div style={{ paddingLeft: "0", paddingRight: "0" }}
                                                class="heading-right-box-first-row-main">
                                                <h3><span class="me-3"><img src={prStats}
                                                    alt="" /></span>Products Stats</h3>
                                            </div>
                                            <div class="tabs-main-page-parent">
                                                <div class="tabs-main-page">
                                                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                        <li class="nav-item" role="presentation">
                                                            <button style={{ marginLeft: "0" }} class="nav-link active"
                                                                id="pills-home-tab" data-bs-toggle="pill"
                                                                data-bs-target="#pills-home" type="button" role="tab"
                                                                aria-controls="pills-home" aria-selected="true">Auction</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link" id="pills-profile-tab"
                                                                data-bs-toggle="pill" data-bs-target="#pills-profile"
                                                                type="button" role="tab" aria-controls="pills-profile"
                                                                aria-selected="false">Listing</button>
                                                        </li>

                                                    </ul>
                                                    <div class="tab-content" id="pills-tabContent">
                                                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                            aria-labelledby="pills-home-tab">
                                                            <div class="maintabs-table-parent mt-3">
                                                                <div class="maintabs-table d-flex">
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>S No.</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Plate No.</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Bid Status</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Starting Bid</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Highest Bid</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Action</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="maintabs-table-parent mt-2">
                                                                <div class="maintabs-table maintabs-table-2 d-flex">
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>01</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>XX-01</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Open</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>$5000.00</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>$6000.00</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <Link to="/Main_first_modal">
                                                                            <h4><i class="fa-solid fa-eye"></i></h4>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="maintabs-table-parent mt-2">
                                                                <div class="maintabs-table maintabs-table-2 d-flex">
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>02</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>XX-01</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Open</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>$5000.00</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>$6000.00</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <Link to="/Main_first_modal">
                                                                            <h4><i class="fa-solid fa-eye"></i></h4>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="maintabs-table-parent mt-2">
                                                                <div class="maintabs-table maintabs-table-2 d-flex">
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>03</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>XX-01</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>Open</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>$5000.00</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <h4>$6000.00</h4>
                                                                    </div>
                                                                    <div class="maintabs-table-inner">
                                                                        <Link to="/Main_first_modal">
                                                                            <h4><i class="fa-solid fa-eye"></i></h4>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                                            aria-labelledby="pills-profile-tab">
                                                            <div class="maintabs-table-parent mt-3">
                                                                <div
                                                                    class="maintabs-table-3 d-flex justify-content-center align-items-center">
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">S No.</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Plate No.</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Offer Status</h4>
                                                                    </div>
                                                                    <div style={{ width: "25%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">No. of Offers Received</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Seller Name</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Action</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Asking price</h4>
                                                                </div>
                                                                <div style={{ width: "25%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">05</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Blair</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <Link to="/Main_second_modal">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">02</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Offer Considered</h4>
                                                                </div>
                                                                <div style={{ width: "25%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">10</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Jake</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <Link to="/Main_second_modal">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">03</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Offer Considered</h4>
                                                                </div>
                                                                <div style={{ width: "25%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">15</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Arron</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <Link to="/Main_second_modal">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="right-box-first-row-main-parent">
                                        <div class="right-box-first-row">
                                            <div style={{ paddingLeft: "0", paddingRight: "0" }}
                                                class="heading-right-box-first-row-main">
                                                <h3><span class="me-3"><img src={lastOrdr}
                                                    alt="" /></span>Latest Orders</h3>
                                            </div>
                                            <div class="tabs-main-page-parent">
                                                <div class="tabs-main-page">
                                                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                        <li class="nav-item" role="presentation">
                                                            <button style={{ marginLeft: "0" }} class="nav-link active"
                                                                id="pills-home-tab" data-bs-toggle="pill"
                                                                data-bs-target="#pills-home22" type="button" role="tab"
                                                                aria-controls="pills-home22"
                                                                aria-selected="true">Auction</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link" id="pills-profile-tab"
                                                                data-bs-toggle="pill" data-bs-target="#pills-profile22"
                                                                type="button" role="tab" aria-controls="pills-profile22"
                                                                aria-selected="false">Listing</button>
                                                        </li>

                                                    </ul>
                                                    <div class="tab-content" id="pills-tabContent">
                                                        <div class="tab-pane fade show active" id="pills-home22" role="tabpanel"
                                                            aria-labelledby="pills-home-tab">
                                                            <div class="maintabs-table-parent mt-3">
                                                                <div
                                                                    class="maintabs-table-3 d-flex justify-content-center align-items-center">
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Date</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Plate No.</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Seller Email</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Starting Price</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Winner</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Winning Bid</h4>
                                                                    </div>
                                                                    <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Payment Status</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Action</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">01/06/2023</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">abc@outlook.com</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$5000.00</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">John</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$6000.00</h4>
                                                                </div>
                                                                <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Pending</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <Link to="/Main_fourth_modal">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">01/06/2023</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">abc@outlook.com</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$5000.00</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Mark</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$6000.00</h4>
                                                                </div>
                                                                <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                    <h4 style={{ color: "#479C34" }} class="text-center">Paid</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <Link to="/Main_fourth_modal">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">01/06/2023</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">abc@outlook.com</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$5000.00</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Smith</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$6000.00</h4>
                                                                </div>
                                                                <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center green-color-tbl">Paid</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <Link to="/Main_fourth_modal">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="pills-profile22" role="tabpanel"
                                                            aria-labelledby="pills-profile-tab">
                                                            <div class="maintabs-table-parent mt-3">
                                                                <div
                                                                    class="maintabs-table-3 d-flex justify-content-center align-items-center">
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Date</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Plate No.</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Seller Email</h4>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Starting Price</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Winner</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Winning Bid</h4>
                                                                    </div>
                                                                    <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Payment Status</h4>
                                                                    </div>
                                                                    <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                        <h4 class="text-center">Action</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">01/06/2023</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">abc@outlook.com</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$5000.00</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">John</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$6000.00</h4>
                                                                </div>
                                                                <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Pending</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <Link to="/Main_fifth_modal">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">01/06/2023</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">abc@outlook.com</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$5000.00</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Mark</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$6000.00</h4>
                                                                </div>
                                                                <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center orng-color-tbl">Deposit Paid</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <a href="">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="maintabs-table-3 maintabs-table-2 d-flex justify-content-center align-items-center mt-2">
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">01/06/2023</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">XX-01</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">abc@outlook.com</h4>
                                                                </div>
                                                                <div style={{ width: "15%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$5000.00</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">Smith</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center">$6000.00</h4>
                                                                </div>
                                                                <div style={{ width: "20%" }} class="maintabs-table-inner">
                                                                    <h4 class="text-center green-color-tbl">Paid</h4>
                                                                </div>
                                                                <div style={{ width: "10%" }} class="maintabs-table-inner">
                                                                    <a href="">
                                                                        <h4 class="text-center"><i class="fa-solid fa-eye"></i>
                                                                        </h4>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row-btm-parent-main mt-4 set-graph-responsive">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                                        <div class="btm-box-4-parent">
                                            {/*  row nested  */}
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <div class="btm-an-box-parent">
                                                        <div class="btm-an-box-heading">
                                                            <h2>
                                                                Plates For <br />{" "}
                                                                <span
                                                                    style={{
                                                                        FontWeight: "500",
                                                                        color: "#212121",
                                                                    }}
                                                                >
                                                                    Auction
                                                                </span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div class="month-year-parent-an d-flex justify-content-end mb-2">
                                                        <div class="month-year-inner-an">
                                                            <h5 class="me-2">Monthly</h5>
                                                        </div>
                                                        <div class="month-year-inner-an">
                                                            <label id="toggleSwitch" class="switch">
                                                                <input type="checkbox" class="switch1" />
                                                                <span class="slider an-slid"></span>
                                                            </label>
                                                        </div>
                                                        <div class="month-year-inner-an">
                                                            <h5 class="ms-2">Yearly</h5>
                                                        </div>
                                                    </div>
                                                    <div class="ord-customer-an-btm d-flex justify-content-end mt-2">
                                                        <img src={orderCustomer} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row nested  */}
                                            <div class="graph-btm-parent d-flex justify-content-center">
                                                <img src={graph3} alt="" />
                                                <div style={{ width: 700 }}>
                                                    {/* <BarChart redraw={true} chartData={userData} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                                        <div class="btm-box-4-parent">
                                            {/* row nested  */}
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <div class="btm-an-box-parent">
                                                        <div class="btm-an-box-heading">
                                                            <h2>
                                                                Plates For <br />{" "}
                                                                <span
                                                                    style={{
                                                                        FontWeight: "500",
                                                                        color: "#212121",
                                                                    }}
                                                                >
                                                                    Listing
                                                                </span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div class="month-year-parent-an d-flex justify-content-end mb-2">
                                                        <div class="month-year-inner-an">
                                                            <h5 class="me-2">Monthly</h5>
                                                        </div>
                                                        <div class="month-year-inner-an">
                                                            <label id="toggleSwitch" class="switch">
                                                                <input type="checkbox" class="switch1" />
                                                                <span class="slider an-slid"></span>
                                                            </label>
                                                        </div>
                                                        <div class="month-year-inner-an">
                                                            <h5 class="ms-2">Yearly</h5>
                                                        </div>
                                                    </div>
                                                    <div class="ord-customer-an-btm d-flex justify-content-end mt-2">
                                                        <img src={orderCustomer} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row nested  */}
                                            <div class="graph-btm-parent d-flex justify-content-center">
                                                <img src={graph3} alt="" />
                                                <div style={{ width: 700 }}>
                                                    {/* <BarChart redraw={true} chartData={userData} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                                        <div class="btm-box-4-parent">
                                            {/* <!-- row nested  --> */}
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <div class="btm-an-box-parent">
                                                        <div class="btm-an-box-heading">
                                                            <h2>
                                                                Total <br />{" "}
                                                                <span
                                                                    style={{
                                                                        FontWeight: "500",
                                                                        color: "#212121",
                                                                    }}
                                                                >
                                                                    Sales
                                                                </span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div class="month-year-parent-an d-flex justify-content-end mb-2">
                                                        <div class="month-year-inner-an">
                                                            <h5 class="me-2">Monthly</h5>
                                                        </div>
                                                        <div class="month-year-inner-an">
                                                            <label id="toggleSwitch" class="switch">
                                                                <input type="checkbox" class="switch1" />
                                                                <span class="slider an-slid"></span>
                                                            </label>
                                                        </div>
                                                        <div class="month-year-inner-an">
                                                            <h5 class="ms-2">Yearly</h5>
                                                        </div>
                                                    </div>
                                                    <div class="ord-customer-an-btm d-flex justify-content-end mt-2">
                                                        <img src={orderCustomer} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- row nested  --> */}
                                            <div class="graph-btm-parent d-flex justify-content-center">
                                                <img src={graph3} alt="" />
                                                <div style={{ width: 700 }}>
                                                    {/* <BarChart redraw={true} chartData={userData} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default connect(null, cityCountryAct)(DashboardMain)