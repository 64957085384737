import React,{useState,useEffect} from "react";


function Completion(props) {
  const [paymentIntentID, setPaymentIntent] = useState(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paymentIntent = urlSearchParams.get('payment_intent_client_secret');
  const stripe = window.Stripe("pk_test_51NWZXDLuGGkFJbYJyIPRRGSbU2MRjiRV6347ydlACEJsHJPDqD8cRpPPjRZgZd5oWLo1MAFB5IHyNY7GTAGo41Ad00j8eflciT");
  console.log("pi",paymentIntent);

  useEffect(() => {

    // Retrieve the Payment Intent from Stripe
    stripe
      .retrievePaymentIntent(paymentIntent)
      .then((result) => {
        // setPaymentIntent(result.paymentIntent);
        console.log("result",result);
      })
      .catch((error) => {
        console.error("Error retrieving Payment Intent:", error);
      });
  }, []);
  
    return <h1>Thank you! 🎉</h1>;
  }
  
  export default Completion;
  