import {React,useState} from 'react'
import WebFooterOneLogo from '../assets/images/footer-one-logo.png'
import {Link} from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import { baseUrl } from '../utils/Function';

function Footer() {
    
    const [formData, setFormData] = useState({
        Name: '',
        Telephone: '',
        Email: '',
        Message: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = JSON.stringify({
            name: formData.Name,
            email: formData.Email,
            phone: formData.Telephone,
            message: formData.Message
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}emails/message`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                Swal.fire("Thank You", "Form Submitted Successfully!", "success");
                console.log(JSON.stringify(response.data));
                setFormData({
                    Name: '',
                    Telephone: '',
                    Email: '',
                    Message: ''
                }); // Reset form data
            })
            .catch((error) => {
                Swal.fire("Error", "Form Submitted Failed!", "error");
                console.log(error);
      
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };



    return (
        <>
            <footer className="footer-one">
                <div className="container">
                    <div className="grid_footer-one">
                        <div className="footer-one-logo">
                            <Link to="/">
                                <img src={WebFooterOneLogo} alt="LOGO" />
                            </Link>
                        </div>
                        <div className="inner-footer-grid">
                            <div className="inner-footer-grid-left">
                                <h2>Signature Plates</h2>
                                <div className="footer-list-link">
                                    <li><Link to="/Buy">Plates For Sale</Link></li>
                                    <li><Link to="/AuctionSig">Live Auctions</Link></li>
                                    <li><Link to="/Sell">Sell Your Plates</Link></li>
                                    <li><Link to="/">Showroom</Link></li>
                                    <li><Link to="/Results">Sold</Link></li>
                                </div>
                            </div>
                            <div className="inner-footer-grid-right">
                                <h2>Contact</h2>
                                <div className="icn-footer-list-one">
                                    <a href="tel:123 1234 1234"><span></span>123 1234 1234</a>
                                </div>
                                <div className="icn-footer-list-two">
                                    <a href="mailto:info@Signatureplus.com.au"><span></span>info@Signatureplus.com.au</a>
                                </div>
                            </div>
                            <div className="inner-footer-grid-left">
                                <h2>Useful Links</h2>
                                <div className="footer-list-link">
                                    <li><Link to="/Buy">Plates For Sale</Link></li>
                                    <li><Link to="/AuctionSig">Live Auctions</Link></li>
                                    <li><Link to="/Sell">Sell Your Plates</Link></li>
                                    <li><Link to="/">Showroom</Link></li>
                                    <li><Link to="/Results">Sold</Link></li>
                                </div>
                            </div>
                            <div className="inner-footer-grid-right">
                                <h2>Find us on Socials!</h2>
                                <div className="icn-footer-list-one">
                                    <a href=""><span></span>Signatureplus</a>
                                </div>
                                <div className="icn-footer-list-two">
                                    <a href=""><span></span>@Signatureplus</a>
                                </div>
                            </div>
                        </div>
                        <div className="footer-one-form">
                            <form className="footer-form" onSubmit={handleSubmit}>
                                <div className="toptxt-form-footer">
                                    <h2>Message Us</h2>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    name="Name"
                                    value={formData.Name}
                                    onChange={handleChange}
                                />
                                <input
                                    type="text"
                                    placeholder="Phone"
                                    name="Telephone"
                                    value={formData.Telephone}
                                    onChange={handleChange}
                                />
                                <input
                                    type="text"
                                    placeholder="Email"
                                    name="Email"
                                    value={formData.Email}
                                    onChange={handleChange}
                                />
                                <textarea
                                    name="Message"
                                    placeholder="Message"
                                    value={formData.Message}
                                    onChange={handleChange}
                                ></textarea>
                                <input className="fo-bottom-send" type="submit" value="Send" />
                            </form>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer