import React from 'react'
import Sidebar from '../../../components/Sidebar'

function Main_second_modal() {
  return (
<div class="bg-dashboard">
  <main id="main">
  <Sidebar />
  <div className="dashboard-right-side main-first-modal-parent">
  <div className="modal-form-request-parent">
    <div className="modal-form-request-inner">
      <div className="cancel-icn">
        <a href="dashboard-main.php">
          <img src="assets/images/cancel-icn.png" alt="" />
        </a>
      </div>
      <div className="abc-heading text-center">
        <h1>ABC-123</h1>
      </div>
      <div className="modal-sec-row-parent">
        <div className="row">
          <div className="col-lg-4 pe-0">
            <div className="modal-2-h1">
              <h1>Asking Price</h1>
            </div>
          </div>
          <div className="col-lg-8 ps-0">
            <div className="modal-2-h1 modal-h1-2">
              <h1>$5000.00</h1>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-4 pe-0">
            <div className="modal-2-h1">
              <h1>Asking Price</h1>
            </div>
          </div>
          <div className="col-lg-8 ps-0">
            <div className="modal-2-h1 modal-h1-2">
              <h1>$6000.00</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="winner-table-parent mt-3">
        <div className="winner-table d-flex w-100">
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3>Name of Bidder</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3>Bidding Amount</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3>Current Highest Bid</h3>
          </div>
        </div>
        <div className="winner-table winner-table-2 d-flex w-100">
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3>Ethen Booker</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3>$5000.00</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <a href="main-third-modal.php">
              <h3>
                <i className="fa-solid fa-eye" />
              </h3>
            </a>
          </div>
        </div>
        <div className="winner-table winner-table-2 d-flex w-100">
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3 style={{ background: "#EBEBEB" }}>Mark Allen</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3 style={{ background: "#EBEBEB" }}>$5000.00</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <a href="main-third-modal.php">
              <h3 style={{ background: "#EBEBEB" }}>
                <i className="fa-solid fa-eye" />
              </h3>
            </a>
          </div>
        </div>
        <div className="winner-table winner-table-2 d-flex w-100">
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3>John Smith</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <h3>$5000.00</h3>
          </div>
          <div
            style={{ width: "33.3%" }}
            className="winner-table-inner text-center"
          >
            <a href="main-third-modal.php">
              <h3>
                <i className="fa-solid fa-eye" />
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  </main>
  {/* Latest compiled JavaScript */}
</div>
  )
}

export default Main_second_modal