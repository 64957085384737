import React, { useState, useEffect } from "react";
import WebSelllogo from "../../../assets/images/logo.png";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import AnimatedCursor from "../../../components/AnimatedCursor";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import { getPlateUrl } from "../../../utils/Function";
import { gql, useQuery } from "@apollo/client";
import axios from "axios";
import FormData from "form-data";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../utils/Function";

const Me = gql`
  query {
    me {
      status
      firstName
      lastName
      email
      phoneNumber
      drivingLicense
    }
  }
`;

function Sell() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedOption, setSelectedOption] = useState(null);
  const [comment, setComment] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [plateCombination, setPlateCombination] = useState();
  const [price, setPrice] = useState(0);
  const [desired, setDesired] = useState();
  const [isListing, setIsListing] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleRadioChange = (e) => {
    setIsListing(e.target.value === "saler");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const { loadin, error, data } = useQuery(Me);

  const userData = useSelector((state) => state.user.userData);
  const token = userData.accessToken;

  console.log({ loadin, error, token });

  if (loadin) {
    return <div>Loadin...</div>;
  }
  if (error) {
    return (
      <>
        <AnimatedCursor />
        <div className="main-scroll-bar">
          <header className="header-bg">
            <Header />
            <section className="top_banner">
              <div className="container">
                <div className="grid-row auction-grid">
                  <div className="grid-col-2">
                    <div className="contentBox content leFadeIn">
                      <h1 className="leFadeIn">Sell</h1>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam convallis rhoncus eros, nec scelerisque nisi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>
          <section className="sell-main">
            <div className="container">
              <div className="sell-main-grid">
                <div className="left-main-sell-grid">
                  <div className="left-main-sell-grid-inner">
                    <div className="toptxt-lft-main-sell">
                      <h2>
                        How to sell your
                        <br />
                        plate ?
                      </h2>
                    </div>
                    <div className="txt-lft-main-sell">
                      <div className="sell-lft-list-one">
                        <p className="para-btm-padding">
                          {" "}
                          <span className="addline">1</span> Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit. Sed et volutpat
                          tellus ?
                        </p>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed et volutpat tellus, quis finibus risus. Nam
                          ullamcorper auctor elit lobortis faucibus.
                        </p>
                      </div>
                      <div className="sell-lft-list-two">
                        <p className="para-btm-padding">
                          {" "}
                          <span className="addline">2</span>Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit. Sed et volutpat
                          tellus ?
                        </p>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed et volutpat tellus, quis finibus risus. Nam
                          ullamcorper auctor elit lobortis faucibus.
                        </p>
                      </div>
                      <div className="sell-lft-list-three">
                        <p className="para-btm-padding">
                          {" "}
                          <span className="addline">3</span>Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit. Sed et volutpat
                          tellus ?
                        </p>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed et volutpat tellus, quis finibus risus. Nam
                          ullamcorper auctor elit lobortis faucibus.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="btm-txt-right-col">
                  <h2 className="para-askprice-one">
                    you need to be logged in with a registered account to be
                    able to Bid, buy now or make offers on our platform
                  </h2>
                  <div className="anchor-ask-price-parent">
                    <div className="mg-left-zero anchor-ask-price-inner">
                      <Link to="/Register">Register</Link>
                    </div>
                    <div className="anchor-ask-price-inner">
                      <h3 className="color-fff">OR</h3>
                    </div>
                    <div className="anchor-ask-price-inner">
                      <Link to="/LoginApiTest">Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }

  if (data?.me?.status != "APPROVED") {
    return (
      <>
        <AnimatedCursor />
        <div className="main-scroll-bar">
          <header className="header-bg">
            <Header />
            <section className="top_banner">
              <div className="container">
                <div className="grid-row auction-grid">
                  <div className="grid-col-2">
                    <div className="contentBox content leFadeIn">
                      <h1 className="leFadeIn">Sell</h1>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam convallis rhoncus eros, nec scelerisque nisi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>
          <section className="sell-main">
            <div className="container">
              <div className="sell-main-grid">
                <div className="left-main-sell-grid">
                  <div className="left-main-sell-grid-inner">
                    <div className="toptxt-lft-main-sell">
                      <h2>
                        How to sell your
                        <br />
                        plate ?
                      </h2>
                    </div>
                    <div className="txt-lft-main-sell">
                      <div className="sell-lft-list-one">
                        <p className="para-btm-padding">
                          {" "}
                          <span className="addline">1</span> Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit. Sed et volutpat
                          tellus ?
                        </p>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed et volutpat tellus, quis finibus risus. Nam
                          ullamcorper auctor elit lobortis faucibus.
                        </p>
                      </div>
                      <div className="sell-lft-list-two">
                        <p className="para-btm-padding">
                          {" "}
                          <span className="addline">2</span>Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit. Sed et volutpat
                          tellus ?
                        </p>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed et volutpat tellus, quis finibus risus. Nam
                          ullamcorper auctor elit lobortis faucibus.
                        </p>
                      </div>
                      <div className="sell-lft-list-three">
                        <p className="para-btm-padding">
                          {" "}
                          <span className="addline">3</span>Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit. Sed et volutpat
                          tellus ?
                        </p>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed et volutpat tellus, quis finibus risus. Nam
                          ullamcorper auctor elit lobortis faucibus.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="btm-txt-right-col">
                    <h2 className="para-askprice-one">
                      you need to be verified first with a registered account to
                      be able to visit this page.
                    </h2>
                    <br />
                    <div className="anchor-ask-price-inner">
                      <Link to="/">BACK TO HOME</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }

  const handlePlateCombination = (e) => {
    setPlateCombination(e.target.value);
  };
  const handlePrice = (e) => {
    setPrice(e.target.value);
  };
  const handleDesiredPrice = (e) => {
    setDesired(e.target.value);
  };
  const suffix = "someSuffix";
  const url = getPlateUrl(suffix);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedFileName(file.name);
    }
  };

  const Swal = require("sweetalert2");
  const handleSubmit = (e) => {
    e.preventDefault();

    let data = new FormData();
    data.append("isOpenForPrice", isChecked); // me isChecked me True false dono bhj rha hn but wo srf false le rha hay
    data.append("combination", plateCombination);
    data.append("askingPrice", desired);
    data.append("comments", comment);
    data.append("numberPlate", selectedFile);
    setIsLoading(true);

    // Simulate a 2-second loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    var suffix = isListing ? "listing-plate" : "auction-plate";

    if (isListing) {
      const formData = new FormData();
      formData.append("isOpenForPrice", isChecked);
      data.append("settlePrice", price);
    } else {
      data.append("isReserve", selectedOption);
    }

    console.log(isChecked, "-------yhe karo pehly"); // console yhe raha

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}plates/${suffix}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // toast("Your Application Succesfully Submitted!")
        setErrorMessage("");
        // console.log(JSON.stringify(response.data));
        Swal.fire("Good job!", "Your Plate has been Registered!", "success");
        navigate("/");
      })
      .catch((error) => {
        if (selectedFile === null) {
          console.log("Please select a file");
          setErrorMessage("Please select a file.");
          return;
        }
        // console.log(error.response.status, "-error mes");
        // toast.error(error.response.data.message);
        // if (error.response.status === 400) {
        // }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
      });
  };

  return (
    <>
      <AnimatedCursor />
      <div className="main-scroll-bar">
        <header className="header-bg">
          <Header />
          <section className="top_banner">
            <div className="container">
              <div className="grid-row auction-grid">
                <div className="grid-col-2">
                  <div className="contentBox content leFadeIn">
                    <h1 className="leFadeIn">Sell</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam convallis rhoncus eros, nec scelerisque nisi.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>
        <section className="sell-main">
          <div className="container">
            <div className="sell-main-grid">
              <div className="left-main-sell-grid">
                <div className="left-main-sell-grid-inner">
                  <div className="toptxt-lft-main-sell">
                    <h2>
                      How to sell your
                      <br />
                      plate ?
                    </h2>
                  </div>
                  <div className="txt-lft-main-sell">
                    <div className="sell-lft-list-one">
                      <p className="para-btm-padding">
                        {" "}
                        <span className="addline">1</span> Lorem ipsum dolor sit
                        amet, consectetur adipiscing elit. Sed et volutpat
                        tellus ?
                      </p>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed et volutpat tellus, quis finibus risus. Nam
                        ullamcorper auctor elit lobortis faucibus.
                      </p>
                    </div>
                    <div className="sell-lft-list-two">
                      <p className="para-btm-padding">
                        {" "}
                        <span className="addline">2</span>Lorem ipsum dolor sit
                        amet, consectetur adipiscing elit. Sed et volutpat
                        tellus ?
                      </p>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed et volutpat tellus, quis finibus risus. Nam
                        ullamcorper auctor elit lobortis faucibus.
                      </p>
                    </div>
                    <div className="sell-lft-list-three">
                      <p className="para-btm-padding">
                        {" "}
                        <span className="addline">3</span>Lorem ipsum dolor sit
                        amet, consectetur adipiscing elit. Sed et volutpat
                        tellus ?
                      </p>

                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed et volutpat tellus, quis finibus risus. Nam
                        ullamcorper auctor elit lobortis faucibus.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-main-sell-grid">
                <div className="right-main-sell-inner">
                  <form onSubmit={handleSubmit}>
                    <div className="heading-sell-form">
                      <div className="head-sell-frm-left">
                        <img src={WebSelllogo} />
                      </div>
                      <div className="head-sell-frm-center">
                        <h1>REGISTRATION FORM</h1>
                      </div>
                      <div className="head-sell-frm-right">
                        <div className="head-sell-frm-right-inner">
                          <h1>
                            SELL
                            <br />
                            YOUR
                            <br />
                            PLATE
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="sell-form-detail">
                      <div className="detailsell-heading">
                        <h1>
                          <span>1</span>ENTER YOUR DETAILS
                        </h1>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="detail-sell-inputs-parent">
                          <div className="detail-input-inner-grid">
                            <div className="detail-inp-left">
                              <input
                                type="text"
                                placeholder="First Name *"
                                value={data?.me?.firstName}
                              />
                            </div>
                            <div className="detail-inp-right">
                              <input
                                type="text"
                                placeholder="Last Name *"
                                value={data?.me?.lastName}
                              />
                            </div>
                            <div className="detail-inp-left">
                              <input
                                type="email"
                                placeholder="Enter your email *"
                                value={data?.me?.email}
                              />
                            </div>
                            <div className="detail-inp-right">
                              <input
                                type="tel"
                                placeholder="+61455887995"
                                value={data?.me?.phoneNumber}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="sell-form-plate">
                      <div className="detailsell-heading">
                        <h1>
                          <span>2</span>PLATE DETAILS
                        </h1>
                      </div>
                      <div className="plate-sell-inner-grid-parent">
                        <div className="radio_divider">
                          <ul>
                            <li className="rdio-btn">
                              <input
                                type="radio"
                                id="auction"
                                name="selector"
                                value="auction"
                                checked={!isListing}
                                onChange={handleRadioChange}
                              />
                              <label htmlFor="auction">
                                I Am Here To Auction
                              </label>
                              <div className="check"></div>
                            </li>

                            <li className="rdio-btn">
                              <input
                                type="radio"
                                id="saler"
                                name="selector"
                                value="saler"
                                checked={isListing}
                                onChange={handleRadioChange}
                              />
                              <label htmlFor="saler">
                                I Am Here For Listing
                              </label>
                              <div className="check">
                                <div className="inside"></div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="checbox-controler">
                          <div className="plate-sell-inner-grid">
                            <div className="detail-inp-left-plate">
                              <input
                                type="text"
                                placeholder="Plate Combination *"
                                value={plateCombination}
                                onChange={handlePlateCombination}
                                maxLength={4}
                                required
                              />
                            </div>
                            <div className="detail-inp-right">
                              <input
                                type="text"
                                inputMode="numeric" // This will display a numeric keyboard on mobile devices.
                                pattern="[0-9]*" // This is for client-side validation (modern browsers).
                                placeholder="Desired Plate price"
                                value={desired}
                                onChange={handleDesiredPrice}
                                onKeyPress={(e) => {
                                  const isValidInput = /^\d*$/.test(e.key);
                                  if (!isValidInput) {
                                    e.preventDefault();
                                  }
                                }}
                                onWheel={(e) => e.currentTarget.blur()} // This prevents the mouse wheel from showing the spinner.
                                required
                              />
                            </div>
                          </div>
                          <div className="plate-sell-drp-dwn">
                            {isListing ? (
                              <div className="sett-the-hide-checkbox-flex">
                                <label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                  />
                                  Offer Considered
                                </label>
                                {!isChecked && (
                                  <input
                                    type="text"
                                    inputMode="numeric" // This will display a numeric keyboard on mobile devices.
                                    pattern="[0-9]*"
                                    onKeyPress={(e) => {
                                      const isValidInput = /^\d*$/.test(e.key);
                                      if (!isValidInput) {
                                        e.preventDefault();
                                      }
                                    }}
                                    value={price}
                                    onChange={handlePrice}
                                    placeholder="Asking Price"
                                  />
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div class="comment-sell-page"></div>

                          <div className="rsrve-drp-dwn-btm-para"></div>

                          <div class="parent-file-upload-sell">
                            <div class="num-plate-photo-parent pd-btm">
                              <span>Upload Valid Number Plate</span>
                              <strong>*</strong>
                              <div class="choose-file">
                                <label className="custom-file-upload">
                                  <img />
                                  <input
                                    name="numberPlate"
                                    type="file"
                                    onChange={handleFileChange}
                                  />
                                  Choose File
                                </label>
                                <span>
                                  {selectedFileName || "No file chosen"}
                                </span>{" "}
                                <p
                                  style={{ color: "red" }}
                                  className="error-message"
                                >
                                  {errorMessage}
                                </p>
                              </div>
                            </div>
                            {/* <img src={selectedFile} /> */}
                            <div className="num-plate-photo-parent">
                              <span> Driver License</span>
                              <strong>*</strong>
                              <div className="choose-file">
                                <img
                                  style={{ width: "200px", height: "150px" }}
                                  src={`${baseUrl}${data?.me?.drivingLicense}`}
                                />

                                <span></span>
                              </div>
                            </div>
                          </div>

                          <div className="comment-sell-page">
                            <textarea
                              name="sell-textarea"
                              value={comment}
                              placeholder="Comments / Questions"
                              onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                          </div>

                          <div className="agree-chk-box">
                            <input
                              type="checkbox"
                              required
                              name="agree-chk-box"
                            />{" "}
                            <span>
                              I agree to the{" "}
                              <b>
                                <a href="#"> Terms & Conditions </a>
                              </b>{" "}
                              ,
                              <b>
                                <a href="#"> Privacy Policy </a>
                              </b>{" "}
                              &{" "}
                              <b>
                                <a href="#"> Refund Policy</a>
                              </b>
                              .
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-sell-form">
                      <button
                        type="submit"
                        className="login-btn actionbtn-pg-5 btn btn-3 apply-btn application-btn"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : " Submit Application"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Sell;
