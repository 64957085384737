import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

const GET_AUCTION_PRODUCT = gql`
  query GetAuctionProduct($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      id
      detail {
        ... on PlateAuction {
          auction {
            id
            startingPrice
            endAt
            bids {
              id
              price
              currentBid
              createdAt
              user {
                username
              }
            }
          }
        }
        ... on PlateListing {
          id
        }
      }
      status
      comments
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;

function AuctionTest() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_AUCTION_PRODUCT, {
    variables: { id: parseInt(id) },
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const plate = data && data.plate;
  const { combination, comments, detail } = plate;
  const { auction } = detail;

  if (auction) {
    const { id: auctionId, startingPrice, endAt, bids } = auction;
    const highestBid = bids.length > 0 ? bids[0] : null;

    return (
      <div>
        <h1>Auction Product</h1>
        <p>Combination: {combination}</p>
        <p>Comments: {comments}</p>
        <p>Auction ID: {auctionId}</p>
        <p>Starting Price: {startingPrice}</p>
        <p>Ends At: {endAt}</p>
        {highestBid && (
          <div>
            <p>Highest Bid: {highestBid.currentBid}</p>
            <p>Bidder: {highestBid.user.username}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <h1>Auction Product</h1>
      <p>Combination: {combination}</p>
      <p>Comments: {comments}</p>
      <p>This plate is not available for auction.</p>
    </div>
  );
}

export default AuctionTest;
