import React from "react";
import { useState } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import cancelIcon from "../../../assets/images/cancel-icn.png";

import { useQuery, useMutation, gql } from "@apollo/client";
import { Link, Navigate, useParams } from "react-router-dom";
import OfferConsideredTwo from "../Offer-Considered-Two/OfferConsideredTwo";
import logo from "../../../assets/images/logo.png";
import "react-toastify/dist/ReactToastify.css";
import AnimatedCursor from "../../../components/AnimatedCursor";
import { useSelector } from "react-redux";
import Swal from "../../../../node_modules/sweetalert2/src/sweetalert2";
import OfferConsideredThree from "../Offer-Considered-Two/OfferConsiderThree";
import { baseUrl } from "../../../utils/Function";
import { useNavigate } from "react-router-dom";
import ScrollIntoView from "react-scroll-into-view";
import Modal from 'react-modal';


const GET_LISTING_PLATE = gql`
  query GetListingPlate($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      comments
      numberPlate
      user{
        id
        firstName
      }
      id
      detail {
        ... on PlateListing {
          isOpenForPrice
          settlePrice
        }
      }
    }
  }
`;

const CREATE_OFFER = gql`
  mutation CreateOffer($plateListingId: Int!, $price: Float!) {
    createOffer(data: { plateListingId: $plateListingId, price: $price }) {
      counter

      id
      price
      status
    }
  }
`;

const Me = gql`
  query {
    me {
      status
      firstName
      lastName
      email
      phoneNumber
      drivingLicense
    }
  }
`;

function OfferConsider() {
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isbidrecivemodal, setIsBidReciveModal] = useState(false);

  const [price, setPrice] = useState("");
  const userData = useSelector((state) => state.user.userData);
  const token = userData.accessToken;
  const ID = userData.userID;

  const [createOffer, { loading, error }] = useMutation(CREATE_OFFER, {
    onError: (error) => {
      console.log("Mutation error:", error);
      Swal.fire("Error!", "The Offer Price is Not Set", "error");
    },
    onCompleted: (data) => {
      console.log(data, "bid submitted");
      Swal.fire(
        "Success!",
        "Your Offer has been Received and is in the Queue.",
        "success"
      );
      navigate("/");
    },
  });
  const customStyles = {
    content: {
      top: "35%",
      left: "60%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      width: "70%",
    },
  };
  const bidreceivemodal = () => {
    setIsBidReciveModal(true);
  };

  const { id } = useParams();
  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the createOffer mutation
    createOffer({
      variables: { plateListingId: parseInt(id), price: parseFloat(price) },
    });
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    setPrice("");
  };

  // const { loading2, error2, data } = useQuery(GET_LISTING_PLATE, {
  //     variables: { id: parseInt(id) },
  // });
  const { loading: loadingb, error: errorb, data: datab } = useQuery(Me);

  const { loading2, error2, data } = useQuery(GET_LISTING_PLATE, {
    variables: { id: parseInt(id) },
  });

  // Check if the data has been loaded and is not in a loading state
  if (loading2) {
    return <div>Loading...</div>;
  }

  // Check if there was an error while fetching the data
  if (error2) {
    return <div>Error: {error.message}</div>;
  }
  const setPrice2 = data?.plate?.detail?.settlePrice;
  const combination = data?.plate?.combination.id;
  const numberPlate = data?.plate?.numberPlate.id;
  console.log(setPrice2, "----------===+++");
  if (loadingb) {
    return <div>Loadin...</div>;
  }
  if (errorb) {
    return <div>Error: {error.message}</div>;
  }

  if (datab?.me?.status === "PENDING") {
    return <OfferConsideredThree />;
  } else if (!token) {
    return <OfferConsideredTwo />;
  } else {
    console.log(token, "------- token ni hai");

    return (
      <>
        {/* <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                /> */}
        <AnimatedCursor />
        <div class="main-scroll-bar">
          <header class="header-bg">
            <Header />
            <section class="top_banner">
              <div class="Web-Container">
                <div class="grid-row contactpage">
                  <div class="grid-col-2">
                    <div class="contentBox">
                      <h1 data-aos="fade-right" data-aos-duration="1700">
                        {data?.plate?.combination}
                      </h1>
                      {console.log(
                        data?.plate?.combination,
                        "-------- combination"
                      )}
                      <p data-aos="fade-right" data-aos-duration="1800">
                        {data?.plate?.comments}
                      </p>
                    </div>
                  </div>
                  <div class="grid-col-2"></div>
                </div>
              </div>
            </section>
          </header>
          <div class="main_contact-page">
            <section class="bid-main-sec">
              <div class="Web-Container">
                <div class="bid-parent">
                  <div class="bid-grid-main">
                    <div class="bid-inner-grid-content">
                      <div class="prent-left-grid">
                        <div class="main-left-bid-grid" id="big-image">
                          <div class="serch-icn-cont">
                            <img src={logo} alt="" />
                          </div>
                          <div class="logo-inner-card">
                            <img src="assets/images/logo.png" alt="" />
                          </div>
                          <div class="plate-bid-card">
                            <img
                              src={`${baseUrl}${data?.plate?.numberPlate}`}
                              alt=""
                            />
                            {/* <img class="und-img" src={`${baseUrl}${data?.plate?.numberPlate}`} alt="" /> */}
                          </div>
                          <div class="text-bid-card">
                            <p style={{ color: "white", padding: "20px" }}>
                              {data?.plate?.comments}
                            </p>
                            {/* <img src={logo} alt="" /> */}
                          </div>
                        </div>
                        <div class="btm-card-img ">
                          <div class="btm-card-inner">
                            <div class="btm-img small-images">
                              <img
                                id="img1"
                                src="assets/images/btm-card-one.png"
                                alt=""
                              />{" "}
                              <img
                                id="img2"
                                src="assets/images/btm-card-two.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bid-inner-grid-content">
                      {setPrice2 === 0 && (
                        <div class="top-txt-rightbid">
                          <h2>Offer Considered </h2>
                        </div>
                      )}
                      {setPrice2 !== 0 && (
                        <div class="top-txt-rightbid">
                          <h2>Asking Price</h2>
                          <h4 className="fw-400 color-fff pd-h4-custom">
                            Current Bid : {data?.plate?.askingPrice}
                          </h4>
                          <Link
                            // to={`/UserPayment/${ID}${data?.plate?.askingPrice}`}
                            class="login-btn actionbtn-pg-5 btn btn-3 offer-btn"
                            style={{
                              width: "26%",
                              height: "38px",
                            }}
                            onClick={bidreceivemodal}
                          >
                            Buy it
                          </Link>
                          <p>
                            You can purchase this Signature plate now for the
                            Asking price
                          </p>
                        </div>
                      )}

                              


                      
                      <form onSubmit={handleSubmit}>
                          {
                            data?.plate?.user?.id === ID ? <p style={{
                              color:"Green",
                            }} >you are not able to bid, this is your own plate"</p> :
                          

                        <div className="flex-sub-bid">
                          <div className="input-value">
                            <span
                              style={{
                                color: "white",
                                fontSize: "20px",
                                padding: "5px",
                              }}
                            >

                              $
                            </span>
                            <input
                              type="text"
                              inputMode="numeric" // This will display a numeric keyboard on mobile devices.
                              pattern="[0-9]*"
                              onKeyPress={(e) => {
                                const isValidInput = /^\d*$/.test(e.key);
                                if (!isValidInput) {
                                  e.preventDefault();
                                }
                              }}
                              value={price}
                              onChange={(event) => setPrice(event.target.value)}
                            />
                          </div>
                          <div className="input-sub-bid">
                            <input type="submit" value={isLoading ? 'Loading...' : 'Submit Offer'}
        disabled={isLoading} />
                          </div>
                        </div>
                        }
                      </form>
                      <div class="btm-txt-right-col">
                        <h2>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nam convallis rhoncus eros, nec scelerisque
                          nisi.
                        </h2>
                      </div>
                      <div class="btm-txt-right-col">
                        <h2>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nam convallis rhoncus eros, nec scelerisque
                          nisi.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />

{/* modal for buy */}

<Modal
                isOpen={isbidrecivemodal}
                // onRequestClose={()=>setIsBidAuctionReciveModal(false)}
                style={customStyles}
              >
                <div class="next-bid-table">
                  <div class="next-bid-table-flex">
                    <div class="next-bid-border-box">
                      <div class="next-bid-table-img">
                        <img
                          onClick={() => setIsBidReciveModal(false)}
                          class="move-to-previous-bid-table"
                          src={cancelIcon}
                          alt=""
                        />
                      </div>
                      <div class="next-bid-table-start">
                        <div class="bid-next-tble d-flex w-100 text-center justify-content-center">
                         <h1>You have to pay {data?.plate?.askingPrice}$.</h1>
                          
                        </div>
                        <div>
                        <Link className="login-btn actionbtn-pg-5 btn btn-3 offer-btn" style={{
                          marginLeft:"120px",
                          width: "200px"
                        }}
                        to="https://buy.stripe.com/test_4gwdUM84mgknef6dQT"
                        >Proceed to pay</Link>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>


                            {/* end */}



        </div>
      </>
    );
  }
}

export default OfferConsider;
