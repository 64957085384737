import React, { useState } from 'react';
// import RangeSliderComponent from '../../../components/RangeSliderComponent';
// import * as React from 'react';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useSelector } from 'react-redux';

function valuetext(value) {
  return `${value}°C`;
}

const LeftMainGridBuy = ({ onDataUpdate, onDataUpdate2, onDataUpdate3, onDataUpdate4 }) => {
  const [characterCount, setCharacterCount] = useState(null);
  const [pattern, setPattern] = useState('');
  const [term, setTerm] = useState('');
  const [value, setValue] = React.useState([20, 37]);
  const userData = useSelector(state => state.user.userData)



  const token = userData.accessToken;

  const handleTermChange = (e) => {
    const text = e.target.value;
    setTerm(text)
    onDataUpdate(text);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onDataUpdate4(value)
  };



  const handleCheckboxChange = (e) => {
    const count = parseInt(e.target.getAttribute('value'), 10);    
    console.log(count, "couuuuuuuuut")
    
    if (e.target.checked) {
      setCharacterCount(count);
      onDataUpdate2(count);
      onDataUpdate3(pattern)
    } else {
      setCharacterCount(null);
      // onDataUpdate2(characterCount);
    }
  };

  const handlePatternCheckboxChange = (e) => {
    const patternValue = e.target.value;
    if (e.target.checked) {
      // Sub-checkbox is checked
      // Make API call or perform necessary operations
      setPattern(patternValue);
      onDataUpdate3(patternValue);
    } else {
      // Sub-checkbox is unchecked
      // Reset data or perform necessary operations
      setPattern('');
      onDataUpdate3('');
    }
  };


  return (
    <div className="left-main-grid-buy">
      <div className="input-search-buy">
        <input
          type="search"
          name="search"
          placeholder="Search by characters"
          value={term}
          onChange={handleTermChange}
        />
        <div className="underline-search" />
      </div>
      <div className="box-left-bottom-buy-parent">
        <div className="box-left-bottom-buy-inner">
          <div className="inner-lft-grid-one">
            <div className="inner-lft-grid-one-left">
              <div className="cross-icn">
                <span style={{fontSize: "26px"}}>FILTER</span>
              </div>
            </div>
            <div className="inner-lft-grid-one-right">  
              <button
                onClick={(e) => {
                  let characterCount = null;
                  setCharacterCount(e.target.characterCount);
                  onDataUpdate2(characterCount);
                  onDataUpdate3("")
                }}
                className="login-btn actionbtn-pg-5 btn btn-3 apply-btn restbtn buy-apply-btn-set"
              >
                RESET
              </button>
            </div>
          </div>
          <div className="combination-list-buy-parent">
            <div className="combination-list-buy-inner">
              <div className="comb-list-heading">
                <h2>Combination</h2>
              </div>

              {/* combination div */}

              <div className="comb-list">
                <div className="list-comb-one list-pd-btm">
                  <div className="squaredOne">
                    <input
                      type="checkbox"
                      defaultValue="None"
                      id="squaredOne"
                      name="check"
                      value={1}
                      checked={characterCount === 1}
                      onChange={handleCheckboxChange}
                      // onChange={(e) => {
                      //   let characterCount = 1;
                      //   setCharacterCount(e.target.characterCount);
                      //   // console.log(characterCount, "=========== init char")
                      //   onDataUpdate2(characterCount);
                      // }}
                    />
                    <label htmlFor="squaredOne" />
                  </div>
                  
                  <span>1 character</span>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredOnesub"
                        name="check"
                        value="L"
                        checked={pattern === 'L'}
                        onChange={handlePatternCheckboxChange} 
                        // onChange={(e) => {
                        //   let pattern = 'L';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}

                      />
                      <label htmlFor="squaredOnesub" />
                    </div>
                    <span>L</span>
                  </div>
                </div>
                <div className="list-comb-one list-pd-btm">
                  <div className="squaredOne">
                    <input
                      type="checkbox"
                      defaultValue="None"
                      id="squaredtwo"
                      name="check"
                      value={2}
                      checked={characterCount === 2}
                      onChange={handleCheckboxChange}
                      // onChange={(e) => {
                      //   let characterCount = 2;
                      //   setCharacterCount(e.target.characterCount);
                      //   // console.log(characterCount, "=========== init char")
                      //   onDataUpdate2(characterCount);
                      // }}
                    />
                    <label htmlFor="squaredtwo" />
                  </div>
                  <span>2 characters</span>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        id="squaredTwosub"
                        name="check"
                        value="L-N"
                        checked={pattern === 'L-N'}
                        onChange={handlePatternCheckboxChange}                         
                        // onChange={(e) => {
                        //   let pattern = 'L-N';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }} 
                        />
                      <label htmlFor="squaredTwosub" />
                    </div>
                    <span>L-N </span>
                  </div>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredThreesub"
                        name="check"
                        value="LL"
                        checked={pattern === 'LL'}
                        onChange={handlePatternCheckboxChange}                         
                        // onChange={(e) => {
                        //   let pattern = 'LL';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}
                      />
                      <label htmlFor="squaredThreesub" />
                    </div>
                    <span>LL</span>
                  </div>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredFoursub"
                        name="check"
                        value="N-L"
                        checked={pattern === 'N-L'}
                        onChange={handlePatternCheckboxChange} 
                        // onChange={(e) => {
                        //   let pattern = 'N-L';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}
                      />
                      <label htmlFor="squaredFoursub" />
                    </div>
                    <span>N-L </span>
                  </div>
                </div>
                <div className="list-comb-one list-pd-btm">
                  <div className="squaredOne">
                    <input
                      type="checkbox"
                      defaultValue="None"
                      id="squaredthree"
                      name="check"
                      value={3}
                      checked={characterCount === 3}
                      onChange={handleCheckboxChange}
                      // onChange={(e) => {
                      //   let characterCount = 3;
                      //   setCharacterCount(e.target.characterCount);
                      //   // console.log(characterCount, "=========== init char")
                      //   onDataUpdate2(characterCount);
                      // }}
                    />
                    {/* {console.log(characterCount, '-----chrc count')} */}

                    <label htmlFor="squaredthree" />
                  </div>
                  <span>3 character </span>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredFivesub"
                        name="check"
                        value="L-NN"
                        checked={pattern === 'L-NN'}
                        onChange={handlePatternCheckboxChange}
                        // onChange={(e) => {
                        //   let pattern = 'L-NN';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}
                      />
                      <label htmlFor="squaredFivesub" />
                    </div>
                    <span>L-NN</span>
                  </div>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredSixsub"
                        name="check"
                        value="LL-N"
                        checked={pattern === 'LL-N'}
                        onChange={handlePatternCheckboxChange}                        // onChange={(e) => {
                        //   let pattern = 'LL-N';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}
                      />
                      <label htmlFor="squaredSixsub" />
                    </div>
                    <span>LL-N </span>
                  </div>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredSevensub"
                        name="check"
                        value="N-LL"
                        checked={pattern === 'N-LL'}
                        onChange={handlePatternCheckboxChange}                        // onChange={(e) => {
                        //   let pattern = 'N-LL';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}
                      />
                      <label htmlFor="squaredSevensub" />
                    </div>
                    <span>N-LL</span>
                  </div>
                  <div className="list-comb-one list-pd-btm">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredEightsub"
                        name="check"
                        value="NN-L"
                        checked={pattern === 'NN-L'}
                        onChange={handlePatternCheckboxChange}     
                        // onChange={(e) => {
                        //   let pattern = 'NN-L';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}
                      />
                      <label htmlFor="squaredEightsub" />
                    </div>
                    <span>NN-L</span>
                  </div>
                </div>
                <div className="list-comb-one">
                  <div className="squaredOne">
                    <input
                      type="checkbox"
                      defaultValue="None"
                      id="squaredfour"
                      name="check"
                      value={4}
                      checked={characterCount === 4}
                      onChange={handleCheckboxChange}
                      // onChange={(e) => {
                      //   let characterCount = 4;
                      //   setCharacterCount(e.target.characterCount);
                      //   // console.log(characterCount, "=========== init char")
                      //   onDataUpdate2(characterCount);
                      // }}
                    />
                    <label htmlFor="squaredfour" />
                  </div>
                  <span>4 character</span>
                  <div className="list-comb-one list-pd-btm set-this-field">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredNinesub"
                        name="check"
                        value="LL-NN"
                        checked={pattern === 'LL-NN'}
                        onChange={handlePatternCheckboxChange}                             
                        // onChange={(e) => {
                        //   let pattern = 'LL-NN';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}
                      />
                      <label htmlFor="squaredNinesub" />
                    </div>
                    <span>LL-NN</span>
                  </div>
                  <div className="list-comb-one list-pd-btm set-this-field">
                    <div className="squaredOne">
                      <input
                        type="checkbox"
                        defaultValue="None"
                        id="squaredTensub"
                        name="check"
                        value="NN-LL"
                        checked={pattern === 'NN-LL'}
                        onChange={handlePatternCheckboxChange}   
                        // onChange={(e) => {
                        //   let pattern = 'NN-LL';
                        //   setPattern(e.target.pattern);
                        //   // console.log(characterCount, "=========== init char")
                        //   onDataUpdate3(pattern);
                        // }}

                      />
                      <label htmlFor="squaredTensub" />
                    </div>
                    <span>NN-LL</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="asking-price-but-parent">
              <div className="asking-price-but-inner">
                <div className="ask-pric-heading">
                  <h2>Asking price</h2>
                </div>

                {/* ------------------------Range slider---------------------- */}

                <Box>
                  <Slider
                    getAriaLabel={() => 'Temperature range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                  />
                  <div className='parent-buy-slider-input'>
                    <div className='child-parent-input'>
                      <p>$</p>
                      <input type="number" value={value[0]} />
                    </div>
                    <div className='child-parent-input'>
                      <p>$</p>
                      <input type="number" value={value[1]} />
                    </div>
                  </div>
                </Box>







                {/* --------------------------end -------------------------------- */}

                {/* <RangeSliderComponent /> */}
                <div className="apply-btn-buy">
                  {/*<input class="login-btn actionbtn-pg-5 btn btn-3" type="submit" value="APPLY">*/}
                  <button className="login-btn actionbtn-pg-5 btn btn-3 apply-btn buy-apply-btn-set">
                    APPLY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftMainGridBuy;


