import { ADMIN_LOGIN } from "./actionType";
import { PostReq } from "../../config/postReq";

export const get_countries = () => async (dispatch) => {
    const query = `
        query {
            countries {
              code
              name
            }
          }
    `
    try {
        const res = await PostReq({ query })
        dispatch({
            type: get_countries,
            payload: res.data,
        });
    } catch (err) {
        console.log("catch err", err);
    }
};